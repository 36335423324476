import React from 'react';
import PageWrapper from '../components/wrappers/PageWrapper';
import Domains from '../components/domains-report/Domains';

const DomainsReportPage = () => {
  return (
    <PageWrapper>
      <Domains />
    </PageWrapper>
  );
};

export default DomainsReportPage;
