import classNames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';

const Body = ({ data }) => {
  return (
    <tbody>
      {data.map((item, index) => (
          <tr key={index}>
            {Object.values(item).map((val, idx) => (
              <td
                key={idx}
                className={classNames(
                  'border-b-[1.5px] text-left whitespace-nowrap border-r-[1.5px] first:bg-[#FCFCFC] dark:first:bg-[#555454] border-[#E8E8E8] dark:border-[#3E3E3E] py-[5px] first:pl-[38px] first:pr-[22px] px-2 first:text-left first:font-onest-medium  first:left-0',
                  {'min-w-[500px] whitespace-pre-wrap': idx===2}
                )}
              >
                {idx === 6 ? moment(val).format('YYYY-MM-DD HH:mm') : val}
              </td>
            ))}
          </tr>
      ))}
    </tbody>
  );
};

export default Body;
