import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import PrimaryButton from '../buttons/primary';
import TextButton from '../buttons/text';

const ActionButtonGroup = ({ button: { isDisabled, handleClick, label }, link, styles }) => {
  const dispatch = useDispatch();
  return (
    <div className={`flex gap-4 pt-6 border-t border-grey-3 dark:border-black-3 ${styles}`}>
      <PrimaryButton disabled={isDisabled} type={'submit'} handleClick={handleClick}>
        {label}
      </PrimaryButton>
      {/* <button
        disabled={isDisabled}
        type='submit'
        className='px-4 py-2 bg-main-red tracking-wide text-main-white disabled:bg-red-300 disabled:cursor-not-allowed transition-colors duration-200 transform rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600 focus:ring focus:ring-red-300 focus:ring-opacity-50'
        onClick={() => handleClick()}
      >
        {label}
      </button> */}

      <Link to={link ? link.linkTo : ROUTES.HOME.route}>
        <TextButton
          type={'button'}
          handleClick={() => {
            link && link.handleLinkClick ? dispatch(link.handleLinkClick) : link && link.handleClick();
          }}
          disabled={link.isDisabled}
        >
          Cancel
        </TextButton>
      </Link>
    </div>
  );
};

export default ActionButtonGroup;
