import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';

const RadioField = ({ inputId, values, title }) => {
  const {
    register,
    watch,
    reset,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useFormContext();

  watch(inputId);
  // const { invalid, error } = getFieldState;

  return (
 <div>
      <p className='text-left text-sm h-fit mb-1'>{title}</p>
      <div className='flex justify-between'>
        {values.map((value, idx) => (
       <div key={idx}>
          <label htmlFor='geo' className='text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer mr-2'>
            <input
             {...register(`${inputId}`)} 
             type='radio' 
             value={value.value} 
            //  onChange={() => reset()}
             className='w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
          </label>
           {value.name}
       </div>
        ))}
      </div>
 </div>
)};

export default RadioField;
