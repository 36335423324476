import React from 'react';
import { Controller } from 'react-hook-form';
import LabeledSelectBox from '../../../../ui/select-box/labeled';
import { useSelector } from 'react-redux';
import MultiSelect from '../../../../ui/multi-select';
import sourcesSelector from '../../../../../state/client-management/selectors/clients/sources';
import MultiSelectSearch from '../../../../ui/multi-search';
import SourcesMultiSelectSearch from '../../../../ui/multi-search/sources';

const BlocksSel = ({ control }) => {
  const  sources  = useSelector(sourcesSelector);
  const selBlocks = sources.map((id, value ) => ({ id: value, name: id }))

  return (
    <section id='blocks' className='flex flex-col gap-4'>
      <div>
        <Controller
          name='blocks'
          control={control}
          render={({ field: { value, name, onChange }, fieldState: { error } }) => (
            <LabeledSelectBox label={'Blocks:'} isRequired={false} error={error}>
               <SourcesMultiSelectSearch
                 id={name}
                 placeholder={'Select blocks'}
                 itemLabel={'name'}
                 options={selBlocks}
                 onChange={onChange}
                 values={value || []}
                 isRequired={false}
                 isDisabled={!selBlocks.length}
                 isReadOnly={false}
                 isSelectAll={true}
              />
            </LabeledSelectBox>
          )}
        />
      </div>
    </section>
  );
};

export default BlocksSel;
