import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import sidebarSelector from '../../state/menus/selectors/sidebar';
import sidebarAnimationSelector from '../../state/menus/selectors/sidebar-animation';

const PageWrapper = ({ children }) => {
  const sidebar = useSelector(sidebarSelector);
  const animation = useSelector(sidebarAnimationSelector);

  return (
    <main className='flex-col overflow-auto'>
      <div
        className={classNames(
          'mt-[76px] overflow-y-auto overflow-x-hidden h-[calc(100vh_-_76px)] bg-grey-6 dark:bg-black-1 flex max-md:ml-0',
          {
            'ml-[124px]': !sidebar,
            'ml-[238px]': sidebar,
            'ease-in-out duration-300': animation,
          }
        )}
      >
        {children}
      </div>
    </main>
  );
};

export default PageWrapper;
