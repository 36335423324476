export const PIXELS_TABLE_HEADERS = [
  {
    key: 'pixelID',
    title: 'Pixel ID',
    width: 100,
  },
  {
    key: 'eventSourceUrl',
    title: 'Event source url',
    width: 200,
  },
  {
    key: 'action',
    title: 'Action',
    width: 65,
  },
];

