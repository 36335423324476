import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pushErrorSelector from '../../../state/push/selectors/error';
import pushLoadingSelector from '../../../state/push/selectors/loading';
import msgsSelector from '../../../state/push/selectors/messages/messages';
import filterSelector from '../../../state/push/selectors/messages/filters';
import pageInfoSelector from '../../../state/push/selectors/messages/pageInfo';
import { deleteMessage, getMessages } from '../../../state/push/async-actions';
import Page401 from '../../error-pages/Page401';
import Page403 from '../../error-pages/Page403';
import Loading from '../../global/Loading';
import classNames from 'classnames';
import TableWrapper from '../../ui/table/TableWrapper';
import TableMsgsHeader from '../../ui/table/MsgHeader';
import { MSGS_TABLE_HEADERS } from '../../../constants/push-headers';
import { toggleDeleteMsgConfModal } from '../../../state/modals/actions';
import deleteMsgConfModalSelector from '../../../state/modals/selectors/delete-msg';
import DeleteModal from '../../modals/DeleteModal';
import MsgsTableBody from './TableBody';
import FiltersRow from './FiltersRow';
import Pagination from '../../global/pagination/Pagination';
import { setCurrentPage, setPageLimit } from '../../../state/push/actions';

const Messages = () => {
  const error = useSelector(pushErrorSelector);
  const isLoading = useSelector(pushLoadingSelector);
  const messages = useSelector(msgsSelector);
  const { selectedCategories, selectedID } = useSelector(filterSelector);
  const { pageSize, currentPage, totalPages } = useSelector(pageInfoSelector);
  const dispatch = useDispatch();
  const limits = [10, 25, 50, 100];

  React.useEffect(() => {
    dispatch(getMessages({ selectedID, selectedCategories, pageSize, currentPage }));
  }, [dispatch, pageSize, currentPage]);

  const handleDelete = () => {
    dispatch(deleteMessage())
      .unwrap()
      .then(() => {
        dispatch(toggleDeleteMsgConfModal());
      });
  };

  const deleteConfirmation = useSelector(deleteMsgConfModalSelector);

  if (error?.status === 401) {
    return <Page401 service='push' error={error} />;
  }
  if (error?.status === 403) {
    return <Page403 />;
  }
  if (error) {
    return <h2 className='text-black-1 dark:text-white text-3xl pt-10 text-center'>{error.message || error}</h2>;
  }
  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className={classNames('overflow-auto h-[calc(100%_-_96px)]', {})}>
        <FiltersRow />
        {messages ? (
          <TableWrapper>
            <TableMsgsHeader headers={MSGS_TABLE_HEADERS} topStyle={0} />
            {messages && <MsgsTableBody message={messages} />}
          </TableWrapper>
        ) : (
          <h2 className='text-black-1 dark:text-white text-3xl pt-10 text-center'>Messages not found.</h2>
        )}
        {messages && (
          <Pagination
            size={pageSize}
            limits={limits}
            totalPages={totalPages}
            currentPage={currentPage}
            setPageFunc={setCurrentPage}
            setLimitFunc={setPageLimit}
          />
        )}
        <DeleteModal
          title={'Delete Push Message'}
          description={"Are you sure you want to delete this message? If you delete it, you can't get it back."}
          onDelete={handleDelete}
          onClose={() => {
            dispatch(toggleDeleteMsgConfModal());
          }}
          deleteConfirmation={deleteConfirmation}
        />
      </div>
    </>
  );
};

export default Messages;
