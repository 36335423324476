import classNames from 'classnames';
import React from 'react';

const Header = ({ data }) => {
  return (
    <thead className='sticky top-[-32px]'>
      <tr>
        {Object.keys(data).map((col, index) => (
          <th
            scope='col'
            key={index}
            className={classNames(
              'px-3 align-middle first:w-52  text-left bg-light-table-header dark:bg-dark-table-header first:pl-[38px] py-[10px] first:left-0 font-extrabold border-r-[1.5px] border-b-[1.5px] border-main-grey-3 dark:border-main-black',
              {
                'border-r-main-grey-2 dark:border-r-main-grey-2': index === 0,
              }
            )}
          >
            {(col.charAt(0).toUpperCase() + col.slice(1))
              .replace('Percent', ' %')
             }
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default Header;
