import React from 'react';
import Error404Dark from '../../assets/errors/Error404Dark.svg';
import Error404White from '../../assets/errors/Error404White.svg';
import { useDispatch, useSelector } from 'react-redux';
import themeSelector from '../../state/menus/selectors/theme';
import { THEMES } from '../../constants/themes';
import { Link } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import sidebarSelector from '../../state/menus/selectors/sidebar';
import classNames from 'classnames';
import PageWrapper from '../wrappers/PageWrapper';
import { resetMenus } from '../../state/menus/actions';

const Page404 = () => {
  const dispatch = useDispatch();
  const theme = useSelector(themeSelector);
  const sidebar = useSelector(sidebarSelector);
  return (
    <PageWrapper>
      <div
        className={classNames('flex flex-col lg:flex-row justify-center w-full items-center 2xl:mx-auto gap-7', {
          'lg:gap-[5%] 2xl:gap-[8.5%]': sidebar,
          'lg:gap-[8.5%]': !sidebar,
        })}
      >
        <img
          className={classNames('md:w-[300px] md:h-[300px] h-[250px] w-[250px] transition-all duration-500', {
            'lg:w-[300px] lg:h-[300px] xl:w-[400px] xl:h-[400px] 2xl:w-[460px] 2xl:h-[460px]': sidebar,
            'lg:w-[350px] lg:h-[350px] xl:w-[460px] xl:h-[460px] 2xl:w-[460px] 2xl:h-[460px]': !sidebar,
          })}
          alt='Error Robot'
          src={theme === THEMES.light ? Error404White : Error404Dark}
        />
        <div className='dark:text-main-white xl:max-w-md max-w-sm transition-all duration-500'>
          <h1 className='text-[34px] leading-snug tracking-widest font-semibold mb-7'>Page is in progress</h1>
          <p className='text-base mb-7'>You should go back to the previous page</p>
          <div className='flex flex-col gap-2 xl:flex-row text-sm text-center'>
            <Link
              className='flex-auto px-6 py-2.5 bg-red tracking-wide text-white transition-colors duration-200 transform rounded-md hover:bg-red-burgundy focus:outline-none active:font-bold'
              onClick={() => dispatch(resetMenus())}
              to={ROUTES.HOME.route}
            >
              Back to Home
            </Link>
            <Link
              to='#'
              className='flex-auto px-5 py-2.5 tracking-wide text-red hover:text-red-burgundy transition-colors duration-200 rounded-md focus:outline-none active:font-bold'
            >
              Visit to our help center
            </Link>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Page404;
