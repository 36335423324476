import React from 'react';
import PageWrapper from '../components/wrappers/PageWrapper';
import FB from '../components/fb/FB';

const FBPage = () => {
  return (
    <PageWrapper>
      <FB />
    </PageWrapper>
  );
};

export default FBPage;
