import React from 'react';
import { useDispatch } from 'react-redux';

const ResetButton = ({resetFunc}) => {
  const handleClick = () => {
    resetFunc();
  };
  return (
    <button
      onClick={() => handleClick()}
      className='border py-2 px-4 rounded-md bg-transparent border-main-grey-2 dark:border-main-black-2 text-main-black dark:text-main-grey-2'
    >
      Reset
    </button>
  );
};

export default ResetButton;
