import React from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import chartSelector from '../../../state/analytics/selectors/chart/chart';

const SummaryPieChart = ({isShow, options, series, title }) => {
  return (
    <div className={!isShow ? 'hidden' : 'min-w-[200px]'}>
      <Chart options={options} series={series} type='pie' width={'180%'} height={500} />
      <p className='text-center uppercase'>{title}</p>

    </div>
  );
};

export default SummaryPieChart;
