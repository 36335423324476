import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  firebaseSetAuthUser,
  firebaseSignIn,
} from '../../firebase/auth';

export const signIn = createAsyncThunk('user/sign-in', (data, thunkAPI) => firebaseSignIn(data, thunkAPI));
export const setAuthUser = createAsyncThunk('user/set-auth-user', () =>
  firebaseSetAuthUser()
);
