import React, { useEffect } from 'react';
import TableWrapper from './TableWrapper';
import Header from './Header';
import { useSelector } from 'react-redux';
import Body from './Body';
import pageInfoSelector from '../../../state/tracker-report/selectors/table/pageInfo';
import Footer from './Footer';
import Pagination from '../../global/pagination/Pagination';
import { setCurrentPage, setPageLimit } from '../../../state/tracker-report/actions';

const Table = ({ report }) => {
  var reportSize = report.length
  const { pageSize, totalPages, currentPage } = useSelector(pageInfoSelector);
  const limits = [10, 25, 50, 100];
  let start = (currentPage - 1) * Number(pageSize);
  let end = (currentPage - 1) * pageSize + Number(pageSize);
  let slicedReport = report.slice(start, end);

  return (
    <div>
      <TableWrapper>
        <Header data={slicedReport[0]?.Total} />
        <Body data={slicedReport} />
        <Footer />
      </TableWrapper>
      <div className='dark:text-white'>
        <Pagination
          size={pageSize}
          limits={limits}
          totalPages={reportSize / pageSize}
          currentPage={currentPage}
          setPageFunc={setCurrentPage}
          setLimitFunc={setPageLimit}
        />
      </div>
    </div>
  );
};

export default Table;
