import Joi from 'joi';

export const messageSchema = Joi.object({
  id: Joi.number(),
  heading: Joi.string().min(2).max(500).messages({
    'string.empty': 'The "Heading" field is required',
    'string.base': 'The "Heading" field should contain only letters and digits',
    'string.min': 'The "Heading" field should have at least {#limit} characters',
    'string.max': 'The "Heading" field should have at most {#limit} characters',
  }),
  status: Joi.string().valid('active', 'not-active').required().messages({
    'string.base': 'The "Status" field should be a string',
    'any.only': 'The "Status" field should be either "active" or "not-active"',
    'string.required': `This field is required`,
  }),
  content: Joi.string().min(2).max(500).messages({
    'string.base': 'The "Text" field should be a string',
    'any.only': 'The "Text" field should be either "active" or "not-active"',
    'string.required': `This field is required`,
  }),
  category: Joi.array().min(1).required().messages({
    'array.base': 'The "Message category" field should be an array',
    'array.required': `This field is required`,
  }),
  langs: Joi.array().min(1).required().messages({
    'array.base': 'The "Langs" field should be an array',
    'array.required': `This field is required`,
  }),
  icon: Joi.string().uri().required().messages({
    'string.base': 'The "Icon" field should be a string',
    'string.required': `This field is required`,
  }),
  image: Joi.string().uri().required().messages({
    'string.base': 'The "Image" field should be a string',
    'string.required': `This field is required`,
  }),
});
