import { pushSlice } from './slice';

export const {
  setAppToDelete,
  setMsgToDelete,
  resetFilters,
  selectCategory,
  selectAllCategories,
  setCurrentPage,
  setPageLimit,
  selectMessageID,
  setSelectedLang,
  setImageType,
} = pushSlice.actions;
