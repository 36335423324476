import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFilters, fetchReport } from '../../api/analytic/analytics';

export const setReportData = createAsyncThunk(
  'analytic/set-report',
  (
    isFilter,
    type,
    selectedDate,
    selectedAdvertisers,
    selectedSource,
    selectedCountry,
    selectedLandingID,
    selectedUserEmail,
    sort,
    pageSize,
    currentPage,
    thunkAPI
  ) =>
    fetchReport(
      isFilter,
      type,
      selectedDate,
      selectedAdvertisers,
      selectedSource,
      selectedCountry,
      selectedLandingID,
      selectedUserEmail,
      sort,
      pageSize,
      currentPage,
      thunkAPI
    )
);

export const downloadReport = createAsyncThunk(
  'analytic/download-report',
  (
    isDownload,
    isFilter,
    type,
    selectedDate,
    selectedAdvertisers,
    selectedSource,
    selectedCountry,
    selectedLandingID,
    selectedUserEmail,
    sort,
    pageSize,
    currentPage,
    thunkAPI
  ) =>
    fetchReport(
      isDownload,
      isFilter,
      type,
      selectedDate,
      selectedAdvertisers,
      selectedSource,
      selectedCountry,
      selectedLandingID,
      selectedUserEmail,
      sort,
      pageSize,
      currentPage,
      thunkAPI
    )
);

export const getFilters = createAsyncThunk('analytic/get-filters', (_, thunkAPI) => fetchFilters(thunkAPI));
