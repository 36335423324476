import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isError: false,
  isNotification: false,
  isOpenedList: false,
  list: [],
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetErrorNotification(state) {
      state.isError = false;
    },
    toggleNotifications(state) {
      state.isNotification = !state.isNotification;
    },
    toggleNotificationList(state) {
      state.isOpenedList = !state.isOpenedList;
    },
    createNotificationList(state, { payload }) {
      const { type } = payload;
      const isError = type === 'error';
      const list = [payload];
      return { ...state, list, isError };
    },
    readNotification(state, { payload }) {
      state.list = state.list.filter((notification) => notification.id !== payload);
      state.isOpenedList = false;
    },
    resetNotifications: () => initialState,
  },
});

export default notificationsSlice.reducer;
