import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTrackerReport } from '../../api/analytic/tracker';

export const setTrackerReport = createAsyncThunk(
  'tracker/set-report',
  (selectedDate, source, thunkAPI) =>
    fetchTrackerReport(selectedDate, source, thunkAPI)
);

export const downloadTrackerReport = createAsyncThunk(
  'tracker/download-report',
  (
    isDownload, selectedDate, source, thunkAPI
  ) =>
  fetchTrackerReport(
    isDownload, selectedDate, source, thunkAPI
    )
);

