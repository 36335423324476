import classNames from 'classnames';
import React from 'react';
import Checkbox from '../checkbox';

const TablePixelsHeader = ({ headers, checkbox, topStyle }) => {
  return (
    <thead
      className={`sticky top-${topStyle} z-10 text-base font-onest-medium bg-blue-0 dark:bg-grey-0 text-black-3 dark:text-grey-3`}
    >
      <tr className='h-[72px]'>
        {checkbox && headers && !!headers.length && (
          <th
            scope='col'
            className={classNames('first:pl-[30px] h-full px-4 py-2 last:pr-[30px]', {
              'first:w-8': checkbox,
            })}
          >
            <div className='flex items-center justify-start'>
              <Checkbox
                id={'header-checkbox'}
                isDisabled={checkbox.isDisabled}
                isChecked={checkbox.isChecked}
                onCheck={checkbox.onCheck}
              />
            </div>
          </th>
        )}
        {headers.map(({ key, title, width }, index) => (
          <th
            key={key}
            scope='col'
            style={{ width: width }}
            className={classNames('first:pl-[30px] max-md:first:!w-[125px] whitespace-nowrap h-full px-4 py-2 last:pr-[30px] last:w-full', {
              'first:w-8': checkbox,
              // 'hidden md:table-cell': index === headers.length-1,
            })}
          >
            {title}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TablePixelsHeader;
