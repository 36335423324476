import classNames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';

const PrimaryButton = ({ type, children, disabled, handleClick }) => {
  const pathname = useLocation().pathname;
  return (
    <button
      type={type}
      onClick={handleClick}
      disabled={disabled}
      className={classNames(
        'min-w-[120px] h-10 flex justify-center items-center px-4 py-2.5 bg-red text-white hover:bg-red-burgundy active:font-bold focus:outline-none font-onest-medium rounded-md text-sm text-center disabled:bg-grey-3 dark:disabled:bg-grey-1 disabled:text-grey-1 dark:disabled:text-black-3 disabled:active:font-medium',
        {
          'w-fit': pathname !== '/signin' && pathname !== '/signup' && !pathname === '/authkeys',
          'w-full': pathname === '/signin' || pathname === '/signup' || (pathname === '/authkeys' && type === 'submit'),
        }
      )}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
