import React from 'react';
import TabSwitcher from '../TabSwitcher';
import ROUTES from '../../constants/routes';

const parentRoute = ROUTES.FB.route;
const TABS = [
  { slug: `${parentRoute}/pixels`, title: 'Pixels' },
];

const FB = () => {
  return (
    <section className='relative flex flex-col w-full'>
      <TabSwitcher tabs={TABS} styles={'h-full'} parentSlug={parentRoute} />
    </section>
  );
};

export default FB;