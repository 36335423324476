import { createSlice } from '@reduxjs/toolkit';
import { addPixel, deletePixel, getPixel, getPixels, updatePixel } from './async-actions';

const initialState = {
  pixels: {
    pixels: [],
    pixel: {},
    toDelete: null,
    isLoading: false,
    isEditable: false,
    error: '',
  },
};

export const fbSlice = createSlice({
  name: 'fb',
  initialState,
  reducers: {
    setPixelToDelete: (state, { payload }) => {
      state.pixels.toDelete = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPixels.fulfilled, (state, { payload }) => {
        state.pixels.pixels = payload ? payload : [];
        state.pixels.isLoading = false;
      })
      .addCase(getPixels.pending, (state) => {
        state.pixels.isLoading = true;
        state.pixels.error = null;
      })
      .addCase(getPixels.rejected, (state, { payload }) => {
        state.pixels.isLoading = false;
        state.pixels.error = payload;
      })
      .addCase(getPixel.fulfilled, (state, { payload }) => {
        state.pixels.pixel = payload ? payload : {};
        state.pixels.isLoading = false;
      })
      .addCase(getPixel.pending, (state) => {
        state.pixels.isLoading = true;
        state.pixels.error = null;
      })
      .addCase(getPixel.rejected, (state, { payload }) => {
        state.pixels.isLoading = false;
        state.pixels.error = payload;
      })
      .addCase(updatePixel.fulfilled, (state, { payload }) => {
        state.pixels.isLoading = false;
      })
      .addCase(updatePixel.pending, (state) => {
        state.pixels.isLoading = true;
        state.pixels.error = null;
      })
      .addCase(updatePixel.rejected, (state, { payload }) => {
        state.pixels.isLoading = false;
        state.pixels.error = payload;
      })
      .addCase(deletePixel.fulfilled, (state) => {
        state.pixels.pixels = state.pixels.pixels.filter(({ id }) => id !== state.pixels.toDelete);
        state.isLoading = false;
      })
      .addCase(deletePixel.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deletePixel.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
      .addCase(addPixel.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addPixel.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addPixel.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      });
  },
});

const fbReducer = fbSlice.reducer;

export default fbReducer;
