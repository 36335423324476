import React, { useEffect } from 'react'
import pixelSelector from '../../../../state/fb/selectors/pixels/pixel';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { getPixel, getPixels } from '../../../../state/fb/async-actions';
import Loading from '../../../global/Loading';
import Breadcrumbs from '../../../ui/breadcrumbs';
import PixelForm from './Form';

export const Pixel = () => {
  const pixel = useSelector(pixelSelector);

  const dispatch = useDispatch();
  const params = useParams();


  useEffect(() => {
       _.isEmpty(pixel) &&
      dispatch(getPixels())
        .unwrap()
        .then(() => {
          dispatch(getPixel(params.id));
        });
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (_.isEmpty(pixel)) {
    return <Loading />;
  }

  return (
    <div className='w-full h-full pl-[30px]'>
      <Breadcrumbs
        linkTo={'/fb/pixels'}
        
        firstLevel={'Pixels'}
        secondLevel={`${pixel.pixel_id}`}
      />
      <div className='overflow-auto h-[calc(100%_-_96px)] flex'>
        <PixelForm pixel={pixel} action="update" />
      </div>
    </div>
  );
}
