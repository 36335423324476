import React from 'react';
import { Controller } from 'react-hook-form';
import LabeledSelectBox from '../../../../ui/select-box/labeled';
import MultiSelect from '../../../../ui/multi-select';

const SegmentSel = ({ control }) => {
  var segments = ['All users'];

  const selSegments = segments.map((id, value) => ({ id: value, name: id }));

  return (
    <section id='include_segment' className='flex flex-col gap-4'>
      <div className='w-[300px]'>
        <Controller
          name='include_segment'
          control={control}
          render={({ field: { value, name, onChange }, fieldState: { error } }) => (
            <LabeledSelectBox label={'Included Segments:'} isRequired={true} error={error}>
              <MultiSelect
                id={name}
                placeholder={'Select segment'}
                itemLabel={'name'}
                options={selSegments}
                onChange={onChange}
                // values={value ? value.map((id, value ) => ({ id: value, name: id })) : []}
                values={value || []}
                isRequired={false}
                isDisabled={!selSegments.length}
                isReadOnly={false}
              />
            </LabeledSelectBox>
          )}
        />
      </div>
    </section>
  );
};

export default SegmentSel;
