import React from 'react';
import PageWrapper from '../components/wrappers/PageWrapper';
import Push from '../components/push/Push';

const PushPage = () => {
  return (
    <PageWrapper>
      <Push />
    </PageWrapper>
  );
};

export default PushPage;
