import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSort } from '../../../state/analytics/actions';
import { prepareSortValue } from '../../../helpers/reportTable';

const Header = ({ data, type }) => {
  const dispatch = useDispatch();
  const onClickHeader = (val, index) => {
    if (index <= 6) {
      dispatch(setSort(prepareSortValue(val, type)));
    }
  };
  return (
    <thead className='sticky top-0'>
      <tr>
        {data.map((col, index = Object.key(col)) => (
          <th
            scope='col'
            key={index}
            onClick={() => onClickHeader(col, index)}
            className={classNames(
              'px-3 align-middle first:w-52  text-left bg-light-table-header dark:bg-dark-table-header first:pl-[38px] py-[10px] first:sticky first:left-0 font-extrabold border-r-[1.5px] border-b-[1.5px] border-main-grey-3 dark:border-main-black',
              {
                'border-r-main-grey-2 dark:border-r-main-grey-2': index === 0,
                'cursor-pointer': index <= 6,
              }
            )}
          >
            {(col.charAt(0).toUpperCase() + col.slice(1))
              .replace('Percent', ' %')
              .replace('Type', type.charAt(0).toUpperCase() + type.slice(1))}
            {index <= 6 && (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='ml-[10px] w-3 h-3 inline'
                aria-hidden='true'
                fill='#FFFF'
                viewBox='0 0 320 512'
              >
                <path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z' />
              </svg>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default Header;
