import React from 'react';
import PageWrapper from '../components/wrappers/PageWrapper';
import Tracker from '../components/tracker/Tracker';

const TrackerPage = () => {
  return (
    <PageWrapper>
      <Tracker />
    </PageWrapper>
  );
};

export default TrackerPage;
