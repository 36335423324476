import { createSlice } from '@reduxjs/toolkit';
import { downloadReport, getFilters, setReportData } from './async-actions';
import moment from 'moment';
import { TODAY_DATE } from '../../constants/date';
import { formatDate } from '../../helpers/time';

const initialState = {
  table: {
    rows: {
      type: [],
      rejects: [],
      successes: [],
      totalTypeSubmits: [],
      leads: [],
      revenue: [],
      uniqTypeUsers: [],
      rejectsPercent: [],
      successPercent: [],
      leads2success: [],
      rev2success: [],
      avgPayout: [],
    },
    summary: {
      totalUniqUsers: null,
      totalUniqUsersCleaned: null,
      totalSubmits: null,
      totalSubmitsCleaned: null,
      totalRevenue: null,
      totalLeads: null,
      totalRejectsPercent: null,
      totalSuccessPercent: null,
      totalLeads2success: null,
      totalRev2success: null,
      GRU: null,
      totalSucces: null,
      totalRejects: null,
      totalRejectsCleaned: null,
      totalAvgPayout: null,
    },
    pageInfo: {
      totalElements: null,
      totalPages: null,
      pageSize: localStorage.getItem('reportTablePageLimit') || '',
      currentPage: 1,
    },
  },
  chart: {
    type: [],
    submits: [],
    leads: [],
    revenue: [],
  },
  filter: {
    advertisers: [],
    sources: [],
    countries: [],
    landings: [],
    selectedDate: {
      startDate: TODAY_DATE,
      endDate: TODAY_DATE,
    },
    selectedAdvertisers: [],
    selectedSource: [],
    selectedCountry: [],
    selectedLandingID: [],
    selectedUserEmail: '',
    sort: {
      field: '',
      sortOpt: 'DESC',
    },
  },
  loading: false,
  error: '',
};

export const analyticSlice = createSlice({
  name: 'analytic',
  initialState,
  reducers: {
    setDateRange: (state, { payload }) => {
      state.filter.selectedDate.startDate = payload.startDate
        ? formatDate(payload.startDate)
        : TODAY_DATE;
      state.filter.selectedDate.endDate = payload.endDate ? formatDate(payload.endDate) : TODAY_DATE;
    },
    setSort: (state, { payload }) => {
      state.filter.sort.field = payload;
      state.filter.sort.sortOpt = state.filter.sort.sortOpt === 'DESC' ? 'ASC' : 'DESC';
    },
    setCurrentPage: (state, { payload }) => {
      state.table.pageInfo.currentPage = payload;
    },
    setForcedPage: (state, { payload }) => {
      state.table.pageInfo.forcedPage = payload;
    },
    setPageLimit: (state, { payload }) => {
      state.table.pageInfo.pageSize = payload;
      localStorage.setItem('reportTablePageLimit', payload);
      state.table.pageInfo.currentPage = 1;
    },
    selectAdvertiser: (state, { payload }) => {
      if (state.filter.selectedAdvertisers.includes(payload)) {
        state.filter.selectedAdvertisers = state.filter.selectedAdvertisers.filter((id) => id !== payload);
      } else {
        state.filter.selectedAdvertisers.push(payload);
      }
    },
    selectAllAdvertisers: (state, { payload }) => {
      state.filter.selectedAdvertisers = payload ? payload.map(({id}) => id) : [];
    },
    selectSource: (state, { payload }) => {
      if (state.filter.selectedSource.includes(payload)) {
        state.filter.selectedSource = state.filter.selectedSource.filter((source) => source !== payload);
      } else {
        state.filter.selectedSource.push(payload);
      }
    },
    selectAllSources: (state, { payload }) => {
      state.filter.selectedSource = payload ? payload : [];
    },
    selectCountry: (state, { payload }) => {
      if (state.filter.selectedCountry.includes(payload)) {
        state.filter.selectedCountry = state.filter.selectedCountry.filter((country) => country !== payload);
      } else {
        state.filter.selectedCountry.push(payload);
      }
    },
    selectAllCountries: (state, { payload }) => {
      state.filter.selectedCountry = payload ? payload : [];
    },
    selectLandingID: (state, { payload }) => {
      if (state.filter.selectedLandingID.includes(payload)) {
        state.filter.selectedLandingID = state.filter.selectedLandingID.filter((landing) => landing !== payload);
      } else {
        state.filter.selectedLandingID.push(payload);
      }
    },
    selectAllLandingsID: (state, { payload }) => {
      state.filter.selectedLandingID = payload ? payload : [];
    },
    selectUserEmail: (state, { payload }) => {
      state.filter.selectedUserEmail = payload;
    },
    resetFilters: (state) => {
      state.filter.selectedAdvertisers = [];
      state.filter.selectedSource = [];
      state.filter.selectedCountry = [];
      state.filter.selectedLandingID = [];
      state.filter.selectedUserEmail = '';
      state.table.pageInfo.currentPage = state.table.pageInfo.currentPage === 1 ? state.table.pageInfo.currentPage : 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setReportData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.table.rows.type = payload.reports ? payload.reports?.map(({ report_type }) => report_type) : [];
        state.table.rows.rejects = payload.reports ? payload.reports?.map(({ rejects }) => rejects) : [];
        state.table.rows.successes = payload.reports ? payload.reports?.map(({ successes }) => successes) : [];
        state.table.rows.totalTypeSubmits = payload.reports
          ? payload.reports?.map(({ total_type_submits }) => total_type_submits)
          : [];
        state.table.rows.leads = payload.reports ? payload.reports?.map(({ leads }) => leads) : [];
        state.table.rows.revenue = payload.reports ? payload.reports?.map(({ revenue }) => revenue) : [];
        state.table.rows.uniqTypeUsers = payload.reports
          ? payload.reports?.map(({ uniq_type_users }) => uniq_type_users)
          : [];
        state.table.rows.rejectsPercent = payload.reports
          ? payload.reports?.map(({ rejects_percent }) => rejects_percent)
          : [];
        state.table.rows.successPercent = payload.reports
          ? payload.reports?.map(({ success_percent }) => success_percent)
          : [];
        state.table.rows.leads2success = payload.reports
          ? payload.reports?.map(({ lead_2_success }) => lead_2_success)
          : [];
        state.table.rows.rev2success = payload.reports
          ? payload.reports?.map(({ rev_2_success }) => rev_2_success)
          : [];
        state.table.rows.avgPayout =
          state.table.rows.revenue !== 0
            ? state.table.rows.revenue.map((val, idx) => {
                var res = val / state.table.rows.leads[idx];
                return res.toFixed(2);
              })
            : [];

        state.chart.type = payload.graph_report
          ? payload.graph_report?.map(({ graph_report_type }) => graph_report_type)
          : [];
        state.chart.submits = payload.graph_report
          ? payload.graph_report?.map(({ graph_submits }) => graph_submits)
          : [];
        state.chart.leads = payload.graph_report ? payload.graph_report?.map(({ graph_leads }) => graph_leads) : [];
        state.chart.revenue = payload.graph_report
          ? payload.graph_report?.map(({ graph_revenue }) => graph_revenue)
          : [];

        state.table.summary.totalUniqUsers = payload.summary.total_uniq_users;
        state.table.summary.totalUniqUsersCleaned = payload.summary.total_uniq_users_cleaned;
        state.table.summary.totalSubmits = payload.summary.total_submits;
        state.table.summary.totalSubmitsCleaned = payload.summary.total_submits_cleaned;
        state.table.summary.totalRevenue = payload.summary.total_revenue;
        state.table.summary.totalLeads = payload.summary.total_leads;
        state.table.summary.totalRejectsPercent = payload.summary.rejects_percent;
        state.table.summary.totalSuccessPercent = payload.summary.success_percent;
        state.table.summary.totalLeads2success = payload.summary.lead_2_success;
        state.table.summary.totalRev2success = payload.summary.rev_2_success;
        state.table.summary.GRU = payload.summary.gru;
        state.table.summary.totalSucces = payload.summary.total_success;
        state.table.summary.totalRejects = payload.summary.total_rejects;
        state.table.summary.totalRejectsCleaned = payload.summary.total_rejects_cleaned;
        state.table.summary.totalAvgPayout = +(state.table.summary.totalRevenue / state.table.summary.totalLeads).toFixed(2);

        state.table.pageInfo.totalElements = payload.total_counts;
        state.table.pageInfo.totalPages = payload.last_page;
      })
      .addCase(setReportData.pending, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(setReportData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getFilters.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.filter.advertisers = payload.advertisers;
        state.filter.sources = payload.source;
        state.filter.landings = payload.landing_id;
        state.filter.countries = payload.country;
      })
      .addCase(getFilters.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFilters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(downloadReport.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(downloadReport.pending, (state) => {
        state.error = null;
      })
      .addCase(downloadReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const analyticReducer = analyticSlice.reducer;
export default analyticReducer;
