import axios from '../../config/backoffice';
import { getToken } from '../../helpers/token';

export const fetchImages = async (type, { rejectWithValue }) => {
  const token = getToken();
  let folder = '';
  if (type === 'icon') {
    folder = 'puic';
  } else {
    folder = 'puim';
  }
  try {
    const res = await axios.get(`/push/message/images/${folder}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};
