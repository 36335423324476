import React from 'react';
import TableWrapper from './TableWrapper';
import Header from './Header';
import { useSelector } from 'react-redux';
import tableRowsSelector from '../../../state/analytics/selectors/table/rows';
import Body from './Body';
import ReportTableFooter from './Footer';
import Pagination from '../../global/pagination/Pagination';
import pageInfoSelector from '../../../state/analytics/selectors/table/pagiInfo';
import { setCurrentPage, setPageLimit } from '../../../state/analytics/actions';

const Table = ({ isShow, type }) => {
  const rows = useSelector(tableRowsSelector);
  const { pageSize, totalPages, currentPage } = useSelector(pageInfoSelector);
  const limits = [10, 25, 50];
  return (
    <div className={!isShow ? 'hidden' : ''}>
      <TableWrapper>
        <Header data={Object.keys(rows)} type={type} />
        <Body data={rows} />
        <ReportTableFooter />
      </TableWrapper>
      <Pagination size={pageSize} limits={limits} totalPages={totalPages} currentPage={currentPage} setPageFunc={setCurrentPage} setLimitFunc={setPageLimit} />
    </div>
  );
};

export default Table;
