import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import sidebarMenuSelector from '../../../state/menus/selectors/sidebar';

const TableWrapper = ({ children }) => {
  return (
    <div className='relative border-black-2 border'>
      <table
        className={classNames(
          'text-base text-center transition-all font-onest-regular text-main-black dark:text-main-white border-separate border-spacing-0 w-full',
        )}
      >
        {children}
      </table>
    </div>
  );
};

export default TableWrapper;
