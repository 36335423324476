import { getToken } from '../../helpers/token';
import axios from '../../config/backoffice';

export const fetchDomainsReport = async (
  { selectedDate, selectedAdvertisers, selectedSource, selectedCountry },
  { rejectWithValue }
) => {
  const token = getToken();
  try {
    const result = await axios.get(
      'reports/domains?' +
        (selectedDate
          ? '&' +
            new URLSearchParams({
              date: selectedDate.startDate + ',' + selectedDate.endDate,
            })
          : '') +
        (selectedAdvertisers.length > 0
          ? '&' +
            new URLSearchParams({
              advertisers: selectedAdvertisers,
            })
          : '') +
        (selectedSource.length > 0
          ? '&' +
            new URLSearchParams({
              source: selectedSource,
            })
          : '') +
        (selectedCountry.length > 0
          ? '&' +
            new URLSearchParams({
              country: selectedCountry,
            })
          : ''),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return await result.data;
  } catch (error) {
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else if (error.request) {
      const status = error.request.status;
      const message = error.request.statusText;
      return rejectWithValue({ status, message });
    } else {
      console.log('Error', error.message);
    }
  }
};
