import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetFilters,
  selectAdvertiser,
  selectAllAdvertisers,
  selectAllCountries,
  selectAllSources,
  selectCountry,
  selectSource,
  setDateRange,
} from '../../state/domains-report/actions';
import domainsFiltersSelector from '../../state/domains-report/selectors/filter/filters';
import filtersSelector from '../../state/analytics/selectors/filters/filters';
import DatePicker from '../global/filters/DatePicker';
import ApplyButton from '../global/filters/ApplyButton';
import ResetButton from '../global/filters/ResetButton';
import CommonFilter from '../global/filters/CommonFilter';
import { setDomainsReportData } from '../../state/domains-report/async-actions';

const FiltersRow = ({ type }) => {
  const { advertisers, sources, countries } = useSelector(filtersSelector);
  const { selectedAdvertisers, selectedSource, selectedDate, selectedCountry } = useSelector(domainsFiltersSelector);
  const dispatch = useDispatch()

  const resetFunc = () => {
    dispatch(resetFilters())
  }

  const applyFunc = () => {
  const isFilter = true
    dispatch(
      setDomainsReportData({
        isFilter,
        type,
        selectedDate,
        selectedAdvertisers,
        selectedSource,
        selectedCountry,
      })
    );
  };

  const handleDateRange = (newRange) => {
    dispatch(setDateRange(newRange));
  };

  return (
    <div className='flex justify-between gap-3 items-center pb-5 max-md:flex-col'>
      <div className='flex items-center gap-3 max-md:flex-wrap'>
        <DatePicker handleSetDate={handleDateRange} selectedDate={selectedDate} />
        <CommonFilter
          name='Advertisers'
          items={advertisers}
          selectedItems={selectedAdvertisers}
          selectFunc={selectAdvertiser}
          selectAllFunc={selectAllAdvertisers}
        />
        <CommonFilter
          name='Sources'
          items={sources}
          selectedItems={selectedSource}
          selectFunc={selectSource}
          selectAllFunc={selectAllSources}
        />
        <CommonFilter
          name='Counties'
          items={countries}
          selectedItems={selectedCountry}
          selectFunc={selectCountry}
          selectAllFunc={selectAllCountries}
        />
      </div>
      <div className='flex items-center gap-5 max-md:mr-auto'>
        <ApplyButton applyFunc={applyFunc} isPaginate={false}  />
        <ResetButton resetFunc={resetFunc} />
      </div>
    </div>
  );
};

export default FiltersRow;
