import React from 'react';
import ApplyButton from '../../global/filters/ApplyButton';
import ResetButton from '../../global/filters/ResetButton';
import { useDispatch, useSelector } from 'react-redux';

import CommonFilter from '../../global/filters/CommonFilter';
import filterSelector from '../../../state/push/selectors/messages/filters';
import pageInfoSelector from '../../../state/push/selectors/messages/pageInfo';
import { resetFilters, selectAllCategories, selectCategory } from '../../../state/push/actions';
import { messageCategories } from '../../../constants/app-form';
import { getMessages } from '../../../state/push/async-actions';
import MessageIDInput from './id-input';
import CreateButton from '../../global/filters/CreateButton';
import { toggleCreateMessageModal } from '../../../state/modals/actions';
import CreateMessageModal from '../../modals/CreateMessageModal';

const FiltersRow = () => {
  const {
    selectedCategories,
    selectedID,
  } = useSelector(filterSelector);
  const { currentPage, pageSize } = useSelector(pageInfoSelector);
  const dispatch = useDispatch()
  const applyFunc = () => {
    dispatch(
      getMessages({
        selectedID,
        selectedCategories,
        pageSize,
        currentPage,
      })
    );
  };

  const resetFunc = () => {
    dispatch(resetFilters())
  }

  const createFunc = () => {
    dispatch(toggleCreateMessageModal())
  }

  return (
    <div className='flex px-[30px] justify-between gap-3 items-center pb-5 pt-7 min-w-max max-md:flex-col max-md:items-start'>
      <div className='flex items-center gap-3'>
        <CommonFilter
          name='Categories'
          items={messageCategories}
          selectedItems={selectedCategories}
          selectFunc={selectCategory}
          selectAllFunc={selectAllCategories}
        />
       <MessageIDInput />
      </div>
      <div className='flex items-center gap-5 max-md:gap-1'>
        <CreateButton createFunc={createFunc}/>
        <ApplyButton applyFunc={applyFunc} isFirstPage={currentPage === 1} isPaginate={true} />
        <ResetButton resetFunc={resetFunc} />
      </div>
      <CreateMessageModal />
    </div>
  );
};

export default FiltersRow;
