import { analyticSlice } from './slice';

export const {
  setDateRange,
  setSort,
  selectAdvertiser,
  selectAllAdvertisers,
  selectSource,
  selectAllSources,
  selectCountry,
  selectAllCountries,
  selectLandingID,
  selectAllLandingsID,
  selectUserEmail,
  resetFilters,
  setCurrentPage,
  setPageLimit,
} = analyticSlice.actions;
