import React from 'react';
//////variant icon and general

const TertiaryButton = ({ type, children, disabled, handleClick }) => {
  return (
    <button
      type={type}
      onClick={handleClick}
      disabled={disabled}
      className='group/btn p-2 rounded-[4px] border bg-grey-6 dark:bg-black-1 border-grey-3 dark:border-black-3 hover:bg-grey-4 dark:hover:bg-grey-0 active:bg-grey-3 dark:active:bg-black-3 focus:outline-none disabled:bg-grey-3 dark:disabled:bg-grey-1 disabled:text-grey-1 transition ease-in-out duration-300'
    >
      {children}
    </button>
  );
};

export default TertiaryButton;
