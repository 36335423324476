import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/global/Loading';
import { authListener } from '../firebase/auth';
import { selectMenuItem } from '../state/menus/actions';
import { resetUser } from '../state/user/actions';
import { setAuthUser } from '../state/user/async-actions';
import { meSelector } from '../state/user/selectors/self';
import AuthRouter from './AuthRouter';
import GuestRouter from './GuestRouter';
import ROUTES from '../constants/routes';

export default function AppRouter() {
  const initialMenuItem = useCallback(() => {
    const path = window.location.pathname;
    let routes = Object.values(ROUTES);
    routes = [...routes, ...Object.values(ROUTES.ANALYTICS.submenu)]
    const { name } = [...routes].find(({ route }) => {
      return route === path || (route !== ROUTES.HOME.route && path.includes(route));
    });
    return name;
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = authListener((user) => {
      const action = user?.email ? setAuthUser(user) : resetUser();
      dispatch(action);

      dispatch(selectMenuItem(initialMenuItem()));
    });
    return () => unsubscribe();
  }, [dispatch, initialMenuItem]);
  
  const { id, isLoading } = useSelector(meSelector);

  if (isLoading) {
    return <Loading />;
  }

  return <>{id ? <AuthRouter /> : <GuestRouter />}</>;
}
