import React, { useEffect, useState } from 'react';
import ModalsWrapper from '../wrappers/ModalsWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { toggleUpdateLimitModal } from '../../state/modals/actions';
import { FormProvider, useForm } from 'react-hook-form';
import { getClient, updateLimit } from '../../state/client-management/async-actions';
import BlockButton from '../ui/buttons/block-btn';
import _ from 'lodash';
import updateLimitModalSelector from '../../state/modals/selectors/update-limit';
import clientSelector from '../../state/client-management/selectors/clients/client';
import LimitValueTypeSel from '../crm/clients/client/form-sections/limit_value_type-sel';
import InputField from '../ui/input';

const UpdateLimitModal = ({ selectedLimit }) => {
  const [isDataChanged, setIsDataChanged] = useState(false);

  const updateLimitModal = useSelector(updateLimitModalSelector);
  const client = useSelector(clientSelector);
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'all',
    values: {
      value: selectedLimit ? selectedLimit[0]?.value : '',
      value_type: selectedLimit ? selectedLimit[0]?.value_type : '',
    },
  });

  const {
    handleSubmit,
    getValues,
    reset,
    // eslint-disable-next-line no-unused-vars
    formState: { isValid, errors },
  } = methods;

  const values = getValues();

  useEffect(() => {
    if(selectedLimit){
      if (values.value == selectedLimit[0]?.value && values.value_type === selectedLimit[0]?.value_type) {
        setIsDataChanged(false);
      } else {
        setIsDataChanged(true);
      }
    }
  }, [values]);

  const submit = (data) => {
    dispatch(updateLimit(data))
      .unwrap()
      .then(() => {
        dispatch(toggleUpdateLimitModal());
      })
      .then(() => {
        dispatch(getClient(client.id));
      })
      .then(() => {
        reset();
      });
  };

  return (
    <ModalsWrapper title={'Update limit'} modal={updateLimitModal} close={() => dispatch(toggleUpdateLimitModal())}>
      <FormProvider {...methods}>
        <section id='names' className='mb-6'>
          <div className='flex gap-6 flex-col'>
            <LimitValueTypeSel />
            <InputField
              inputId={'value'}
              isRequired={true}
              label={'Value'}
              placeholder={'Enter a limit value'}
              type={'text'}
            />
          </div>
        </section>
        <div className='flex justify-center gap-6'>
          <BlockButton handleClick={() => dispatch(toggleUpdateLimitModal())}>Cancel</BlockButton>
          <BlockButton disabled={!isDataChanged} handleClick={handleSubmit(submit)}>
            Update
          </BlockButton>
        </div>
      </FormProvider>
    </ModalsWrapper>
  );
};

export default UpdateLimitModal;
