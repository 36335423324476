export const langs = [
  { Czech: 'cs' },
  { English: 'en' },
  { Danish: 'da' },
  { Dutch: 'nl' },
  { Finnish: 'fi' },
  { French: 'fr' },
  { German: 'de' },
  { Hungarian: 'hu' },
  { Italian: 'it' },
  { Latvian: 'lv' },
  { Lithuanian: 'lt' },
  { Norwegian: 'nb' },
  { Polish: 'pl' },
  { Portuguese: 'pt' },
  { Romanian: 'ro' },
  { Slovak: 'sk' },
  { Spanish: 'es' },
  { Swedish: 'sv' },
];

export function getKeyByValue(value) {
  for (const obj of langs) {
    if (Object.values(obj)[0] === value) {
      return Object.keys(obj)[0];
    }
  }
  return null;
}

export function getValueByKey(value) {
  for (const obj of langs) {
    if (Object.keys(obj)[0] === value) {
      return Object.values(obj)[0];
    }
  }
  return null;
}