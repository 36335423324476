import React from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import { TODAY_DATE } from '../../../constants/date';

const DatePicker = ({selectedDate, handleSetDate}) => {
  return (
    <div className='w-[235px]'>
      <Datepicker
        value={selectedDate}
        onChange={handleSetDate}
        primaryColor={'red'}
        placeholder={`${TODAY_DATE} - ${TODAY_DATE}`}
        separator={'-'}
        showShortcuts={true}
        displayFormat={'DD/MM/YYYY'}
        containerClassName='relative dark:border-main-black-2 border-main-grey-2 border rounded-md [&>button>svg]:stroke-main-black dark:[&>button>svg]:stroke-main-grey-2'
        inputClassName='bg-inherit pr-2 border-none placeholder:text-black dark:placeholder:text-main-grey-2 dark:text-main-grey-2'
      />
    </div>
  );
};

export default DatePicker;
