import React, { useEffect, useRef, useState } from 'react';
import { RoundedCloseIcon, SearchIcon } from '../../../assets/icons';
import DropdownList from '../dropdown-list';
import SecondaryButton from '../buttons/secondary';
import classNames from 'classnames';
///options in props
const SearchBar = ({ options, onSearch, onChange, placeholder, label, selectedVal, isDisabled, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [isDropdownOpen, setDropdownIsOpen] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener('click', toggle);
    return () => document.removeEventListener('click', toggle);
  }, []);

  const selectOption = (option) => {
    setQuery('');
    onChange(option);
    setDropdownIsOpen((isOpen) => !isOpen);
  };

  const toggle = (e) => {
    setDropdownIsOpen(e && e.target === inputRef.current);
  };

  const onSearchClick = () => {
    setIsOpen(!isOpen)
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;

    return '';
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setQuery(searchTerm);
    onChange(searchTerm);
  };

  return (
    <div className='relative w-fit h-fit flex items-center justify-center'>
      <div className='relative flex items-center overflow-hidden'>
        {isOpen && (
          <input
            ref={inputRef}
             value={value}
            className={classNames(
              'w-64 h-10 pl-3 py-2 bg-white dark:bg-black-1 rounded-l-md text-sm font-onest-regular placeholder-grey-2 text-black-3 dark:text-white border-r-0 border-grey-2 dark:border-black-3 focus:outline-none focus:ring-transparent focus:border-grey-2 dark:focus:border-black-3',
              {
                'pr-10': query || selectedVal,
              }
            )}
            type='text'
            placeholder={placeholder || 'Enter your search terms...'}
            onChange={handleSearch}
            onClick={toggle}
          />
        )}
        {(query || selectedVal) && (
          <button
            type='button'
            onClick={() => {
              setQuery('');
              onChange('');
            }}
            className='absolute top-0 right-11 h-full w-10 flex justify-center items-center z-30 bg-inherit'
          >
            <RoundedCloseIcon className='fill-grey-1' />
          </button>
        )}
        {isOpen ? (
          <button
            type='button'
            onClick={() => onSearchClick()}
            className='flex justify-center items-center h-10 w-10 rounded-r-md bg-red active:bg-red-burgundy'
          >
            <SearchIcon className='fill-white' />
          </button>
        ) : (
          <SecondaryButton
            type={'button'}
            variant={'icon'}
            handleClick={() => onSearchClick()}
            disabled={isDisabled}
          >
            <SearchIcon className='fill-grey-2 group-hover:fill-white group-active:fill-white group-disabled:fill-grey-1 dark:group-disabled:fill-black-3' />
          </SecondaryButton>
        )}
      </div>
      {/* <DropdownList
        isOpen={isDropdownOpen}
        label={label}
        options={options}
        selectOption={selectOption}
        selectedVal={selectedVal}
        query={query}
      /> */}
    </div>
  );
};

export default SearchBar;
