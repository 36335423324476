import classNames from 'classnames';
import React from 'react';

const Header = ({ data }) => {
  return (
    <thead className='sticky top-[-32px]'>
      <tr>
        {data.map((col, index) => (
          <th
            scope='col'
            key={index}
            className={classNames(
              'px-3 align-middle  text-left bg-light-table-header dark:bg-dark-table-header first:pl-[38px] py-[10px] first:left-0 font-extrabold border-r-[1.5px] border-b-[1.5px] border-main-grey-3 dark:border-main-black',
              {
              }
            )}
          >
            {col}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default Header;
