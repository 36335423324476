import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TdWrapper from '../../ui/table/TdWrapper';
import appsSelector from '../../../state/push/selectors/apps/apps';
import DeleteButton from '../../ui/buttons/actions/Delete';
import { toggleDeleteAppConfModal } from '../../../state/modals/actions';
import { setAppToDelete } from '../../../state/push/actions';
import { getApp } from '../../../state/push/async-actions';

const AppsTableBody = () => {
  const apps = useSelector(appsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <tbody>
      {apps &&
        !!apps.length &&
        apps.map(({ id, name, push_stats, message_category, include_segment, status, need_to_create, need_to_delete }) => (
          <tr
            id={id}
            key={id}
            className='h-[72px] border-b border-grey-3 dark:border-black-3 hover:bg-grey-3 dark:hover:bg-black-3 transition duration-300 ease-in-out'
          >
            <TdWrapper>
              <span
                onClick={() => {
                  if (need_to_create) {
                    navigate(`/push/apps/${id}`);
                  } else {
                    dispatch(getApp(id))
                      .unwrap()
                      .then(() => navigate(`/push/apps/${id}`));
                  }
                }}
                className='inline-flex w-full h-full text-blue-dark dark:text-blue-light cursor-pointer whitespace-break-spaces'
              >
                {name}
              </span>
            </TdWrapper>
            <TdWrapper styles={'max-md:hidden'}>{id}</TdWrapper>
            <TdWrapper>{status}</TdWrapper>
            <TdWrapper>{push_stats ? push_stats +"%" : ""}</TdWrapper>
            <TdWrapper styles={'max-md:hidden'}>{message_category?.join(', ')}</TdWrapper>
            <TdWrapper styles={'max-md:hidden'}>{include_segment?.join(', ')}</TdWrapper>
            <TdWrapper>
              <span className={need_to_create && 'text-red'}>{need_to_create && 'YES'}</span>
              {need_to_create}
            </TdWrapper>
            <TdWrapper>
              {need_to_delete && (
                <DeleteButton
                  handleDelete={() => dispatch(toggleDeleteAppConfModal()) && dispatch(setAppToDelete(id))}
                />
              )}
            </TdWrapper>
          </tr>
        ))}
    </tbody>
  );
};

export default AppsTableBody;
