import React from 'react';

const TdWrapper = ({ children, styles, handleClick }) => {
  return (
    <td
      className={`first:pl-[30px] text-base overflow-hidden font-onest-regular text-black-1 dark:text-white px-4 py-2 last:pr-[30px] ${styles}`}
      onClick={handleClick}
    >
      {children}
    </td>
  );
};

export default TdWrapper;
