export const errorHandler = (error) => {
  switch (error) {
    case 'auth/wrong-password':
      return 'Invalid email or password';
    case 'auth/user-not-found':
      return 'This user does not exist';
    case 'auth/email-already-in-use':
      return 'This email already in use';
    case 'auth/network-request-failed':
      return "Network problems. Let's try again! ";
    case 'auth/too-many-requests':
      return 'Sorry, too many requests';
    case 'unavailable':
      return 'Unavaliable!';
    default:
      return error;
  }
};
