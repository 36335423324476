import Joi from 'joi';

export const blockSchema = Joi.object({
  block_name: Joi.string().min(1).max(50).trim().required().messages({
    'string.base': `It should be a type of 'text'`,
    'string.min': `This field should have a min length of {#limit}`,
    'string.max': `This field should have a max length of {#limit}`,
    'string.empty': `This field can not be empty`,
    'string.required': `This field is required`,
  }),
});
