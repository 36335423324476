import React from 'react';
import bgLight from '../../assets/homepage/home_page_light.png';
import bgDark from '../../assets/homepage/home_page_dark.jpg';
import { THEMES } from '../../constants/themes';
import { useSelector } from 'react-redux';
import themeSelector from '../../state/menus/selectors/theme';
import classNames from 'classnames';
import sidebarMenuSelector from '../../state/menus/selectors/sidebar';
import HomeIcon from './HomeIcon';
import { HomeDashboardIcon} from '../../assets/icons/homepage/menu-icons';
import ROUTES from '../../constants/routes';

const { CRM } = ROUTES;

const Home = () => {
  const theme = useSelector(themeSelector);
  const sidebar = useSelector(sidebarMenuSelector);

  return (
    <section
      className={classNames(
        'flex flex-col gap-8 px-[30px] py-8 bg-cover bg-center bg-repeat-y w-full h-auto overflow-y-auto overflow-x-hidden',
        {
          'min-w-[calc(100vw-128px-60px)]': !sidebar,
          'min-w-[calc(100vw-238px-60px)]': sidebar,
        }
      )}
      style={
        theme === THEMES.light
          ? {
              backgroundImage: `url(${bgLight})`,
            }
          : {
              backgroundImage: `url(${bgDark})`,
            }
      }
    >
      <div className='flex flex-col'>
        <h1 className='text-4xl text-black-1 dark:text-white mb-2'>Greetings The Best Employers!</h1>
        <div className='max-w-[540px] font-onest-medium text-base flex flex-col gap-2 text-black-3 dark:text-grey-3'>
          <p>
            Welcome to this beautiful dashboard
          </p>
          <p className='mt-8'>
            We’re continually improving and adding new features to dashboard, and you can use these modules:{' '}
          </p>
        </div>
      </div>
      <div className='flex justify-between gap-4 lg:justify-start relative lg:gap-24'>
        <HomeIcon
          item={CRM}
          title='Advertisers'
          description='Manage Cascade advertisers.'
        >
          <HomeDashboardIcon />
        </HomeIcon>
      </div>
    </section>
  );
};

export default Home;
