import React from 'react';

const Checkbox = ({ id, isChecked, isDisabled, onCheck, onClick, title }) => {
  return (
    <input
      title={title}
      id={id}
      type='checkbox'
      checked={isChecked}
      disabled={isDisabled}
      onChange={onCheck}
      onClick={(e) => !onClick && e.stopPropagation()}
      className='w-4 h-4 text-red checked:bg-red dark:checked:bg-red bg-white dark:bg-black-1 border-grey-2 dark:border-black-3 hover:border-red dark:hover:border-red checked:border-none rounded-sm focus:ring-grey-1 focus:ring-1 cursor-pointer disabled:cursor-not-allowed disabled:bg-grey-3 dark:disabled:bg-grey-1'
    />
  );
};

export default Checkbox;
