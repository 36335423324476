export const APPS_TABLE_HEADERS = [
  {
    key: 'appName',
    title: 'App name',
    width: 150,
  },
  {
    key: 'id',
    title: 'ID',
    width: 150,
  },
  {
    key: 'status',
    title: 'Status',
    width: 80,
  },
  {
    key: 'push_stats',
    title: 'Push-stats %',
    width: 80,
  },
  {
    key: 'category',
    title: 'Category',
    width: 65,
  },
  {
    key: 'segment',
    title: 'Segment',
    width: 65,
  },
  {
    key: 'needToCreate',
    title: 'Need to Create',
    width: 65,
  },
  {
    key: 'needToDelete',
    title: 'Need to Delete',
    width: 65,
  },
];

export const MSGS_TABLE_HEADERS = [
  {
    key: 'id',
    title: 'ID',
    width: 30,
  },
  {
    key: 'category',
    title: 'Category',
    width: 65,
  },
  {
    key: 'heading',
    title: 'Heading',
    width: 150,
  },
  {
    key: 'test',
    title: 'Text',
    width: 150,
  },
  {
    key: 'icon',
    title: 'Icon',
    width: 65,
  },
  {
    key: 'image',
    title: 'Image',
    width: 65,
  },
  {
    key: 'stats',
    title: 'Stats %',
    width: 65,
  },
  {
    key: 'action',
    title: 'Action',
    width: 65,
  },
];