import React from 'react';

const FormsWrapper = ({ children }) => {
  return (
    <div className='bg-white dark:bg-black-1 pt-[76px] font-onest-medium justify-center min-h-screen flex items-center w-screen mx-auto'>
      {children}
    </div>
  );
};

export default FormsWrapper;
