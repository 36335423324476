import React from 'react';
import { useSelector } from 'react-redux';
import Header from './Header';
import ControlPanel from './control-panel/ControlPanel';
import selectedItemSelector from '../../../state/menus/selectors/selected-item';
import selectedSubMenuItemSelector from '../../../state/menus/selectors/selected-submenu-item';

const AuthenticatedHeader = () => {
  const selectedTitle = useSelector(selectedItemSelector);
  const selectedSubmenuItem = useSelector(selectedSubMenuItemSelector);
  
  return (
    <Header>
      <h3 className='text-2xl text-black-1 dark:text-white'>{selectedSubmenuItem ? selectedSubmenuItem : selectedTitle}</h3>
      <ControlPanel />
    </Header>
  );
};

export default AuthenticatedHeader;
