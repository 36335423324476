import React from 'react';
import ModalsWrapper from '../wrappers/ModalsWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAddBlockModal } from '../../state/modals/actions';
import addBlockModalSelector from '../../state/modals/selectors/add-block';
import { FormProvider, useForm } from 'react-hook-form';
import BlocksSection from '../crm/clients/client/form-sections/blocksSel';
import { addBlocks, getSources, getClient } from '../../state/client-management/async-actions';
import BlockButton from '../ui/buttons/block-btn';
import clientSelector from '../../state/client-management/selectors/clients/client';

const AddBlockModal = () => {
  const addBlockModal = useSelector(addBlockModalSelector);
  const client = useSelector(clientSelector);
  const dispatch = useDispatch();
  
  React.useEffect(() => {
    dispatch(getSources());
  }, [dispatch]);

  const submit = (data) => {
    let sourcesToAdd = Object.values(data).map((item => item.map(item2 => (item2.name))));
    dispatch(addBlocks(...sourcesToAdd))
      .unwrap()
      .then(() => {
        dispatch(toggleAddBlockModal());
      })
      .then(() => {
        dispatch(getClient(client.id));
      })
      .then(() => {
        reset()
      });
  };

  const methods = useForm({ mode: 'all' });

  const {
    handleSubmit,
    control,
    reset,
    // eslint-disable-next-line no-unused-vars
    formState: { isValid, errors },
  } = methods;

  return (
    <ModalsWrapper title={'Add source block'} modal={addBlockModal} close={() => dispatch(toggleAddBlockModal())}>
      <FormProvider {...methods}>
        <div className='flex flex-col gap-6'>
          <BlocksSection control={control} />
          <div className='w-full flex gap-6 justify-center [&>div>*]:w-1/2'>
            <BlockButton disabled={false} handleClick={() => dispatch(toggleAddBlockModal())}>
              Cancel
            </BlockButton>
            <BlockButton handleClick={handleSubmit(submit)}>Add</BlockButton>
          </div>
        </div>
      </FormProvider>
    </ModalsWrapper>
  );
};

export default AddBlockModal;
