import React from 'react';
import ROUTES from '../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { errorHandler } from '../../helpers/firebaseErrorHandler';
import { meSelector } from '../../state/user/selectors/self';
import Loading from '../global/Loading';
import FormsWrapper from '../wrappers/FormsWrapper';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { signInSchema } from '../../schemas/signInSchema';
import { signIn } from '../../state/user/async-actions';
import IconEye from '../../assets/icons/IconEye.svg';
import IconNotEye from '../../assets/icons/IconNotEye.svg';
import classNames from 'classnames';

const SignInForm = () => {
  const [show, setShow] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { error, isLoading } = useSelector(meSelector);
  error = errorHandler(error);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ mode: 'all', resolver: joiResolver(signInSchema) });

  const submit = (data) => {
    dispatch(signIn(data));

    navigate(ROUTES.HOME.route);
  };

  return (
    <FormsWrapper>
      {isLoading ? (
        <Loading />
      ) : (
        <div className='flex-1 max-w-md lg:w-2/6 mt-6 pb-7 px-6 '>
          <h2 className='text-center text-[24px] tracking-wider font-bold text-main-black dark:text-main-white'>
            Sign in to access your account
          </h2>
          <p className='h-8 text-center'>{error && <span className=' text-red-1 text-xs'>{error}</span>}</p>
          <form onSubmit={handleSubmit(submit)}>
            <div>
              <label htmlFor='email' className='block mb-2 text-sm text-main-black dark:text-gray-200'>
                Email ID
              </label>
              <input
                {...register('email')}
                type='email'
                id='email'
                placeholder='example@example.com'
                className='block w-full px-4 py-2 mt-2 border rounded-md font-onest-regular text-gray-700 border-gray-200 placeholder-gray-400 bg-main-white dark:text-gray-300 dark:border-gray-700 dark:placeholder-gray-600 dark:bg-main-black focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-50'
              />
              <p className='h-6 '>
                {errors?.email && <span className=' text-red-600 text-xs'>{errors?.email?.message}</span>}
              </p>
            </div>
            <div className='mt-3'>
              <div className='flex justify-between mb-2'>
                <label htmlFor='password' className='text-sm text-main-black dark:text-gray-200'>
                  Password
                </label>
              </div>
              <div className='relative'>
                <input
                  {...register('password')}
                  type={show ? 'text' : 'password'}
                  id='password'
                  placeholder='Your Password'
                  className='block w-full px-4 py-2 mt-2 border rounded-md font-onest-regular bg-main-white text-gray-700 placeholder-gray-400 border-gray-200 dark:bg-main-black dark:text-gray-300 dark:placeholder-gray-600 dark:border-gray-700 focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-50'
                />
                <img
                  onClick={() => setShow(!show)}
                  alt='View password'
                  className={classNames('absolute right-3 scale-125 cursor-pointer', {
                    'top-[15px]': show,
                    'top-[13px]': !show,
                  })}
                  src={show ? IconEye : IconNotEye}
                />
              </div>
              <p className='h-6 '>
                {errors?.password && <span className='text-red-600 text-xs'>{errors?.password?.message}</span>}
              </p>
            </div>
            <div className='mt-6'>
              <button
                disabled={!isValid}
                type='submit'
                className='w-full px-4 py-2 bg-orange-400 mb-3 tracking-wide text-main-white disabled:bg-orange-200 disabled:cursor-not-allowed transition-colors duration-200 transform rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600 focus:ring focus:ring-red-300 focus:ring-opacity-50'
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      )}
    </FormsWrapper>
  );
};

export default SignInForm;
