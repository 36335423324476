import Joi from 'joi';

export const clientSchema = Joi.object({
  name: Joi.string().min(2).max(100).trim().messages({
    'string.empty': 'The "Name" field is required',
    'string.base': 'The "Name" field should contain only letters and digits',
    'string.min': 'The "Name" field should have at least {#limit} characters',
    'string.max': 'The "Name" field should have at most {#limit} characters',
  }),
  priority: Joi.number().integer().min(1).max(999).optional().messages({
    'number.base': 'The "Priority" field should be a number',
    'number.integer': 'The "Priority" field should be an integer',
    'number.min': 'The "Priority" field should be at least {#limit}',
    'number.max': 'The "Priority" field should be at most {#limit}',
  }),
  status: Joi.string().valid('active', 'not-active').optional().messages({
    'string.base': 'The "Status" field should be a string',
    'any.only': 'The "Status" field should be either "active" or "not-active"',
  }),
  payment_model: Joi.string().valid('PPL', 'PPS').optional().messages({
    'string.base': 'The "Payment model" field should be a string',
    'any.only': 'The "Payment model" field should be either "PPL" or "PPS"',
  }),
  send_subsource: Joi.boolean().messages({
    'boolean.base': 'The "Send sub-source" field should be a boolean',
  }),
  http_method: Joi.string().valid('POST', 'GET').optional().messages({
    'string.base': 'The "HTTP method" field should be a string',
    'any.only': 'The "HTTP method" field should be either "GET" or "POST"',
  }),
  payout: Joi.number().min(0.1).max(100).optional().messages({
    'number.base': 'The "Payout" field should be a number',
    'number.integer': 'The "Payout" field should be an integer',
    'number.min': 'The "Payout" field should be at least {#limit}',
    'number.max': 'The "Payout" field should be at most {#limit}',
  }),
  api_key: Joi.string().allow('').optional().min(4).max(100).trim().messages({
    'string.min': 'The "Api key" field should have at least {#limit} letters',
    'string.max': 'The "Api key" field should have at most {#limit} letters',
  }),
  request_address: Joi.string().min(3).max(100).optional().allow('').trim().messages({
    'string.pattern.base': 'Please enter a valid URL (e.g., https://example.com)',
    'string.min': 'The "URL" field should have at least {#limit} characters',
    'string.max': 'The "URL" field should have at most {#limit} characters',
  }),
  partner_id: Joi.number().integer().allow('').optional().messages({
    'string.pattern.base': 'The "Partner ID" field should be a number',
    'string.min': 'The "Partner ID" field should have at least {#limit} characters',
  }),
});
