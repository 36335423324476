import classNames from 'classnames';
import React from 'react';

const Body = ({ data }) => {
  const tableHeaders = Object.keys(data);

  return (
    <tbody>
      {data[tableHeaders[0]].map((_, rowIndex) => (
        <tr key={rowIndex}>
          {tableHeaders.map((header, colIndex) => (
            <td
              key={colIndex}
              className={classNames(
                'border-b-[1.5px] border-r-[1.5px] first:bg-[#FCFCFC] dark:first:bg-[#555454] border-[#E8E8E8] dark:border-[#3E3E3E] py-[22px] first:pl-[38px] first:pr-[22px] px-2 first:text-left whitespace-nowrap first:font-onest-medium first:sticky first:left-0'
              )}
            >
              {`${colIndex === 10 || colIndex === 5 || colIndex === 11   ? "$": ''} ${data[header][rowIndex]} ${colIndex > 6 && colIndex < 10  ? "%": ''}`}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default Body;
