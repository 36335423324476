import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import summarySelector from '../../../state/analytics/selectors/table/summary';

const ReportTableFooter = () => {
  const {
    totalSubmitsCleaned,
    totalRevenue,
    totalLeads,
    totalRejectsPercent,
    totalSuccessPercent,
    totalLeads2success,
    totalRev2success,
    totalSucces,
    totalRejectsCleaned,
    totalUniqUsersCleaned,
    totalAvgPayout,
  } = useSelector(summarySelector);

  var footerData = [
    'Total',
    totalRejectsCleaned,
    totalSucces,
    totalSubmitsCleaned,
    totalLeads,
    totalRevenue,
    totalUniqUsersCleaned,
    totalRejectsPercent,
    totalSuccessPercent,
    totalLeads2success,
    totalRev2success,
    totalAvgPayout,
  ];
  return (
    <tfoot className='dark:bg-main-black-2 bg-additional-blue sticky bottom-0'>
      <tr className='font-semibold text-gray-900 dark:text-main-white'>
        {footerData.map((col, index) => (
          <td
            key={index}
            className={classNames(
              'py-[22px] first:pl-[38px] first:text-left px-2 whitespace-nowrap first:font-onest-medium first:sticky first:left-0 dark:bg-dark-table-header bg-light-table-header border-r-[1.5px] border-main-grey-3 dark:border-[#131313]'
            )}
          >
            {`${index === 10 || index === 5 || index === 11 ? '$' : ''} ${col} ${index > 6 && index < 10 ? '%' : ''}`}
          </td>
        ))}
      </tr>
    </tfoot>
  );
};

export default ReportTableFooter;
