import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ArrowIcon,
  EmployeesIcon,
  PushIcon,
  AnalitycIcon,
  TrackerIcon,
  FBIcon,
} from '../../../assets/icons/menu/menu-icons';
import logo from '../../../assets/logo-fb.png';
import ROUTES from '../../../constants/routes';
import { resetSidebarAnimation, selectMenuItem, toggleSidebarMenu } from '../../../state/menus/actions';
import MenuItem from './MenuItem';
import sidebarMenuSelector from '../../../state/menus/selectors/sidebar';
import sidebarAnimationSelector from '../../../state/menus/selectors/sidebar-animation';
import { meSelector } from '../../../state/user/selectors/self';
import showSidebarMenuSelector from '../../../state/menus/selectors/sidebar-mob';
import burger from '../../../assets/icons/menu/menu.svg';
import { toggleShowSideBar } from '../../../state/menus/actions';

const { CRM, ANALYTICS, TRACKER_REPORT, PUSH, FB } = ROUTES;

const SidebarMenu = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const sidebar = useSelector(sidebarMenuSelector);
  const animation = useSelector(sidebarAnimationSelector);
  const showSidebar = useSelector(showSidebarMenuSelector);
  const { role } = useSelector(meSelector);
  const navigate = useNavigate();
  const resetAnimation = () => {
    setTimeout(() => dispatch(resetSidebarAnimation()), 300);
  };
  const [sidebarCollapsedButton, setSidebarCollapsedButton] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showSidebar && ref.current && !ref.current.contains(e.target)) {
        dispatch(toggleShowSideBar());
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showSidebar, dispatch]);

  return (
    
    <>
    { (showSidebar && ref.current )&& <div className='absolute w-full h-full bg-[#00000091] z-30'></div>}
      <aside
        ref={ref}
        className={classNames(
          'w-[124px] fixed top-0 p-[30px] flex flex-col items-center h-full bg-white dark:bg-black-1 border-r border-grey-3 dark:border-black-3 z-30 max-md:w-[238px] max-md:items-center',
          {
            'w-[238px] flex items-center': sidebar,
            'ease-linear duration-150': animation,
            'max-md:hidden': !showSidebar,
          }
        )}
        onMouseEnter={() => setSidebarCollapsedButton(true)}
        onMouseLeave={() => {
          setSidebarCollapsedButton(false);
        }}
      >
        <img
          src={logo}
          className='mb-8 cursor-pointer max-md:hidden max-w-[130px]'
          alt='AffBar'
          to={ROUTES.HOME.route}
          onClick={() => {
            dispatch(selectMenuItem(ROUTES.HOME.name));
            navigate(ROUTES.HOME.route);
          }}
        />
        <img
          src={burger}
          className='cursor-pointer md:hidden w-12 z-50 relative top-[-12px] left-[-65px]'
          alt='AffBar'
          onClick={() => {
            dispatch(toggleShowSideBar());
          }}
        />
        <ul className='flex flex-col gap-4'>
          {role === 'admin' && (
            <>
              <MenuItem sidebar={true} item={CRM}>
                <EmployeesIcon />
              </MenuItem>
              <MenuItem sidebar={true} item={ANALYTICS}>
                <AnalitycIcon />
              </MenuItem>
              <MenuItem sidebar={true} item={TRACKER_REPORT}>
                <TrackerIcon />
              </MenuItem>
              <MenuItem sidebar={true} item={PUSH}>
                <PushIcon />
              </MenuItem>
              <MenuItem sidebar={true} item={FB}>
                <FBIcon />
              </MenuItem>
            </>
          )}
        </ul>
        <div className={classNames('w-full h-fit relative max-md:hidden', { hidden: !sidebarCollapsedButton })}>
          <button
            className='flex items-center justify-center rounded-md text-red h-8 w-8 ring-1 ring-red bg-white dark:bg-black-1 absolute top-0 -right-12'
            data-test-id='workspace-navigation-collapse-button'
            type='button'
            aria-expanded='true'
            aria-label='Workspace navigation'
            onClick={() => dispatch(toggleSidebarMenu()) && resetAnimation()}
          >
            <img
              src={ArrowIcon}
              className={classNames({ 'rotate-180': !sidebar })}
              alt='Workspace navigation collapse icon'
            />
          </button>
        </div>
      </aside>
    </>
  );
};

export default SidebarMenu;
