export const CLIENTS_TABLE_HEADERS = [
  {
    key: 'clientName',
    title: 'Client name',
    width: 200,
  },
  {
    key: 'status',
    title: 'Status',
    width: 100,
  },
  {
    key: 'priority',
    title: 'Priotity',
    width: 100,
  },
  {
    key: 'payout',
    title: 'Payout',
    width: 65,
  },
  {
    key: 'paymentModel',
    title: 'Payment model',
    width: 100,
  },
];

export const BLOCKS_TABLE_HEADERS = [
  {
    key: 'number',
    title: '#',
    width: 50,
  },
  {
    key: 'name',
    title: 'Name',
    width: 300,
  },
  {
    key: 'blacklist',
    title: 'Blacklist',
    width: 300,
  },
  {
    key: 'customRotation',
    title: 'Custom Rotation',
    width: 300,
  },
  {
    key: 'actions',
    title: '',
    width: 130,
  },
];