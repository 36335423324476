import React from 'react';
import Breadcrumbs from '../../../ui/breadcrumbs';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMessage } from '../../../../state/push/async-actions';
import msgSelector from '../../../../state/push/selectors/messages/message';
import MessageForm from './Form';

const Message = () => {
  const param = useParams()
  const dispatch = useDispatch()
  const message = useSelector(msgSelector)

  React.useEffect(() => {
    dispatch(getMessage(param.id))
  },[])
  return (
    <div className='w-full h-full pl-[30px]'>
      <Breadcrumbs linkTo={'/push/messages'} firstLevel={'messages'} secondLevel={!message?.id ? 'unknown' : `${message.id}`} />
      <div className='overflow-auto h-[calc(100%_-_96px)] flex'>
        {message &&<MessageForm message={message} action="update"/>}
      </div>
    </div>
  )
};

export default Message;
