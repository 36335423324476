import React from 'react';
import TabSwitcher from '../TabSwitcher';
import ROUTES from '../../constants/routes';

const parentRoute = ROUTES.PUSH.route;
const TABS = [
  { slug: `${parentRoute}/apps`, title: 'Applications' },
  { slug: `${parentRoute}/messages`, title: 'Messages' },
];

const Push = () => {
  return (
    <section className='relative flex flex-col w-full'>
      <TabSwitcher tabs={TABS} styles={'h-full'} parentSlug={parentRoute} />
    </section>
  );
};

export default Push;
