import React from 'react';
import ReactPaginate from 'react-paginate';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { setCurrentPage, setCursor, setNextCursor, setPrevCursor } from '../../../state/rejects-report/actions';
import pageSelector from '../../../state/rejects-report/selectors/page/page';
import reportSelector from '../../../state/rejects-report/selectors/report';

const PaginationButtons = ({ totalPages, setPageFunc }) => {
  const { pageSize, total, currentPage, nextCursor, cursor, prevCursor } = useSelector(pageSelector);
  const report = useSelector(reportSelector);
  const showNextButton = currentPage !== totalPages;
  const showPrevButton = currentPage !== 1;
  const dispatch = useDispatch();

  const handleNext = () => {
    if (currentPage !== totalPages) {
      dispatch(setCursor(nextCursor));
      dispatch(
        setPrevCursor({
          action: 'next',
          value: report[0].id + 1,
        })
      );
      dispatch(setCurrentPage(currentPage + 1));
    }
  };
  const handlePrev = () => {
    if (currentPage !== 1) {
      dispatch(setCursor(prevCursor[prevCursor.length - 1]));
      console.log(report[0].id + 1);
      dispatch(setPrevCursor({action: "prev"}))
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  return (
    <>
      <span
        className={classNames('w-10 h-10 flex items-center justify-center rounded-md', {
          'text-slate-600 cursor-default': !showPrevButton,
          'cursor-pointer': showPrevButton,
        })}
        onClick={() => handlePrev()}
      >
        <BsChevronLeft />
      </span>
      <span>
        {currentPage} of {totalPages}
      </span>
      <span
        className={classNames('w-10 h-10 flex items-center justify-center rounded-md mr-4', {
          'text-slate-600 cursor-default': !showNextButton,
          'cursor-pointer': showNextButton,
        })}
        onClick={() => handleNext()}
      >
        <BsChevronRight />
      </span>
    </>
  );
};

export default PaginationButtons;
