import React from 'react';
import TableWrapper from '../../ui/table/TableWrapper';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import clientsErrorSelector from '../../../state/client-management/selectors/error';
import clientsLoadingSelector from '../../../state/client-management/selectors/loading';
import { BLOCKS_TABLE_HEADERS} from '../../../constants/clients';
import Loading from '../../global/Loading';
import Page401 from '../../error-pages/Page401';
import Page403 from '../../error-pages/Page403';
import { deleteBlock, getClients, getSourcesWithPriority} from '../../../state/client-management/async-actions';
import BlocksTableBody from './TableBody';
import DeleteModal from '../../modals/DeleteModal';
import { toggleDeleteBlockConfModal } from '../../../state/modals/actions';
import deleteBlockConfModalSelector from '../../../state/modals/selectors/delete-block';
import ShowAdvertisersModal from '../../modals/ShowAdvertisersModal';
import CreateBlockModal from '../../modals/CreateBlockModal';
import TableBlocksHeader from '../../ui/table/BlocksHeader';
import sourcesWithPrioritySelector from '../../../state/client-management/selectors/sources/sources-priotity';
import SourceConfigModal from '../../modals/SourceConfigModal';
import sourceConfigSelector from '../../../state/modals/selectors/config-source';

const Sources = () => {
  const error = useSelector(clientsErrorSelector);
  const loading = useSelector(clientsLoadingSelector);
  const deleteConfirmation = useSelector(deleteBlockConfModalSelector);
  const sources = useSelector(sourcesWithPrioritySelector);
  const sourceConfigModal = useSelector(sourceConfigSelector);
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteBlock())
      .unwrap()
      .then(() => {
        dispatch(toggleDeleteBlockConfModal());
      });
  };

  React.useEffect(() => {
    dispatch(getSourcesWithPriority());
    dispatch(getClients());
  }, [dispatch]);

  if (error?.status === 401) {
    return <Page401 service='crm' error={error} />;
  }
  if (error?.status === 403) {
    return <Page403 />;
  }
  // if (loading) {
  //   return <Loading />;
  // }

  return (
    <>
      <div
        className={classNames('overflow-auto h-[calc(100%_-_96px)]', {
          'pt-52': !sources.length,
        })}
      >
        {sources &&  (
          <TableWrapper>
            <TableBlocksHeader headers={BLOCKS_TABLE_HEADERS} topStyle={0} />
            <BlocksTableBody sources={sources} />
          </TableWrapper>
        )}
      </div>
      <DeleteModal
        title={'Delete sourse block'}
        description={"Are you sure you want to delete this source block? If you delete it, you can't get it back."}
        onDelete={handleDelete}
        onClose={() => {
          dispatch(toggleDeleteBlockConfModal());
        }}
        deleteConfirmation={deleteConfirmation}
      />
      <ShowAdvertisersModal />
      <CreateBlockModal />
     {sourceConfigModal && <SourceConfigModal />}
    </>
  );
};

export default Sources;
