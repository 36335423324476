import { createSlice } from '@reduxjs/toolkit';
import { THEMES } from '../../constants/themes';
import getSystemTheme from '../../helpers/systemTheme';

const theme = getSystemTheme();

const initialState = {
  user: false,
  sidebar: false,
  sidebarSubmenu: false,
  showSidebar: false,
  sidebarAnimation: false,
  selectedItem: '',
  selectedSubmenuItem: '',
  theme: localStorage.getItem('theme') || 'dark',
};

export const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    toggleUserMenu(state) {
      state.user = !state.user;
    },
    selectSubmenuMenuItem(state, { payload }) {
      state.selectedSubmenuItem = payload;
    },
    toggleShowSideBar(state) {
      state.showSidebar = !state.showSidebar;
    },
    toggleSidebarMenu(state) {
      state.sidebar = !state.sidebar;
      state.sidebarAnimation = true;
    },
    openSidebarMenu(state) {
      state.sidebar = true;
      state.sidebarAnimation = true;
    },
    togleSidebarSubMenu(state, {payload}) {
      state.sidebarSubmenu = payload;
      state.sidebarAnimation = true;
    },
    resetSidebarAnimation(state) {
      state.sidebarAnimation = initialState.sidebarAnimation;
    },
    selectMenuItem(state, { payload }) {
      state.selectedItem = payload;
    },
    changeTheme(state) {
      let { theme } = state;
      theme === THEMES.dark ? (theme = THEMES.light) : (theme = THEMES.dark);
      localStorage.setItem('theme', theme);
      return { ...state, theme };
    },
    setTheme(state, { payload }) {
      state.theme = payload;
    },
    resetMenus: () => initialState,
  },
});

export default menusSlice.reducer;
