import React from 'react';
import ModalsWrapper from '../wrappers/ModalsWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAddBlockModal, toggleAddLangModal } from '../../state/modals/actions';
import { FormProvider, useForm } from 'react-hook-form';
import BlockButton from '../ui/buttons/block-btn';
import addLangModalSelector from '../../state/modals/selectors/add-lang';
import LangSel from '../push/messages/message/langs-sel';
import { addLangs, getMessage } from '../../state/push/async-actions';
import msgSelector from '../../state/push/selectors/messages/message';
import { getValueByKey } from '../../constants/languages';

const AddLangModal = () => {
  const addLangModal = useSelector(addLangModalSelector);
  const message = useSelector(msgSelector);
  const dispatch = useDispatch();

  const submit = (data) => {
    data.heading = message.heading;
    data.content = message.content;
    data.message_id = message.id;
    data.langs = data.langs.map((obj) => getValueByKey(obj.name));
    dispatch(addLangs(data))
      .unwrap()
      .then(() => {
        dispatch(toggleAddLangModal());
      })
      .then(() => {
        dispatch(getMessage(message.id));
      })
      .then(() => {
        reset();
      });
  };

  const methods = useForm({ mode: 'all' });

  const {
    handleSubmit,
    control,
    reset,
    // eslint-disable-next-line no-unused-vars
    formState: { isValid, errors },
  } = methods;

  return (
    <div className='w-[500px]'>
      <ModalsWrapper title={'Add Lang'} modal={addLangModal} close={() => dispatch(toggleAddLangModal())}>
        <FormProvider {...methods}>
          <div className='flex flex-col gap-6'>
            <LangSel control={control} action='update' />
            <div className='w-full flex gap-6 justify-center [&>div>*]:w-1/2'>
              <BlockButton disabled={false} handleClick={() => dispatch(toggleAddBlockModal())}>
                Cancel
              </BlockButton>
              <BlockButton handleClick={handleSubmit(submit)}>Add</BlockButton>
            </div>
          </div>
        </FormProvider>
      </ModalsWrapper>
    </div>
  );
};

export default AddLangModal;
