import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDomainsReportData } from '../../state/domains-report/async-actions';
import errorSelector from '../../state/domains-report/selectors/error';
import loadingSelector from '../../state/domains-report/selectors/loading';
import Page401 from '../error-pages/Page401';
import Page403 from '../error-pages/Page403';
import Loading from '../global/Loading';
import FiltersRow from './FiltersRow';
import classNames from 'classnames';
import sidebarMenuSelector from '../../state/menus/selectors/sidebar';
import ReportData from './Accordion';
import { getFilters } from '../../state/analytics/async-actions';
import domainsFiltersSelector from '../../state/domains-report/selectors/filter/filters';
import reportSelector from '../../state/domains-report/selectors/report';

const Domains = () => {
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);
  const loading = useSelector(loadingSelector);
  const sidebar = useSelector(sidebarMenuSelector);
  const report = useSelector(reportSelector);

  const { selectedDate, selectedAdvertisers, selectedSource, selectedCountry } = useSelector(domainsFiltersSelector);

  useEffect(() => {
    dispatch(setDomainsReportData({ selectedDate, selectedAdvertisers, selectedSource, selectedCountry }));
    dispatch(getFilters());
  }, [dispatch, selectedDate]);

  if (error?.status === 401) {
    return <Page401 service='dashboard' error={error} />;
  }
  if (error?.status === 403) {
    return <Page403 />;
  }
  if (error) {
    return <h2 className='text-black-1 dark:text-white text-3xl pt-10 text-center'>{error.message}</h2>;
  }
  if (loading) return <Loading />;
  return (
    <section
      className={classNames('flex flex-col px-[30px] max-md:px-[10px] pt-8 gap-8 pb-[100px] overflow-x-auto', {
        'min-w-[calc(100vw-128px-60px)]': !sidebar,
        'min-w-[calc(100vw-238px-60px)]': sidebar,
      })}
    >
      <FiltersRow />
      {Object.keys(report).length === 0 ? (
        <h2 className='text-black-1 dark:text-white text-3xl pt-10 text-center'>Not found</h2>
      ) : (
        <ReportData />
      )}
    </section>
  );
};

export default Domains;
