import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCreateMessageModal } from '../../state/modals/actions';
import MessageForm from '../push/messages/message/Form';
import createMessageModalSelector from '../../state/modals/selectors/create-message';
import CreateMessageModalsWrapper from '../wrappers/CreateMessageModalWrapper';

const CreateMessageModal = () => {
  const dispatch = useDispatch();
  const createMessage = useSelector(createMessageModalSelector);
  let emptyMessage = {
    id: undefined,
    category: [],
    heading: "",
    content: "",
    icon: "",
    image: "",
    langs: [{id: 0, name: "English"}]
  }

  return (
    <CreateMessageModalsWrapper
      title={'Create message'}
      modal={createMessage}
      close={() => dispatch(toggleCreateMessageModal())}
    >
      <MessageForm message={emptyMessage} action="create"/>
    </CreateMessageModalsWrapper>
  );
};

export default CreateMessageModal;
