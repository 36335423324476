import React, { useState } from 'react';
import BlockButton from '../../../../ui/buttons/block-btn';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedBlock, setSelectedLimit } from '../../../../../state/client-management/actions';
import AddBlockModal from '../../../../modals/AddBlockModal';
import CreateBlockModal from '../../../../modals/CreateBlockModal';
import selectedLimitSelector from '../../../../../state/client-management/selectors/clients/selectet-limit';
import AddLimitModal from '../../../../modals/AddLimitModal';
import { toggleAddLimitModal, toggleUpdateLimitModal } from '../../../../../state/modals/actions';
import { deleteLimit } from '../../../../../state/client-management/async-actions';
import UpdateLimitModal from '../../../../modals/UpdateLimitModal';

const LimitsSection = ({ limits }) => {
  const [selectedLimitIDX, setSelectedLimitIDX] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  
  const selectedLimit = useSelector(selectedLimitSelector);
  const dispatch = useDispatch();
  
  const handleBlockClick = (id, limitID) => {
    setSelectedLimitIDX(selectedLimitIDX === id ? null : id);
    setButtonDisabled(selectedLimit === limitID ? true : false);
    dispatch(setSelectedLimit(selectedLimit === limitID ? null : limitID));
  };

  const handleDelete = () => {
    dispatch(deleteLimit());
    setButtonDisabled(true);
    setSelectedLimitIDX(null);
  };

  const handleClickAdd = () => {
    dispatch(toggleAddLimitModal());
  };

  const handleClickUpdate = () => {
    dispatch(toggleUpdateLimitModal());
    // setButtonDisabled(true);
    // setSelectedLimitIDX(null);
  };

  return (
    <section id='limits' className='flex flex-col gap-4'>
      <h3 className='text-2xl'>Advertiser limits:</h3>
      <div className='flex gap-4'>
        <div className='flex flex-col gap-3 w-[300px] border rounded-md border-grey-2 p-3 h-[250px] overflow-auto'>
          {limits &&
            !!limits.length &&
            limits.slice().sort(function(a, b) {
              var nameA = a.limit_type.toUpperCase(); 
              var nameB = b.limit_type.toUpperCase();
              if (nameA < nameB) {
                return -1;
              } else if (nameA > nameB) {
                return 1;
              } else {
                return 0;
              }}).map((limit, id) => (
              <div
                key={id}
                onClick={() => handleBlockClick(id, limit.id)}
                className={classNames(
                  'flex justify-around p-2 w-full text-sm rounded-md border hover:border-black-3 focus:border-black-3 dark:hover:border-grey-4 dark:focus:border-grey-4 read-only:bg-grey-4 dark:read-only:border-grey-0 dark:read-only:bg-black-3 dark:read-only:text-grey-3 focus:ring-0 dark:focus:ring-0',
                  {
                    '!border-grey-4 !bg-grey-1 !text-gray-950': selectedLimitIDX === id,
                  }
                )}
              >
                <span>{limit.limit_type}</span>
                <span>{limit.limit_name}</span>
                <span>{limit.value} ({limit.value_type})</span>
              </div>
            ))}
        </div>
        <div className='flex flex-col gap-4 justify-center mr-2'>
          <BlockButton disabled={isButtonDisabled} handleClick={handleDelete}>
            Delete
          </BlockButton>
          <BlockButton disabled={isButtonDisabled} handleClick={handleClickUpdate}>
            Update
          </BlockButton>
          <BlockButton handleClick={handleClickAdd}>Add</BlockButton>
        </div>
      </div>
      <AddLimitModal />
      <CreateBlockModal />
      <UpdateLimitModal selectedLimit={limits?.filter(({id}) => id === selectedLimit)} />
    </section>
  );
};

export default LimitsSection;
