import React from 'react';
import ModalsWrapper from '../wrappers/ModalsWrapper';
import { useDispatch, useSelector } from 'react-redux';
import showAdvertisersModalSelector from '../../state/modals/selectors/show-advertisers';
import { toggleShowAdvertisersModal } from '../../state/modals/actions';
import advertisersSelector from '../../state/client-management/selectors/sources/advertisers';
import BlockButton from '../ui/buttons/block-btn';

const ShowAdvertisersModal = () => {
  const showAdvetisersBlockModal = useSelector(showAdvertisersModalSelector);
  const ads = useSelector(advertisersSelector);
  const dispatch = useDispatch();

  return (
    <ModalsWrapper
      title={'Advetisers:'}
      modal={showAdvetisersBlockModal}
      close={() => dispatch(toggleShowAdvertisersModal())}
    >
     <div className='mb-5'>
        {ads.slice().sort().map((elem, id) => (
          <div key={id} className='mb-2'>{elem}</div>
        ))}
     </div>
      <BlockButton disabled={false} handleClick={() => dispatch(toggleShowAdvertisersModal())}>
        Cancel
      </BlockButton>
    </ModalsWrapper>
  );
};

export default ShowAdvertisersModal;
