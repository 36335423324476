import React from 'react';
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import chevron from '../../assets/icons/shevron-down.svg';
import { useSelector } from 'react-redux';
import reportSelector from '../../state/domains-report/selectors/report';

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={({ state: { isEnter } }) => (
      <>
        {header}
        <img
          className={`ml-auto transition-transform duration-200 ease-out ${isEnter && 'rotate-180'}`}
          src={chevron}
          alt='Chevron'
        />
      </>
    )}
    buttonProps={{
      className: ({ isEnter }) =>
        `flex w-full uppercase p-2 text-left hover:bg-slate-100 dark:hover:bg-grey-2 text-lg font-medium font-onest-medium text-black-3 dark:bg-black-3 border rounded-md dark:text-grey-3 ${
          isEnter && 'bg-blue-0 dark:bg-grey-0'
        }`,
    }}
    contentProps={{
      className: 'transition-height duration-200 ease-out',
    }}
    panelProps={{ className: 'px-4' }}
  />
);

const headers = ['Domain', 'Leads', 'Successes', 'Lead2Success %', 'AVG Payout $'];
const ReportData = () => {
  const report = useSelector(reportSelector);

  return (
    <div className=''>
      <Accordion allowMultiple transition transitionTimeout={200}>
        {Object.keys(report).map((val, idx) => (
          <AccordionItem key={val} header={val} initialEntered>
            <table className='text-base max-md:text-sm text-center transition-all font-onest-regular text-main-black dark:text-main-white border-separate border-spacing-0 w-full'>
              <thead>
                <tr>
                  {headers.map((header, hidx) => (
                    <th
                      scope='col'
                      key={hidx}
                      className='px-3 first:w-[70%] first:text-left bg-light-table-header dark:bg-dark-table-header first:pl-[38px] max-md:first:pl-1 py-[10px] max-md:py-0  font-extrabold border-r-[1.5px] border-b-[1.5px] border-main-grey-3 dark:border-main-black'
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.values(report)[idx].map((domainData, didx) => (
                  <tr className='' key={didx}>
                    {Object.values(domainData).map((colData, colIdx) => (
                      <td
                        key={colIdx}
                        className='border-b-[1.5px] border-l-[1.5px] border-r-[1.5px] border-[#E8E8E8] dark:border-[#3E3E3E] py-[10px] first:pl-[38px] max-md:first:pl-1  px-2 first:text-left whitespace-nowrap first:font-onest-medium first:sticky first:left-0'
                      >
                        {colData + ` ${colIdx === 3 ? '%' : '' || colIdx === 4 ? '$' : ''}`}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default ReportData;
