import React from 'react';
import TertiaryButton from '../tertiary';
import { EditIcon } from '../../../../assets/icons';

const EditButton = ({ isEditDisabled, handleEdit }) => {
  return (
    <TertiaryButton disabled={isEditDisabled} handleClick={handleEdit} type={'button'}>
      <EditIcon className='fill-black-1 dark:fill-white group-disabled/btn:fill-grey-1 dark:group-disabled/btn:fill-black-3' />
    </TertiaryButton>
  );
};

export default EditButton;
