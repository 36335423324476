import { Listbox, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ExpandArrowIcon, LinedExpandArrowIcon } from '../../../assets/icons';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';

const SelectBox = ({
  name,
  selectedValue,
  onSelectChange,
  onClickSelect,
  options,
  placeholder,
  isDisabled,
  isReadOnly,
  secondary,
  label,
}) => {
  const { trigger, watch } = useFormContext() || {};
  watch && watch(name);
  return (
    <Listbox value={selectedValue} onChange={onSelectChange} by={label && 'id'} disabled={isDisabled} name={name}>
      {({ open, disabled, value }) => (
        <div
          className={classNames(
            'h-10 min-w-[164px] font-onest-regular text-sm flex items-center pl-3 pr-2 rounded-md border border-grey-2 dark:border-black-3 hover:border-black-3 group-hover:border-black-3 dark:hover:border-grey-4 dark:group-hover:border-grey-4 dark:disabled:border-none disabled:cursor-not-allowed',
            {
              'w-full': secondary,
              'w-fit': !secondary,
              'bg-grey-3 dark:bg-black-3': open,
              'dark:border-black-3': !value && secondary,
              'dark:border-white': value && secondary && !isReadOnly && !isDisabled,
              'bg-grey-4 dark:border-grey-0 dark:bg-black-3 dark:text-grey-3': isReadOnly && disabled,
              'bg-grey-3 dark:bg-grey-1 text-grey-1 dark:text-black-3 border-none cursor-default':
                disabled && !isReadOnly,
            }
          )}
        >
          <Listbox.Button
            onClick={(e) => (onClickSelect ? e.stopPropagation() : trigger && trigger(name))}
            aria-readonly={isReadOnly}
             className='group relative w-full cursor-pointer text-light-grey py-2 pr-10 text-left disabled:cursor-default'
          >
            {!selectedValue && placeholder && (
              <Listbox.Label
                className={classNames('text-sm whitespace-nowrap text-grey-2 dark:text-grey-1', {
                  'text-grey-1 dark:text-grey-0': disabled,
                })}
              >
                {placeholder}
              </Listbox.Label>
            )}
            <span className='block truncate'>
              {label && _.isObject(selectedValue) ? selectedValue[label] : selectedValue}
            </span>
            <span
              className='pointer-events-none absolute inset-y-0 right-0 flex items-center'
             >
              {secondary ? (
                <LinedExpandArrowIcon
                  className={classNames('fill-grey-1', { 'dark:fill-white rotate-180': open, invisible: disabled })}
                />
              ) : (
                <ExpandArrowIcon
                  className={classNames('fill-grey-1', { 'dark:fill-white rotate-180': open, invisible: disabled })}
                />
              )}
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options 
                className='top-full absolute right-0 mt-1 max-h-[200px] w-full z-50 overflow-auto rounded-lg text-black-3 dark:text-white bg-grey-4 dark:bg-black-2 py-2 text-sm shadow-md dark:shadow-black-3'
            >
              {options.map((option, optionIdx) => (
                <Listbox.Option
                  key={option?.id || optionIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-3 ${active && 'bg-white dark:bg-black-3'}`
                  }
                  value={option.value}
                  onClick={(e) => onClickSelect && e.stopPropagation()}
                >
                  {({ selected }) => (
                    <span className={`block truncate ${selected && 'font-bold'}`}>
                      {label ? option[label] : option}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default SelectBox;
