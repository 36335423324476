import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedLang } from '../../../../state/push/actions';
import { toggleAddLangModal } from '../../../../state/modals/actions';
import { deleteLang } from '../../../../state/push/async-actions';
import AddLangModal from '../../../modals/AddLangModal';
import BlockButton from '../../../ui/buttons/block-btn';
import selectedLangSelector from '../../../../state/push/selectors/messages/selectedLeng';

const Lanquage = ({langs}) => {
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  
  const selectedLang = useSelector(selectedLangSelector);
  const dispatch = useDispatch();

  const handleLengClick = (lang) => {
    if (lang !== "English"){
      dispatch(setSelectedLang(selectedLang !== lang ? lang : ""));
      setButtonDisabled(selectedLang !== lang ? false : true);
    }
  };

  const handleDelete = () => {
    dispatch(deleteLang());
    setButtonDisabled(true);
  };

  const handleClickAdd = () => {
    dispatch(toggleAddLangModal())
  }
  return (
    <section id='projects' className='flex flex-col gap-4 text-left'>
      <div><h3 className='text-2xl'>Languages:</h3></div>
      <div className='flex gap-4'>
        <div className='flex flex-col gap-3 w-[300px] border rounded-md border-grey-2 p-3 h-[250px] overflow-auto'>
          {langs &&
            !!langs.length &&
            langs.map((lang, id) => (
              <div
                key={id}
                onClick={() => handleLengClick(lang)}
                className={classNames(
                  'Leng p-2 w-full text-sm rounded-md border hover:border-black-3 focus:border-black-3 dark:hover:border-grey-4 dark:focus:border-grey-4 read-only:bg-grey-4 dark:read-only:border-grey-0 dark:read-only:bg-black-3 dark:read-only:text-grey-3 focus:ring-0 dark:focus:ring-0',
                  {
                    '!border-grey-4 !bg-grey-1 !text-gray-950': lang === selectedLang,
                  }
                )}
              >
                <span>{lang}</span>
              </div>
            ))}
        </div>
        <div className='flex flex-col gap-4 justify-center mr-2'>
          <BlockButton disabled={isButtonDisabled} handleClick={handleDelete}>
            Delete
          </BlockButton>
          <BlockButton
          handleClick = {handleClickAdd}
          >Add</BlockButton>
          {/* <BlockButton
          handleClick = {handleClickCreate}
          >Create</BlockButton> */}
        </div>
      </div>
      <AddLangModal />
    </section>
  );
};

export default Lanquage;
