import React from 'react';
import { Controller } from 'react-hook-form';
import LabeledSelectBox from '../../../../ui/select-box/labeled';
import MultiSelect from '../../../../ui/multi-select';

const CategorySel = ({ control }) => {
  var categories = [
    {
      id: 0,
      name: 'general',
    },
    { id: 1, 
      name: 'gay' },
  ];

  // const selCategories = categories.map((id, value) => ({ id: value, name: id }));

  return (
    <section id='message_category' className='flex flex-col gap-4'>
      <div className='w-[300px]'>
        <Controller
          name='message_category'
          control={control}
          render={({ field: { value, name, onChange }, fieldState: { error } }) => (
            <LabeledSelectBox label={'Message Categories:'} isRequired={true} error={error}>
              <MultiSelect
                id={name}
                placeholder={'Select category'}
                itemLabel={'name'}
                options={categories}
                onChange={onChange}
                values={value || []}
                //  values={value ? value.map((id, value ) => ({ id: value, name: id })) : []}
                isRequired={false}
                isDisabled={!categories.length}
                isReadOnly={false}
              />
            </LabeledSelectBox>
          )}
        />
      </div>
    </section>
  );
};

export default CategorySel;
