import React from 'react';
import { Link } from 'react-router-dom';
import { BackIcon } from '../../../assets/icons';

const Breadcrumbs = ({ linkTo, handleClick, firstLevel, secondLevel }) => {
  return (
    <div className='sticky top-0 flex gap-2 pt-8 pb-6 text-sm font-onest-regular items-center'>
      <Link to={linkTo} className='flex gap-2 items-center' onClick={handleClick}>
        <BackIcon className='stroke-grey-2 dark:stroke-grey-1' />
        <span className='text-grey-2 dark:text-grey-1'>{firstLevel}</span>
      </Link>
      <span className='text-grey-2 dark:text-grey-1'>/</span>
      <span className='text-black-1 dark:text-white'>{secondLevel}</span>
    </div>
  );
};

export default Breadcrumbs;
