export const prepareSortValue = (val, type) => {
  var res = val;
  if (val.toLowerCase().includes('submits')) {
    res = 'submit';
  } else if (val.toLowerCase().includes('users')) {
    res = 'users';
  }

  return res.replace('type', type);
};

export const prepareFooterData = (data) => {
  var res = [];
  for (var key in data) {
    if (key === 'rejectsPercent' || key === 'successPercent' || key === 'leads2success' || key === 'rev2success') {
      res.push(
        (data[key].reduce((acc, arr) => acc + arr, 0) / (data.type.length > 0 ? data.type.length : 1)).toFixed(1)
      );
    } else {
      res.push(data[key].reduce((acc, arr) => acc + arr, 0));
    }
  }

  return res;
};

export const getLineGraphData = (type, rows) => {
  var chartType = {
    date: 'Date',
    advertiser: 'Advertisers',
    source: 'Sources',
    country: 'Countries',
    landing: 'Landing',
  };

  var allData = [...rows.submits, ...rows.leads, ...rows.revenue.map((val) => val.toFixed(0))];
  var series, options;
  series = [
    {
      name: 'Submits',
      data: rows.submits ? rows.submits : [],
    },
    {
      name: 'Leads',
      data: rows.leads ? rows.leads : [],
    },
    {
      name: 'Revenue',
      data: rows.revenue ? rows.revenue.map((val) => val.toFixed(0)) : [],
    },
  ];

  options = {
    chart: {
      height: 500,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
      foreColor: '#000000',
    },
    colors: ['#e90e0e', '#3e61d3', '#04fa10'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    title: {
      text: 'Stats',
      align: 'left',
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: rows.type,
      title: {
        text: chartType[type],
      },
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: 600,
        },
      },
    },
    yaxis: {
      title: {
        text: 'Value',
      },
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: 600,
        },
      },
      min: 0,
      max: allData.length > 0 ? Math.round(Math.max(...allData) / 100) * 100 + 100 : 30,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  return {
    options: options,
    series: series,
  };
};

export const getPieGrapthData = (rows) => {
  let types = ['submits', 'leads', 'revenue'];
  let res = [];
  types.forEach((type, idx) => {
    res.push({
      series: rows[type],
      options: {
        chart: {
          type: 'pie',
          foreColor: '#000',
        },
        labels: rows.type,
        legend: {
          show: idx === 0 ? true : true,
          position: 'left',
          fontSize: '16px',
        },
      },
      title: type,
    });
  });
  return res;
};
