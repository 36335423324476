import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import clientsSelector from '../../../state/client-management/selectors/clients/clients';
import TdWrapper from '../../ui/table/TdWrapper';
import { getClient } from '../../../state/client-management/async-actions';

const ClientsTableBody = () => {
  const clients = useSelector(clientsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <tbody>
      {clients &&
        !!clients.length &&
        clients.map(
          ({
            id,
            name,
            priority,
            status,
            payout,
            payment_model
          }) => (
            <tr
              id={id}
              key={id}
              className='h-[72px] border-b border-grey-3 dark:border-black-3 hover:bg-grey-3 dark:hover:bg-black-3 transition duration-300 ease-in-out'
            >
              <TdWrapper>
                <span
                  onClick={() => {
                    dispatch(getClient(id))
                      .unwrap()
                      .then(() => navigate(`/crm/clients/${id}`));
                  }}
                  className='inline-flex w-full h-full text-blue-dark dark:text-blue-light cursor-pointer whitespace-break-spaces'
                >
                  {name}
                </span>
              </TdWrapper>
              <TdWrapper>{status}</TdWrapper>
              <TdWrapper>{priority}</TdWrapper>
              <TdWrapper styles={'max-md:hidden'}>{payout}</TdWrapper>
              <TdWrapper styles={'max-md:hidden'}>{payment_model}</TdWrapper>
            </tr>
          )
        )}
    </tbody>
  );
};

export default ClientsTableBody;
