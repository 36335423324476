import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  openSidebarMenu,
  selectMenuItem,
  selectSubmenuMenuItem,
  togleSidebarSubMenu,
} from '../../../state/menus/actions';
import sidebarSelector from '../../../state/menus/selectors/sidebar';
import selectedMenuItemSelector from '../../../state/menus/selectors/selected-item';
import sidebarSubmenuSelector from '../../../state/menus/selectors/submenu';
import selectedSubMenuItemSelector from '../../../state/menus/selectors/selected-submenu-item';

const MenuItem = ({ item, sidebar, children }) => {
  const dispatch = useDispatch();
  const selectedItem = useSelector(selectedMenuItemSelector);
  const selectedSubmenuItem = useSelector(selectedSubMenuItemSelector);
  const sidebarSubmenu = useSelector(sidebarSubmenuSelector);
  const isSelectedItem = selectedItem === item.title;
  const sidebarCollapsed = useSelector(sidebarSelector);
  const { route, name, title, submenu } = item;

  const handleSubmenuClick = (submenu) => {
    dispatch(selectSubmenuMenuItem(submenu));
  };

  const handleClickItem = () => {
    dispatch(selectMenuItem(title));
    if (submenu) {
      dispatch(openSidebarMenu());
      dispatch(togleSidebarSubMenu(!sidebarSubmenu));
    } else {
      dispatch(selectSubmenuMenuItem(''));
      dispatch(togleSidebarSubMenu(false));
    }
  };

  return (
    <li
      key={route}
      title={title}
      onClick={() => handleClickItem()}
      className={classNames('block w-full rounded-xl', {
        'bg-red text-white': isSelectedItem,
        'hover:bg-grey-3 dark:hover:bg-black-3 hover:text-grey-1': !isSelectedItem,
        'text-base text-grey-1': sidebar && !isSelectedItem,
      })}
    >
      <Link
        to={route}
        className={classNames('flex items-center w-full px-5 py-[10px] fill-grey-2 stroke-grey-2', {
          'fill-white stroke-white': sidebar && isSelectedItem,
        })}
      >
        {children}
        <span
          className={classNames('max-md:block', {
            'font-onest-medium ml-4 transition-all': sidebar,
            hidden: sidebar && !sidebarCollapsed,
          })}
        >
          {sidebar && name === 'Profile settings' ? 'Settings' : title ? title : name}
        </span>
      </Link>
      {sidebarSubmenu && sidebarCollapsed && (
        <ul>
          {submenu?.map((menu, idx) => (
            <li
              key={idx}
              title={menu.name}
              onClick={(e) => {
                e.stopPropagation();
                handleSubmenuClick(menu.name);
              }}
              className={classNames('block w-full text-white pl-7 last:rounded-b-xl hover:text-black-3', {
                ' bg-slate-500': selectedSubmenuItem === menu.name,
                ' hover:text-black-3': !selectedSubmenuItem,
              })}
            >
              <Link
                to={menu.route}
                className={classNames('flex items-center w-full px-5 py-[10px] fill-grey-2 stroke-grey-2', {
                  'fill-white stroke-white': selectedSubmenuItem,
                })}
              >
                <span
                  className={classNames('max-md:block', {
                    'font-onest-medium ml-4 transition-all': sidebar,
                    hidden: sidebar && !sidebarCollapsed,
                  })}
                >
                  {sidebar && menu.name}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default MenuItem;
