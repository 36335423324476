import React from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import chartSelector from '../../../state/analytics/selectors/chart/chart';

const SummaryLineChart = ({isShow, options, series }) => {
  return (
    <div className={!isShow ? 'hidden' : ''}>
      <Chart options={options} series={series} type='line' width={'100%'} height={500} />
    </div>
  );
};

export default SummaryLineChart;
