import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TdWrapper from '../../ui/table/TdWrapper';
import DeleteButton from '../../ui/buttons/actions/Delete';
import { toggleDeleteMsgConfModal } from '../../../state/modals/actions';
import { setMsgToDelete } from '../../../state/push/actions';
import msgsSelector from '../../../state/push/selectors/messages/messages';
import EditButton from '../../ui/buttons/actions/Edit';
import classNames from 'classnames';

const MsgsTableBody = () => {
  const messages = useSelector(msgsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <tbody>
      {messages &&
        !!messages.length &&
        messages.map(({ id, stats, status, category, heading, content, icon, image }) => (
          <tr
            id={id}
            key={id}
            className= {classNames('h-[72px] border-b border-grey-3 dark:border-black-3 hover:bg-grey-3 dark:hover:bg-black-3 transition duration-300 ease-in-out', {
              'dark:bg-black-2 bg-grey-3': status === 'not-active'
            })}
          >
            <TdWrapper>{id}</TdWrapper>
            <TdWrapper styles={'max-md:hidden'}>{category?.join(', ')}</TdWrapper>
            <TdWrapper styles={'max-md:hidden'}>{heading?.find(obj => obj.lang === 'en').text}</TdWrapper>
            <TdWrapper styles={'max-md:hidden'}>{content?.find(obj => obj.lang === 'en').text}</TdWrapper>
            <TdWrapper>
              <img src={icon} alt='Icon view.' className='max-w-[70px]' />
            </TdWrapper>
            <TdWrapper styles={'max-md:hidden'}>
              <img src={image} alt='Img view' className='max-w-[70px]' />
            </TdWrapper>
            <TdWrapper >{stats}</TdWrapper>

            <TdWrapper>
              <div className='flex gap-3'>
                <DeleteButton handleDelete={() => dispatch(toggleDeleteMsgConfModal()) && dispatch(setMsgToDelete(id))} />
                <EditButton handleEdit={() => navigate(`/push/messages/${id}`)} />
              </div>
            </TdWrapper>
          </tr>
        ))}
    </tbody>
  );
};

export default MsgsTableBody;
