import showToast from '../../components/global/Toast';
import axios from '../../config/backoffice';
import { getValueByKey } from '../../constants/languages';
import { getToken } from '../../helpers/token';

export const fetchApps = async ({rejectWithValue}) => {
  const token = getToken();

  try {
    const clients = await axios.get('push/apps', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return clients.data;
  } catch (error) {
    console.log(error);
    
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};

export const fetchAppByID = async (id, { rejectWithValue }) => {
  const token = getToken();

  try {
    const client = await axios.get(`push/app/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return client.data;
  } catch (error) {
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};

export const removeApp = async ({ rejectWithValue, getState }) => {
  const token = getToken();
  const { toDelete } = getState().push.apps;

  try {
    const { data } = await axios.delete(`push/app/${toDelete}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    showToast({
      type: 'success',
      title: 'Deleted successfully',
      description: 'application was deleted!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.error || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const message = error.response.data.error;
      const status = error.response.data.status;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};

export const modifyApp = async (appInfo, { rejectWithValue, getState }) => {
  const token = getToken();

  if (appInfo.hasOwnProperty('frequency')) {
    appInfo.frequency = +appInfo.frequency;
  }
  appInfo.message_category?.length
    ? (appInfo.message_category = appInfo?.message_category.map(({ name }) => name))
    : (appInfo.message_category = undefined);
  appInfo.include_segment?.length
    ? (appInfo.include_segment = appInfo?.include_segment.map(({ name }) => name))
    : (appInfo.include_segment = undefined);

  try {
    const { data } = await axios.put(
      `push/app/${appInfo.id}`,
      { ...appInfo },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showToast({
      type: 'success',
      title: 'Updated successfully',
      description: 'Application was updated!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.message || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};

export const createApp = async (appInfo, { rejectWithValue, getState }) => {
  const token = getToken();

  if (appInfo.hasOwnProperty('frequency')) {
    appInfo.frequency = +appInfo.frequency;
  }

  appInfo.message_category = appInfo.message_category.map(({ name }) => name);
  appInfo.include_segment = appInfo.include_segment.map(({ name }) => name);

  try {
    const { data } = await axios.post(
      `push/app`,
      { ...appInfo },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showToast({
      type: 'success',
      title: 'Created successfully',
      description: 'Application was created!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.message || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};

//-----------------------------------MSGS---------------------------------------//

export const fetchMessages = async ({ selectedID, selectedCategories, pageSize, currentPage }, { rejectWithValue }) => {
  const token = getToken();
  try {
    const clients = await axios.get(
      'push/messages?' +
        new URLSearchParams({
          page: currentPage,
        }) +
        '&' +
        new URLSearchParams({
          limit: pageSize,
        }) +
        (selectedID !== ''
          ? '&' +
            new URLSearchParams({
              id: selectedID,
            })
          : '') +
        (selectedCategories.length > 0
          ? '&' +
            new URLSearchParams({
              category: selectedCategories,
            })
          : ''),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return clients.data;
  } catch (error) {
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};

export const fetchMessageByID = async (id, { rejectWithValue }) => {
  const token = getToken();

  try {
    const client = await axios.get(`push/message/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return client.data;
  } catch (error) {
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};

export const removeMessage = async ({ rejectWithValue, getState }) => {
  const token = getToken();
  const { toDelete } = getState().push.messages;

  try {
    const { data } = await axios.delete(`push/message/${toDelete}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    showToast({
      type: 'success',
      title: 'Deleted successfully',
      description: 'message was deleted!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.error || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const message = error.response.data.error;
      const status = error.response.data.status;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};

export const removeLang = async ({ rejectWithValue, getState }) => {
  const token = getToken();
  const { selectedLang } = getState().push.messages;
  const { id } = getState().push.messages.message;
  const lang = getValueByKey(selectedLang);

  try {
    const { data } = await axios.delete(`push/message/lang/${id}/${lang}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    showToast({
      type: 'success',
      title: 'Deleted successfully',
      description: 'Lenguage was deleted!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.error || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const message = error.response.data.error;
      const status = error.response.data.status;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};

export const modifyMessage = async (msgInfo, { rejectWithValue, getState }) => {
  const token = getToken();
  const { id } = getState().push.messages.message;
  if (msgInfo.category.length > 0) {
    msgInfo.category = msgInfo?.category.map(({ name }) => name);
  }

  try {
    const { data } = await axios.put(
      `push/message/${id}`,
      { ...msgInfo },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showToast({
      type: 'success',
      title: 'Updated successfully',
      description: 'Message was updated!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.message || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};

export const createMessage = async (msgInfo, { rejectWithValue, getState }) => {
  const token = getToken();
  if (msgInfo.category.length > 0) {
    msgInfo.category = msgInfo?.category.map(({ name }) => name);
  }
  if (msgInfo.langs.length > 0) {
    msgInfo.langs = msgInfo?.langs.map(({ name }) => getValueByKey(name));
  }
  try {
    const { data } = await axios.post(
      `push/message`,
      { ...msgInfo },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showToast({
      type: 'success',
      title: 'Created successfully',
      description: 'Message was created!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.message || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};

export const createLangs = async (langData, { rejectWithValue, getState }) => {
  const token = getToken();
  try {
    const { data } = await axios.post(
      `push/message/lang`,
      { ...langData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showToast({
      type: 'success',
      title: 'Created successfully',
      description: 'Language was added!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.message || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};
