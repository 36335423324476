import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { CloseIcon } from '../../assets/icons';

const ModalType = {
  error: 'error',
  success: 'success',
  info: 'info',
};

export default function showToast({ type, title, description }) {
  toast.custom(
    (t) => (
      <>
        <Transition
          show={t.visible}
          as={Fragment}
          enter='transform ease-out duration-300 transition'
          enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
          enterTo='translate-y-0 opacity-100 sm:translate-x-0'
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='max-w-sm w-full bg-grey-3 dark:bg-black-3 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black-1 ring-opacity-5 overflow-hidden'>
            <div className='p-4'>
              <div className='flex items-start'>
                <div className='flex-shrink-0'>
                  {type === ModalType.error && <XCircleIcon className='h-6 w-6 text-red-400' aria-hidden='true' />}
                  {type === ModalType.success && (
                    <CheckCircleIcon className='h-6 w-6 text-green-400' aria-hidden='true' />
                  )}
                  {type === ModalType.info && (
                    <InformationCircleIcon className='h-6 w-6 text-blue-400' aria-hidden='true' />
                  )}
                </div>
                <div className='ml-3 w-0 flex-1 pt-0.5'>
                  <p className='text-sm font-medium text-black-1 dark:text-white'>{title}</p>
                  <p className='mt-1 text-sm text-grey-2'>{description}</p>
                </div>
                <div className='ml-4 flex-shrink-0 flex'>
                  <button
                    className='inline-flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-1'
                    onClick={() => {
                      toast.dismiss(t.id);
                    }}
                  >
                    <span className='sr-only'>Close</span>
                    <CloseIcon className='fill-grey-1 dark:fill-grey-2 hover:fill-red dark:hover:fill-white' />
                    {/* <XMarkIcon className='h-5 w-5' aria-hidden='true' /> */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </>
    ),
    {
      duration: 6000,
    }
  );
}
