import React from 'react';
import ModalsWrapper from '../wrappers/ModalsWrapper';
import ActionButtonGroup from '../ui/button-group/ActionButtonGroup';

const UpdateModal = ({ title, description, deleteConfirmation, onClose, onUpdate }) => {
  return (
    <ModalsWrapper title={title} modal={deleteConfirmation} close={onClose}>
      <div className='flex flex-col gap-6'>
        <p className='text-sm text-black-3 dark:text-grey-2 text-justify whitespace-pre-line'>{description}</p>
        <div className='w-full [&>div>*]:w-1/2'>
          <ActionButtonGroup button={{ label: 'Update', handleClick: onUpdate }} link={{ handleLinkClick: onClose }} />
        </div>
      </div>
    </ModalsWrapper>
  );
};

export default UpdateModal;
