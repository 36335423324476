import { createSlice } from '@reduxjs/toolkit';
import {setAuthUser, signIn} from './async-actions';
import adminEmails from '../../constants/emails';

const initialState = {
  id: '',
  email: '',
  role: '',
  isLoading: true,
  error: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUser: () => ({ ...initialState, isLoading: false }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, { payload }) => {
        state.id = payload.uid;
        state.email = payload.email;
        state.role = adminEmails.includes(payload.email) ? "admin" : "user";
        state.isLoading = false;
      })
      .addCase(signIn.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signIn.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(setAuthUser.fulfilled, (state, { payload }) => {
        state.id = payload.uid;
        state.email = payload.email;
        state.role = adminEmails.includes(payload.email) ? "admin" : "user";
        state.isLoading = false;
      })
      .addCase(setAuthUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setAuthUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
});

export default userSlice.reducer;
