import React, { useEffect } from 'react';
import TableWrapper from './TableWrapper';
import Header from './Header';
import { useSelector } from 'react-redux';
import Body from './Body';
import Footer from './Footer';
import pageSelector from '../../../state/rejects-report/selectors/page/page';
import { setCurrentPage, setPageLimit } from '../../../state/rejects-report/actions';
import CursorPagination from '../../global/pagination-cursor/Pagination';

const Table = ({ report }) => {
  const { pageSize, total, currentPage } = useSelector(pageSelector);
  const limits = [10, 25, 50, 100];

  const headerData = ["ID", "Advertiser", "Error", "Source", "Country", "Email", "Created Time"]

  return (
    <div>
      <TableWrapper>
        <Header data={headerData} />
        <Body data={report} />
        {/* <Footer /> */}
      </TableWrapper>
      <div className='dark:text-white'>
        <CursorPagination
          size={pageSize}
          limits={limits}
          totalPages={Math.ceil(total / pageSize)}
          currentPage={currentPage}
          setPageFunc={setCurrentPage}
          setLimitFunc={setPageLimit}
        />
      </div>
    </div>
  );
};

export default Table;
