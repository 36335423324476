import { domainsSlice } from './slice';

export const {
  setDateRange,
  selectAdvertiser,
  selectAllAdvertisers,
  selectSource,
  selectAllSources,
  selectCountry,
  selectAllCountries,
  resetFilters,
} = domainsSlice.actions;
