import { Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { CloseIcon } from '../../assets/icons';

const MessageImagesModalsWrapper = ({ modal, title, close, children }) => {
  return (
    <Transition.Root show={modal} as={Fragment}>
      <div className='relative z-40'>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 z-10 bg-black-3 bg-opacity-20 backdrop-blur-[0.5px] dark:bg-black-1 dark:bg-opacity-30 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto flex min-h-full items-center justify-center text-center sm:items-center sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='relative flex flex-col transform rounded-lg transition-all overflow-auto h-[400px] w-[700px] max-md:w-[392px] sm:my-8'>
              <div className='bg-white dark:bg-black-2 p-6'>
                <div className='flex justify-between pb-4'>
                  {title && <h3 className='text-2xl font-medium text-left text-black dark:text-white mr-1'>{title}</h3>}
                  <button
                    className='p-2 rounded-md fill-grey-1 dark:fill-grey-2 hover:fill-red dark:hover:fill-white'
                    onClick={close}
                  >
                    <CloseIcon />
                  </button>
                </div>
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  );
};

export default MessageImagesModalsWrapper;
