export const generateShortForm = (str) => {
  const [firstWord, secondWord] = str.split(' ');
  const firstInitial = firstWord.charAt(0);
  // const secondInitial = surname.charAt(0);

  return `${firstInitial}. ${secondWord}`;
};

export const customCopy = (data) => navigator.clipboard.writeText(data);

export const uniteStrings = (...strings) =>  strings.join(',');

