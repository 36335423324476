import React from 'react';
import PageWrapper from '../components/wrappers/PageWrapper';
import Home from '../components/home/Home';

const HomePage = () => {
  return (
    <PageWrapper>
      <Home />
    </PageWrapper>
  );
};

export default HomePage;
