import React from 'react';
import TabSwitcher from '../TabSwitcher';
import ROUTES from '../../constants/routes';

const parentRoute = ROUTES.CRM.route;
const TABS = [
  { slug: `${parentRoute}/clients`, title: 'Clients' },
  { slug: `${parentRoute}/sources`, title: 'Sources' },
];

const CRM = () => {
  return (
    <section className='relative flex flex-col w-full'>
      <TabSwitcher tabs={TABS} styles={'h-full'} parentSlug={parentRoute} />
    </section>
  );
};

export default CRM;
