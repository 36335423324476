import React from 'react';
import TertiaryButton from '../tertiary';
import { ConfigIcon } from '../../../../assets/icons';

const ConfigButton = ({ isConfigDisabled, handleConfig }) => {
  return (
    <TertiaryButton disabled={isConfigDisabled} handleClick={handleConfig} type={'button'}>
      <ConfigIcon className='fill-black-1 dark:fill-white group-disabled/btn:fill-grey-1 dark:group-disabled/btn:fill-black-3' />
    </TertiaryButton>
  );
};

export default ConfigButton;
