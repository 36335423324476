import React, { useEffect, useState } from 'react';
import ModalsWrapper from '../wrappers/ModalsWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAddLimitModal } from '../../state/modals/actions';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import { addLimit, getClient } from '../../state/client-management/async-actions';
import BlockButton from '../ui/buttons/block-btn';
import clientSelector from '../../state/client-management/selectors/clients/client';
import addLimitModalSelector from '../../state/modals/selectors/add-limit';
import InputField from '../ui/input';
import { joiResolver } from '@hookform/resolvers/joi';
import { limitSchema } from '../../schemas/limitSchema';
import RadioField from '../ui/radio';
import LimitTypeSel from '../crm/clients/client/form-sections/limit-type-sel';

const limits = [
  {
    value: 'GEO',
    name: 'GEO',
  },
  {
    value: 's1',
    name: 'Source',
  },
  {
    value: 'general',
    name: 'General',
  },
];

const AddLimitModal = () => {
  const addLimitModal = useSelector(addLimitModalSelector);
  const client = useSelector(clientSelector);
  const dispatch = useDispatch();
  const [isDataChanged, setIsDataChanged] = useState(false);

  const submit = (data) => {
    dispatch(addLimit(data))
      .unwrap()
      .then(() => {
        dispatch(toggleAddLimitModal());
      })
      .then(() => {
        dispatch(getClient(client.id));
      })
      .then(() => {
        reset();
      });
  };

  const methods = useForm({ mode: 'all', resolver: joiResolver(limitSchema), values: '' });

  const {
    handleSubmit,
    reset,
    getValues,
    // eslint-disable-next-line no-unused-vars
    formState: { isValid, errors },
  } = methods;

  const values = getValues();

  useEffect(() => {
    if (_.isEmpty(values) || !_.isEmpty(errors)) {
      setIsDataChanged(true);
    } else {
      setIsDataChanged(false);
    }
  }, [values, errors]);

  useEffect(() => {
    // Reset all form fields except limit_type when limit_type changes
    const fieldsToReset = Object.keys(values).filter((key) => key !== 'limit_type');
    reset({ limit_type: values.limit_type, ...Object.fromEntries(fieldsToReset.map((key) => [key, undefined])) });
  }, [values.limit_type]);

  return (
    <ModalsWrapper title={'Add limit'} modal={addLimitModal} close={() => dispatch(toggleAddLimitModal())}>
      <FormProvider {...methods}>
        <section id='names' className='mb-6'>
          <div className='flex flex-col gap-6'>
            <RadioField inputId={'limit_type'} values={limits} title={'Limit type'} />
            {values.limit_type === 'GEO' && <LimitTypeSel />}
            {values.limit_type === 's1' && (
              <InputField
                inputId={'limit_name'}
                isRequired={true}
                label={'Source name'}
                placeholder={'Enter a limit name'}
                type={'text'}
              />
            )}
            <InputField
              inputId={'value'}
              isRequired={true}
              label={'Value'}
              placeholder={'Enter a limit value'}
              type={'text'}
            />
          </div>
        </section>
        <div className='flex justify-center gap-6'>
          <BlockButton handleClick={() => dispatch(toggleAddLimitModal()) && reset()}>Cancel</BlockButton>
          <BlockButton disabled={isDataChanged} handleClick={handleSubmit(submit)}>
            Add
          </BlockButton>
        </div>
      </FormProvider>
    </ModalsWrapper>
  );
};

export default AddLimitModal;
