import React from 'react';
import logo from '../../assets/loading_logo.svg';

const Loading = () => {
  return (
    <div className='relative z-40'>
      <div className='fixed inset-0 z-10 bg-black-3 bg-opacity-20 backdrop-blur-[0.5px] dark:bg-black-1 dark:bg-opacity-50 transition-opacity' />
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20'>
        <img className='animate-spin' src={logo} alt='logo' />
      </div>
    </div>
  );
};

export default Loading;
