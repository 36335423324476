import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import sidebarSelector from '../../../state/menus/selectors/sidebar';
import sidebarAnimationSelector from '../../../state/menus/selectors/sidebar-animation';
import burger from '../../../assets/icons/menu/menu.svg';
import { toggleShowSideBar } from '../../../state/menus/actions';


const Header = ({ children }) => {
  const sidebar = useSelector(sidebarSelector);
  const animation = useSelector(sidebarAnimationSelector);

  const dispatch = useDispatch();

  return (
    <header
      className={classNames('fixed w-full top-0 right-0 z-30', {
        'w-[calc(100%_-_238px)]': sidebar,
        'ease-in-out duration-300 translate-x-0': animation,
      })}
    >
      <nav
        className={classNames('relative px-[30px] py-[18px] bg-white dark:bg-black-1 max-md:flex max-md:justify-between', {
          // 'border-b border-grey-3 dark:border-black-3': hasKeys,
        })}
      >
          <img
        src={burger}
        className='cursor-pointer md:hidden w-12 z-50 mr-2'
        alt='AffBar'
        onClick={() => {
          dispatch(toggleShowSideBar());
        }}
      />
        <div
          className={classNames('flex flex-wrap justify-between items-center max-md:ml-0 max-md:w-full', {
            'ml-[238px]': sidebar,
            'ml-[124px]': !sidebar,
            // 'ml-4': !hasKeys,
          })}
        >
          {children}
        </div>
      </nav>
    </header>
  );
};

export default Header;
