import { createAsyncThunk } from '@reduxjs/toolkit';
import { createPixel, fetchPixelByID, fetchPixels, modifyPixel, removePixel } from '../../api/fb/pixels';

export const getPixels = createAsyncThunk('pixels/get-pixels', (_, thunkAPI) => fetchPixels(thunkAPI));

export const getPixel = createAsyncThunk('pixels/get-pixel', (id, thunkAPI) => fetchPixelByID(id, thunkAPI));

export const updatePixel = createAsyncThunk('pixels/update-pixel', (data, thunkAPI) => modifyPixel(data, thunkAPI));

export const deletePixel = createAsyncThunk('pixels/delete-pixel', (_, thunkAPI) => removePixel(thunkAPI));

export const addPixel = createAsyncThunk(' pixels/add-pixel', (data, thunkAPI) =>
  createPixel(data, thunkAPI)
);