import React from 'react';
import Breadcrumbs from '../../../ui/breadcrumbs';
import appsSelector from '../../../../state/push/selectors/apps/apps';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppForm from './form-section/App-form';
import { getApps } from '../../../../state/push/async-actions';
import pushLoadingSelector from '../../../../state/push/selectors/loading';
import Loading from '../../../global/Loading';

const App = () => {
  const apps = useSelector(appsSelector);
  const loading = useSelector(pushLoadingSelector);
  const params = useParams();
  const dispatch = useDispatch();
  const app = apps?.filter(({ id }) => id === params.id)[0];

  React.useEffect(() => {
    dispatch(getApps());
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='w-full h-full pl-[30px]'>
      <Breadcrumbs linkTo={'/push/apps'} firstLevel={'applications'} secondLevel={!app?.name ? 'unknown' : `${app.name}`} />
      <div className='overflow-auto h-[calc(100%_-_96px)] flex'>
        {app &&<AppForm app={app} />}
      </div>
    </div>
  );
};

export default App;
