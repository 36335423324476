import React from 'react';
import TertiaryButton from '../tertiary';
import { DeleteIcon } from '../../../../assets/icons';

const DeleteButton = ({ isDeleteDisabled, handleDelete }) => {
  return (
    <TertiaryButton disabled={isDeleteDisabled} handleClick={handleDelete} type={'button'}>
      <DeleteIcon className='fill-black-1 dark:fill-white group-disabled/btn:fill-grey-1 dark:group-disabled/btn:fill-black-3' />
    </TertiaryButton>
  );
};

export default DeleteButton;
