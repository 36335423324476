import Joi from 'joi';

export const appSchema = Joi.object({
  name: Joi.string().min(2).max(100).trim().messages({
    'string.empty': 'The "Name" field is required',
    'string.base': 'The "Name" field should contain only letters and digits',
    'string.min': 'The "Name" field should have at least {#limit} characters',
    'string.max': 'The "Name" field should have at most {#limit} characters',
  }),
  status: Joi.string().valid('active', 'not-active').required().messages({
    'string.base': 'The "Status" field should be a string',
    'any.only': 'The "Status" field should be either "active" or "not-active"',
    'string.required': `This field is required`,
  }),
  message_category: Joi.array().required().messages({
    'array.base': 'The "Message category" field should be an array',
    'array.required': `This field is required`,
  }),
  include_segment: Joi.array().required().messages({
    'array.base': 'The "Include segment" field should be a array',
    'array.required': `This field is required`,
  }),
  frequency: Joi.number().min(0.1).max(100).required().messages({
    'number.base': 'The "Frequency" field should be a number',
    'number.integer': 'The "Frequency" field should be an integer',
    'number.min': 'The "Frequency" field should be at least {#limit}',
    'number.max': 'The "Frequency" field should be at most {#limit}',
    'string.required': `This field is required`,
  }),
  api_key: Joi.string().allow('').required().min(4).max(100).trim().messages({
    'string.min': 'The "Api key" field should have at least {#limit} letters',
    'string.max': 'The "Api key" field should have at most {#limit} letters',
    'string.required': `This field is required`,
  }),
  link: Joi.string().min(3).max(500).optional().allow('').trim().messages({
    'string.pattern.base': 'Please enter a valid URL (e.g., https://example.com)',
    'string.min': 'The "Link" field should have at least {#limit} characters',
    'string.max': 'The "Link" field should have at most {#limit} characters',
  }),
});
