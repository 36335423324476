import { clientsSlice } from './slice';

export const {
  setEditableClient,
  setSelectedBlock,
  setSelectedSourceWithPriority,
  setSelectedLimit,
  setBlockToDelete,
  setAdvertisers,
} = clientsSlice.actions;
