import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import trackerReportSelector from '../../../state/tracker-report/selectors/report';

const Footer = () => {
  const data = useSelector(trackerReportSelector)
  const sumTotalValues = {};
  var percent = ['lp_ctr', 'cr', 'form_submit', 'sub2lpclick', 'sub2lead'];

  // Iterate over the report and accumulate the values
  data.forEach((item) => {
    const total = item.Total;
    for (const key in total) {
      if (total.hasOwnProperty(key) && typeof total[key] === 'number') {
        if (percent.includes(key)) {
          sumTotalValues[key] = (sumTotalValues[key] || 0) + total[key] / data.length;
        } else {
          sumTotalValues[key] = (sumTotalValues[key] || 0) + total[key];
        }
      }
    }
  });
  var footerData = ['Total', ...Object.values(sumTotalValues)];
  return (
    <tfoot className='dark:bg-main-black-2 bg-additional-blue sticky bottom-[-100px]'>
      <tr className='font-semibold text-gray-900 dark:text-main-white'>
        {footerData.map((col, index) => (
          <td
            key={index}
            className={classNames(
              'py-[22px] first:pl-[38px] first:text-left px-2 whitespace-nowrap first:font-onest-medium first:sticky first:left-0 dark:bg-dark-table-header bg-light-table-header border-r-[1.5px] border-main-grey-3 dark:border-[#131313]'
            )}
          >
            {`${index === 6 || index === 9 || index === 10 || index === 13 ? '$' : ''} ${typeof(col) === 'number' ? customRound(col) : col} ${index === 3 || index === 5 || index === 12 || index === 14 || index === 15 || index === 16 ? '%' : ''}`}
          </td>
        ))}
      </tr>
    </tfoot>
  );
};

function customRound(number) {
  const stringNumber = number.toString();
  const decimalIndex = stringNumber.indexOf('.');
  
  if (decimalIndex !== -1 && stringNumber.length - decimalIndex > 4) {
    return parseFloat(number.toFixed(3));
  }
  
  return number;
}

export default Footer;
