import { createSlice } from '@reduxjs/toolkit';
import { setRejectsReportData } from './async-actions';
import { TODAY_DATE } from '../../constants/date';
import { formatDate } from '../../helpers/time';

const initialState = {
  report: {},
  filter: {
    selectedDate: {
      startDate: TODAY_DATE,
      endDate: TODAY_DATE,
    },
    advertisers: [],
    sources: [],
    countries: [],
    selectedAdvertisers: [],
    selectedSource: [],
    selectedCountry: [],
  },
  page: {
    cursor: null,                                              
    nextCursor: null,
    prevCursor: [],
    total: null,
    pageSize: localStorage.getItem('rejectsPageLimit') || '10',
    currentPage: 1,
  },
  loading: false,
  error: '',
};

export const rejectsSlice = createSlice({
  name: 'rejects-report',
  initialState,
  reducers: {
    setDateRange: (state, { payload }) => {
      state.filter.selectedDate.startDate = payload.startDate ? formatDate(payload.startDate) : TODAY_DATE;
      state.filter.selectedDate.endDate = payload.endDate ? formatDate(payload.endDate) : TODAY_DATE;
    },
    setCurrentPage: (state, { payload }) => {
      state.page.currentPage = payload;
    },
    setNextCursor: (state, { payload }) => {
      state.page.nextCursor = payload;
    },
    setCursor: (state, { payload }) => {
      state.page.cursor = payload;
    },
    setPrevCursor: (state, { payload }) => {
      if (payload.action === 'next') {
        state.page.prevCursor.push(payload.value);
      } else {
        state.page.prevCursor.pop();
      }
    },
    selectAdvertiser: (state, { payload }) => {
      if (state.filter.selectedAdvertisers.includes(payload)) {
        state.filter.selectedAdvertisers = state.filter.selectedAdvertisers.filter((id) => id !== payload);
      } else {
        state.filter.selectedAdvertisers.push(payload);
      }
    },
    selectAllAdvertisers: (state, { payload }) => {
      state.filter.selectedAdvertisers = payload ? payload.map(({ id }) => id) : [];
    },
    selectSource: (state, { payload }) => {
      if (state.filter.selectedSource.includes(payload)) {
        state.filter.selectedSource = state.filter.selectedSource.filter((source) => source !== payload);
      } else {
        state.filter.selectedSource.push(payload);
      }
    },
    selectAllSources: (state, { payload }) => {
      state.filter.selectedSource = payload ? payload : [];
    },
    selectCountry: (state, { payload }) => {
      if (state.filter.selectedCountry.includes(payload)) {
        state.filter.selectedCountry = state.filter.selectedCountry.filter((country) => country !== payload);
      } else {
        state.filter.selectedCountry.push(payload);
      }
    },
    selectAllCountries: (state, { payload }) => {
      state.filter.selectedCountry = payload ? payload : [];
    },
    setPageLimit: (state, { payload }) => {
      state.page.pageSize = payload;
      localStorage.setItem('rejectsPageLimit', payload);
      state.page.currentPage = 1;
      state.page.cursor = null;
      state.page.prevCursor = [];
    },
    resetPagination: (state) =>  {
      state.page.currentPage = 1;
      state.page.cursor = null;
      state.page.prevCursor = [];
    },
    resetFilters: (state) => {
      state.filter.selectedAdvertisers = [];
      state.filter.selectedSource = [];
      state.filter.selectedCountry = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setRejectsReportData.fulfilled, (state, { payload }) => {
        state.report = payload.report ? payload.report : [];

        state.filter.advertisers = payload.filters.advertisers ? payload.filters.advertisers : [];
        state.filter.sources = payload.filters.source ? payload.filters.source : [];
        state.filter.countries = payload.filters.country ? payload.filters.country.filter((item) => item !== '') : [];
        state.page.nextCursor = payload.next_cursor;
        if (state.page.currentPage === 1) {
          state.page.total = payload.total;
        }
        // state.page.prevCursor[0] = payload.report ? payload.report[0].id + 1 : [];
        state.loading = false;
      })
      .addCase(setRejectsReportData.pending, (state) => {
        if (state.page.cursor === null) {
          state.loading = true;
        }
        state.error = null;
      })
      .addCase(setRejectsReportData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const rejectsReportReducer = rejectsSlice.reducer;
export default rejectsReportReducer;
