import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page401 from '../error-pages/Page401';
import Page403 from '../error-pages/Page403';
import Loading from '../global/Loading';
import FiltersRow from './FiltersRow';
import classNames from 'classnames';
import sidebarMenuSelector from '../../state/menus/selectors/sidebar';
import filtersSelector from '../../state/rejects-report/selectors/filter/filters';
import errorSelector from '../../state/rejects-report/selectors/error';
import loadingSelector from '../../state/rejects-report/selectors/loading';
import { setRejectsReportData } from '../../state/rejects-report/async-actions';
import pageSelector from '../../state/rejects-report/selectors/page/page';
import Table from './table/Table';
import reportSelector from '../../state/rejects-report/selectors/report';

const Rejects = () => {
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);
  const loading = useSelector(loadingSelector);
  const sidebar = useSelector(sidebarMenuSelector);
  const report = useSelector(reportSelector);
  const { cursor, nextCursor, pageSize } = useSelector(pageSelector);
  const { selectedDate, selectedAdvertisers, selectedSource, selectedCountry } = useSelector(filtersSelector);

  useEffect(() => {
    dispatch(setRejectsReportData({ selectedDate, selectedAdvertisers, selectedSource, selectedCountry, pageSize, cursor }));
  }, [dispatch, selectedDate, pageSize, cursor]);

  if (error?.status === 401) {
    return <Page401 service='dashboard' error={error} />;
  }
  if (error?.status === 403) {
    return <Page403 />;
  }
  if (error) {
    return <h2 className='text-black-1 dark:text-white text-3xl p-10 text-center'>{error.message}</h2>;
  }
   if (loading) return <Loading />;

  return (
    <section
      className={classNames('flex flex-col px-[30px] max-md:px-[10px] pt-8 gap-8 pb-[100px] overflow-x-auto', {
        'min-w-[calc(100vw-128px-60px)]': !sidebar,
        'min-w-[calc(100vw-238px-60px)]': sidebar,
      })}
    >
      <FiltersRow />
      {Object.keys(report).length === 0 ? (
        <h2 className='text-black-1 dark:text-white text-3xl pt-10 text-center'>Not found</h2>
      ) : (
        <Table report={report}/>
      )}
    </section>
  );
};

export default Rejects;
