import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ROUTES from '../constants/routes';
import SignInForm from '../components/forms/SignInForm';
import GuestHeader from '../components/global/header/Guest';

const GuestRouter = () => {
  return (
    <BrowserRouter>
      <GuestHeader />
      <Routes>
        <Route path={ROUTES.SIGN_IN.route} element={<SignInForm />} />
        <Route path={'/*'} element={<Navigate to={ROUTES.SIGN_IN.route} replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default GuestRouter;
