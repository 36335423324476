import { joiResolver } from '@hookform/resolvers/joi';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { messageSchema } from '../../../../schemas/messageSchema';
import { addMessage, getMessage, getMessages, updateMessage } from '../../../../state/push/async-actions';
import {
  toggleCreateMessageModal,
  toggleMessageImageModal,
  toggleUpdateMessageConfModal,
} from '../../../../state/modals/actions';
import updateAppConfModalSelector from '../../../../state/modals/selectors/update-app';
import InputField from '../../../ui/input';
import ActionButtonGroup from '../../../ui/button-group/ActionButtonGroup';
import UpdateModal from '../../../modals/UpdateModal';
import TextArea from '../../../ui/textarea';
import CategorySel from './category-sel';
import Lanquage from './language';
import BlockButton from '../../../ui/buttons/block-btn';
import MessageImageModal from '../../../modals/MessageImageModal';
import messageImageModalSelector from '../../../../state/modals/selectors/message-image';
import { setImageType } from '../../../../state/push/actions';
import LangSel from './langs-sel';
import filterSelector from '../../../../state/push/selectors/messages/filters';
import pageInfoSelector from '../../../../state/push/selectors/messages/pageInfo';
import StatusSel from '../../../crm/clients/client/form-sections/status-sel';

const MessageForm = ({ message, action }) => {
  var areaFields = [
    {
      inputId: 'heading',
      isRequired: true,
      label: 'Heading',
      placeholder: message.heading,
      isReadOnly: false,
    },
    {
      inputId: 'content',
      isRequired: true,
      label: 'Content',
      placeholder: message.content,
      isReadOnly: false,
    },
  ];

  const fileFields = [
    {
      id: 'icon',
      isRequired: true,
      label: 'Icon',
      path: message.icon,
      isReadOnly: false,
    },
    {
      id: 'image',
      isRequired: true,
      label: 'Image',
      path: message.image,
      isReadOnly: false,
    },
  ];

  // const clientWithoutId = _.omit(client, 'id');
  const updateConfirmation = useSelector(updateAppConfModalSelector);
  const messageImage = useSelector(messageImageModalSelector);
  const {
    selectedCategories,
    selectedID,
  } = useSelector(filterSelector);
  const { currentPage, pageSize } = useSelector(pageInfoSelector);

  const dispatch = useDispatch();

  const [isDataChanged, setIsDataChanged] = useState(false);
  const methods = useForm({ mode: 'all', resolver: joiResolver(messageSchema), values: withArrayObject(message) });
  const {
    handleSubmit,
    getValues,
    control,
    reset,
    // eslint-disable-next-line no-unused-vars
    formState: { isValid, errors },
  } = methods;
  const values = getValues();

  const omitEmptyFields = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (obj[key] !== '') {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  useEffect(() => {
    if (_.isEqual(omitEmptyFields(values), omitEmptyFields(withArrayObject(message)))) {
      setIsDataChanged(false);
    } else {
      setIsDataChanged(true);
    }
  }, [message, values]);

  const submit = (data) => {
    if (action === 'create') {
      dispatch(addMessage(data))
        .unwrap()
        .then(() => {
          dispatch(toggleCreateMessageModal());
        })
        .then(() => {
          dispatch(getMessages({selectedCategories,
            selectedID,
            currentPage,
            pageSize}));
        });
    } else {
      const changedValues = Object.entries(omitEmptyFields(data))
        .filter(([key, value]) => !_.isEqual(value, message[key]))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      if (Object.keys(changedValues).length === 0) {
        // No changes, do not submit
        console.log('No changes to submit');
        return;
      }
      console.log('changedValues', changedValues);
      dispatch(updateMessage(changedValues))
        .unwrap()
        .then(() => {
          dispatch(getMessage(message.id));
        })
        .then(() => {
          // dispatch(setEditableClient(false));
          dispatch(getMessages({selectedCategories,
            selectedID,
            currentPage,
            pageSize}));
        });
    }
  };
  const handleOpenConfModal = () => {
    dispatch(toggleUpdateMessageConfModal());
  };

  const handleClickUpdateImage = (type) => {
    dispatch(toggleMessageImageModal());
    dispatch(setImageType(type));
  };

  return (
    <div className='flex flex-col'>
      <FormProvider {...methods}>
        <form className='flex flex-col gap-8 w-full relative' onSubmit={handleSubmit(submit)}>
          {action === 'update' && <h3 className='text-2xl'>Message info:</h3>}
          <section id='client_info' className='flex flex-wrap gap-4 mr-[30px] mb-4'>
            {action === 'update' && (
              <InputField
                inputId='id'
                label='ID'
                placeholder={message.id}
                type='text'
                isReadOnly={true}
                styles='w-[70px]'
              />
            )}
            <CategorySel />
            <StatusSel />
            {action === 'create' && <LangSel control={control} action='create' />}
            {areaFields.map((item, idx) => (
              <div key={idx}>
                <TextArea
                  label={item.label}
                  textareaId={item.inputId}
                  placeholder={item.placeholder}
                  isRequired={item.isRequired}
                  styles='w-[300px]'
                />
              </div>
            ))}
            <div className='flex gap-4 flex-wrap'>
              {action === 'update' && <Lanquage langs={message.langs} />}
              {fileFields.map((field, id) => (
                <div key={id} className='flex gap-2 flex-col'>
                  <InputField
                    inputId={field.id}
                    label={field.label}
                    placeholder={values[field.id]}
                    type='text'
                    isReadOnly={field.isReadOnly}
                    styles={'w-[300px]'}
                  />
                  {values[field.id] !== '' && (
                    <img alt='resource path' src={values[field.id]} className='max-w-[300px] max-h-[200px]' />
                  )}
                  <BlockButton disabled={false} handleClick={() => handleClickUpdateImage(field.id)}>
                    {action === 'update' ? 'Update' : 'Add'}
                  </BlockButton>
                </div>
              ))}
            </div>
            {messageImage && <MessageImageModal />}
          </section>
        </form>
      </FormProvider>
      {action === 'update' && (
        <div className='flex gap-6 my-6'>
          <span>
            Created time: <b>{moment.utc(message.created_at).format('DD.MM.YYYY')}</b>
          </span>
          <span>
            Updated time: <b>{moment.utc(message.updated_at).format('DD.MM.YYYY')}</b>
          </span>
        </div>
      )}
      <ActionButtonGroup
        link={{
          handleClick: action === 'update' ? reset : () => dispatch(toggleCreateMessageModal()),
          isDisabled: action === 'update' ? !isDataChanged : false,
        }}
        button={{
          isDisabled: !isDataChanged,
          label: action === 'update' ? 'Update' : 'Create',
          handleClick: () => submit(values),
        }}
        styles={
          action === 'update'
            ? 'justify-end sticky bottom-0 z-50 dark:bg-black-1 bg-grey-6 max-md:justify-start mr-5'
            : 'justify-end'
        }
      />
      <UpdateModal
        title={'Update Message'}
        description={"Are you sure you want to update this message? If you update it, you can't get it back."}
        onUpdate={() => dispatch(toggleUpdateMessageConfModal()) && submit(values)}
        onClose={handleOpenConfModal}
        deleteConfirmation={updateConfirmation}
      />
    </div>
  );
};

export default MessageForm;

function withArrayObject(message) {
  const updatedMsg = { ...message };
  updatedMsg.category = updatedMsg.category?.map((id, value) => ({ id: value, name: id })) || [];
  return updatedMsg;
}
