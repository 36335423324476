import React from 'react';
import TableWrapper from '../../ui/table/TableWrapper';
import ClientsTableBody from './TableBody';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import clientsErrorSelector from '../../../state/client-management/selectors/error';
import clientsLoadingSelector from '../../../state/client-management/selectors/loading';
import clientsSelector from '../../../state/client-management/selectors/clients/clients';
import {CLIENTS_TABLE_HEADERS} from '../../../constants/clients';
import Loading from '../../global/Loading';
import Page401 from '../../error-pages/Page401';
import Page403 from '../../error-pages/Page403';
import { getClients } from '../../../state/client-management/async-actions';
import TableClientsHeader from '../../ui/table/ClientsHeader';

const Clients = () => {
  const error = useSelector(clientsErrorSelector);
  const loading = useSelector(clientsLoadingSelector);
  const clients = useSelector(clientsSelector);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getClients())
  }, [dispatch])
  

  if (error?.status === 401) {
    return <Page401 service='crm' error={error} />;
  }
  if (error?.status === 403) {
    return <Page403 />;
  }
  if (error) {
    return <h2 className='text-black-1 dark:text-white text-3xl pt-10 text-center'>{error.message || error}</h2>;
  }
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div
        className={classNames('overflow-auto h-[calc(100%_-_96px)]', {
          'pt-52': !clients.length,
        })}
      >
        {clients &&  (
          <TableWrapper>
            <TableClientsHeader headers={CLIENTS_TABLE_HEADERS} topStyle={0} />
            <ClientsTableBody />
          </TableWrapper>
        )}
      </div>
    </>
  );
};

export default Clients;
