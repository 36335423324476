import React from 'react';
//////variant icon and general

const TextButton = ({ type, children, disabled, handleClick }) => {
  return (
    <button
      type={type}
      onClick={handleClick}
      disabled={disabled}
      className='px-3 py-[10px] h-10 min-w-[120px] rounded-md text-sm border-grey-2 border-2 font-onest-regular text-grey-1 hover:text-red active:text-red-burgundy focus:outline-none disabled:bg-grey-3 dark:disabled:bg-grey-1 disabled:text-grey-1 dark:disabled:text-black-3'
    >
      {children}
    </button>
  );
};

export default TextButton;
