import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import ROUTES from '../../../constants/routes';
import MenuItem from './MenuItem';
import { toggleUserMenu } from '../../../state/menus/actions';
import { GuestPicture } from '../../../assets/icons/header/header-icons';
import userMenuSelector from '../../../state/menus/selectors/user-menu';
import { meSelector } from '../../../state/user/selectors/self';
import { getCurrentUser } from '../../../firebase/auth';

const { SIGN_IN } = ROUTES;

const UserMenu = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const dropdown = useSelector(userMenuSelector);
  const { email, user_avatar } = useSelector(meSelector);
  const userPhoto = getCurrentUser().photoURL;

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropdown && ref.current && !ref.current.contains(e.target)) {
        dispatch(toggleUserMenu());
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [dropdown, dispatch]);

  return (
    <div className='flex items-center md:order-2' ref={ref}>
      <button
        type='button'
        className='flex justify-center items-center bg-grey-3 rounded-full w-10 h-10 focus:ring-4 focus:ring-grey-3 dark:focus:ring-black-3'
        id='user-menu-button'
        aria-expanded='false'
        data-dropdown-toggle='user-dropdown'
        data-dropdown-placement='bottom'
        onClick={() => dispatch(toggleUserMenu())}
      >
        <span className='sr-only'>Open user menu</span>
        <div className='relative z-50 w-10 h-10'>
          <img
            className={classNames('absolute w-full h-full object-contain top-0 left-0 rounded-full', {
              'scale-100 bg-black-1 dark:bg-white border': user_avatar,
            })}
            src={user_avatar || userPhoto || GuestPicture}
            alt='User avatar'
            referrerPolicy='no-referrer'
          />
        </div>
      </button>
      <div
        className={classNames(
          'w-[216px] top-[77px] z-50 right-[30px] py-2 font-onest-regular text-grey-2 text-sm rounded-br-xl rounded-bl-xl shadow bg-grey-5 dark:bg-black-2',
          {
            hidden: !dropdown,
            absolute: dropdown,
          }
        )}
        id='user-dropdown'
        data-popper-reference-hidden=''
        data-popper-escaped=''
        data-popper-placement='bottom'
      >
        <div className='px-4 py-2'>
          <span className='block text-sm truncate'>{email}</span>
        </div>
        <ul className='py-2 flex flex-col gap-2' aria-labelledby='user-menu-button'>
          <MenuItem item={SIGN_IN} />
        </ul>
      </div>
    </div>
  );
};

export default UserMenu;
