import React from 'react'
import PaginationButtons from './PaginationButtons'
import LimitSelect from './LimitSelect'

const Pagination = ({size, totalPages, currentPage, limits, setPageFunc, setLimitFunc}) => {
  return (
  <div className='flex justify-center items-center gap-3  mt-8 mb-4'>
      <LimitSelect pageSize={size} limits={limits} setLimitFunc={setLimitFunc} />
      <PaginationButtons totalPages = {totalPages} currentPage={currentPage} setPageFunc={setPageFunc} />
  </div>
  )
}

export default Pagination