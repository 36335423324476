import { getToken } from "../../helpers/token";
import axios from '../../config/backoffice';
import showToast from "../../components/global/Toast";

export const fetchPixels = async ({rejectWithValue}) => {
  const token = getToken();

  try {
    const pixels = await axios.get('fbpixel/pixels', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return pixels.data;
  } catch (error) {
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const fetchPixelByID = async (id, { rejectWithValue }) => {
  const token = getToken();

  try {
    const pixel = await axios.get(`fbpixel/pixel/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return pixel.data;
  } catch (error) {
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const modifyPixel = async (pixelInfo, { rejectWithValue, getState }) => {
  const token = getToken();

  try {
    const { data } = await axios.put(
      `fbpixel/pixel/${pixelInfo.id}`,
      { ...pixelInfo },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showToast({
      type: 'success',
      title: 'Updated successfully',
      description: 'Pixel was updated!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.message || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const removePixel = async ({ rejectWithValue, getState }) => {
  const token = getToken();
  const { toDelete } = getState().fb.pixels;

  try {
    const { data } = await axios.delete(`fbpixel/pixel/${toDelete}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    showToast({
      type: 'success',
      title: 'Deleted successfully',
      description: 'pixel was deleted!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.error || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const message = error.response.data.error;
      const status = error.response.data.status;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};

export const createPixel = async (pixelInfo, { rejectWithValue, getState }) => {
  const token = getToken();
 
  try {
    const { data } = await axios.post(
      `fbpixel/pixel`,
      { ...pixelInfo },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showToast({
      type: 'success',
      title: 'Created successfully',
      description: 'Pixel was created!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.message || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};