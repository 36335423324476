import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCreatePixelModal } from '../../state/modals/actions';
import PixelForm from '../fb/pixels/pixel/Form';
import ModalsWrapper from '../wrappers/ModalsWrapper';
import createPixelModalSelector from '../../state/modals/selectors/create-pixel';

const CreatePixelModal = () => {
  const dispatch = useDispatch();
  const createMessage = useSelector(createPixelModalSelector);
  let emptyPixel = {
    id: "",
    pixel_id: "",
    event_source_url: "",
    access_token: "",
  }

  return (
    <ModalsWrapper
      title={'Create pixel'}
      modal={createMessage}
      close={() => dispatch(toggleCreatePixelModal())}
    >
      <PixelForm pixel={emptyPixel} action="create"/>
    </ModalsWrapper>
  );
};

export default CreatePixelModal;
