import React, { Fragment } from 'react';
import pageInfoSelector from '../../../state/analytics/selectors/table/pagiInfo';
import { useDispatch, useSelector } from 'react-redux';
import { Listbox, Transition } from '@headlessui/react';
import { ExpandArrowIcon } from '../../../assets/icons';
import classNames from 'classnames';

const LimitSelect = ({pageSize, limits, setLimitFunc}) => {
  const dispatch = useDispatch();

  const onChangeLimit = (element) => {
    dispatch(setLimitFunc(element));
  };
  return (
    <Listbox value={pageSize} onChange={onChangeLimit} disabled={false}>
      {({ open, disabled, value }) => (
        <div
          className={classNames(
            'h-10 w-[100px] relative font-onest-regular text-sm flex items-center pl-3 pr-2 rounded-md border border-grey-2 dark:border-black-3 hover:border-black-3 group-hover:border-black-3 dark:hover:border-grey-4 dark:group-hover:border-grey-4 dark:disabled:border-none disabled:cursor-not-allowed'
          )}
        >
          <Listbox.Button className='group relative w-full cursor-pointer text-light-grey py-2 pr-10 text-left disabled:cursor-default'>
            <span className='block truncate'>{pageSize}</span>
            <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center'>
              <ExpandArrowIcon
                className={classNames('fill-grey-1', { 'dark:fill-white rotate-180': open, invisible: disabled })}
              />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='top-full absolute right-0 mt-1 max-h-[200px] w-[100px] z-50 overflow-auto rounded-lg text-black-3 dark:text-white bg-grey-4 dark:bg-black-2 py-2 text-sm shadow-md dark:shadow-black-3'>
              {limits.map((option, optionIdx) => (
                <Listbox.Option
                  key={option?.id || optionIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-3 ${active && 'bg-white dark:bg-black-3'}`
                  }
                  value={option}
                >
                  {({ selected }) => <span className={`block truncate ${selected && 'font-bold'}`}>{option}</span>}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default LimitSelect;
