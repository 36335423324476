import { menusSlice } from './slice';

export const {
  toggleUserMenu,
  toggleShowSideBar,
  toggleSidebarMenu,
  resetSidebarAnimation,
  selectMenuItem,
  changeTheme,
  setTheme,
  resetMenus,
  openSidebarMenu,
  selectSubmenuMenuItem,
  togleSidebarSubMenu,
} = menusSlice.actions;
