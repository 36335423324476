import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { THEMES } from '../../constants/themes';
import { setTheme } from '../../state/menus/actions';
import themeSelector from '../../state/menus/selectors/theme';

const ThemeWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const theme = useSelector(themeSelector);

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      const colorScheme = event.matches ? THEMES.dark : THEMES.light;
      dispatch(setTheme(colorScheme));
    });
  }, [dispatch]);

  return <div className={`${theme} bg-black-1 w-full h-full`}>{children}</div>;
};

export default ThemeWrapper;
