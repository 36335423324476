import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createApp,
  createLangs,
  createMessage,
  fetchAppByID,
  fetchApps,
  fetchMessageByID,
  fetchMessages,
  modifyApp,
  modifyMessage,
  removeApp,
  removeLang,
  removeMessage,
} from '../../api/push/push';
import { fetchImages } from '../../api/push/bunnyCDN';

//read
export const getApps = createAsyncThunk('push/get-apps', (_, thunkAPI) => fetchApps(thunkAPI));

export const getApp = createAsyncThunk('push/get-app', (id, thunkAPI) => fetchAppByID(id, thunkAPI));

export const deleteApp = createAsyncThunk('push/delete-app', (_, thunkAPI) => removeApp(thunkAPI));

export const updateApp = createAsyncThunk(' push/update-app', (data, thunkAPI) => modifyApp(data, thunkAPI));

export const addApp = createAsyncThunk('push/create-app', (data, thunkAPI) => createApp(data, thunkAPI));

export const getMessages = createAsyncThunk(
  'push/get-messages',
  (selectedID, selectedCategory, pageSize, currentPage, thunkAPI) =>
    fetchMessages(selectedID, selectedCategory, pageSize, currentPage, thunkAPI)
);

export const getMessage = createAsyncThunk('push/get-message', (id, thunkAPI) => fetchMessageByID(id, thunkAPI));

export const deleteMessage = createAsyncThunk('push/delete-message', (_, thunkAPI) => removeMessage(thunkAPI));

export const deleteLang = createAsyncThunk('push/delete-lang', (_, thunkAPI) => removeLang(thunkAPI));

export const updateMessage = createAsyncThunk(' push/update-message', (data, thunkAPI) =>
  modifyMessage(data, thunkAPI)
);

export const addMessage = createAsyncThunk(' push/add-message', (data, thunkAPI) =>
  createMessage(data, thunkAPI)
);

export const addLangs = createAsyncThunk(' push/add-langs', (data, thunkAPI) => createLangs(data, thunkAPI));

export const getImages = createAsyncThunk('push/get-images', (type, thunkAPI) => fetchImages(type, thunkAPI));
