import React from 'react';
import Checkbox from '../checkbox';
import classNames from 'classnames';

const SourcesDropdownList = ({ isOpen, options, selectOption, selectedVal, label, query, checkboxes, isSelectAll }) => {
  const filter = (options) => {
    return query ? options.filter((option) => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1) : options;
  };

  const parentItems = options.filter((item) => {
    return (
      !item.name.includes('_') &&
      !item.name.includes('-') &&
      options.some(
        (child) => child.name.startsWith(item.name) && (child.name.includes('_') || child.name.includes('-'))
      )
    );
  });

  return (
    <div
      className={`absolute z-[60] left-0 max-h-64 flex flex-col text-sm font-onest-regular top-full mt-1 w-full rounded-md py-1 bg-grey-4 dark:bg-black-3 overflow-y-auto shadow-lg dark:shadow-black-3/20 ${
        isOpen ? 'block' : 'hidden'
      }`}
    >
      {isSelectAll && (
        <div className='flex gap-3 items-center justify-center'>
          <Checkbox
            id='id'
            isChecked={selectedVal.length === options.length}
            onCheck={() => {
              selectOption(options);
            }}
          />
          <p>Select All</p>
        </div>
      )}
      {filter(options).map((option, idx) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              selectOption(option);
            }}
            className={classNames(
              `flex items-center cursor-pointer text-black-3 dark:text-white p-3 hover:bg-white dark:hover:bg-black-3`,
              {
                'font-bold': option[label] === selectedVal,
                '!text-red font-bold': parentItems.includes(option),
              }
            )}
            key={option[label]}
          >
            {checkboxes && (
              <div className='p-1 mr-2' key={option.id}>
                <Checkbox
                  id={option.id}
                  isChecked={option === selectedVal || !!selectedVal.find((value) => value[label] === option[label])}
                  onCheck={() => {
                    selectOption(option);
                  }}
                />
              </div>
            )}
            {option[label]}
          </div>
        );
      })}
    </div>
  );
};

export default SourcesDropdownList;
