import React, { useEffect, useState } from 'react';
import { setReportData } from '../../../state/analytics/async-actions';
import filtersSelector from '../../../state/analytics/selectors/filters/filters';
import { useDispatch, useSelector } from 'react-redux';
import errorSelector from '../../../state/analytics/selectors/error';
import Page401 from '../../error-pages/Page401';
import Page403 from '../../error-pages/Page403';
import loadingSelector from '../../../state/analytics/selectors/loading';
import Loading from '../../global/Loading';
import classNames from 'classnames';
import sidebarMenuSelector from '../../../state/menus/selectors/sidebar';
import FiltersRow from '../filters/FiltersRow';
import Summary from '../summary/Summary';
import ReportBlocksWrapper from '../../wrappers/ReportBlocksWrapper';
import Table from '../table/Table';
import pageInfoSelector from '../../../state/analytics/selectors/table/pagiInfo';
import chartSelector from '../../../state/analytics/selectors/chart/chart';
import { getPieGrapthData } from '../../../helpers/reportTable';
import SummaryPieChart from '../chart/SummaryPieChart';

const LandingReport = ({ type }) => {
  const dispatch = useDispatch();
  const {
    selectedDate,
    selectedAdvertisers,
    selectedSource,
    selectedCountry,
    selectedLandingID,
    selectedUserEmail,
    sort,
  } = useSelector(filtersSelector);
  const { pageSize, currentPage } = useSelector(pageInfoSelector);
  const error = useSelector(errorSelector);
  const loading = useSelector(loadingSelector);
  const sidebar = useSelector(sidebarMenuSelector);
  const rows = useSelector(chartSelector);
  const [isShowSummary, setIsShowSummary] = useState(true);
  const [isShowGraph, setIsShowGraph] = useState(true);
  const [isShowTable, setIsShowTable] = useState(true);
  var graphInfo = getPieGrapthData(rows);

  const handleIsShowSummaryChange = () => {
    setIsShowSummary(!isShowSummary);
  };
  const handleIsShowGraphChange = () => {
    setIsShowGraph(!isShowGraph);
  };
  const handleIsShowTableChange = () => {
    setIsShowTable(!isShowTable);
  };

  useEffect(() => {
    dispatch(
      setReportData({
        type,
        selectedDate,
        selectedAdvertisers,
        selectedSource,
        selectedCountry,
        selectedLandingID,
        selectedUserEmail,
        sort,
        pageSize,
        currentPage,
      })
    );
  }, [dispatch, selectedDate, sort, pageSize, currentPage]);

  if (error?.status === 401) {
    return <Page401 service='dashboard' error={error} />;
  }
  if (error?.status === 403) {
    return <Page403 />;
  }
  if (error) {
    return <h2 className='text-black-1 dark:text-white text-3xl pt-10 text-center'>{error.message}</h2>;
  }
  if (loading) return <Loading />;
  return (
    <section
      className={classNames('flex flex-col px-[30px] pt-8 gap-8 min-w-max pb-[100px]', {
        'min-w-[calc(100vw-128px-60px)]': !sidebar,
        'min-w-[calc(100vw-238px-60px)]': sidebar,
      })}
    >
      <FiltersRow type={type} />
      <ReportBlocksWrapper title='Summary' handleShow={handleIsShowSummaryChange} isShow={isShowSummary}>
        <Summary isShow={isShowSummary} />
      </ReportBlocksWrapper>
      <ReportBlocksWrapper title='Performance graph' handleShow={handleIsShowGraphChange} isShow={isShowGraph}>
        {graphInfo[0].series.length > 0 && (
          <div className='flex justify-between'>
            {graphInfo.map((grapType, idx) => (
              <SummaryPieChart
                key={idx}
                type='pie'
                isShow={isShowGraph}
                options={grapType.options}
                series={grapType.series}
                title={grapType.title}
              />
            ))}
          </div>
        )}
      </ReportBlocksWrapper>
      <ReportBlocksWrapper title='Detailed report' handleShow={handleIsShowTableChange} isShow={isShowTable}>
        <Table isShow={isShowTable} type={type} />
      </ReportBlocksWrapper>
    </section>
  );
};
export default LandingReport;
