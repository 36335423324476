import React, { useEffect, useState } from 'react';
import ModalsWrapper from '../wrappers/ModalsWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSourceConfigModal } from '../../state/modals/actions';
import { FormProvider, useForm } from 'react-hook-form';
import {
  createSource,
  deleteSourcePriority,
  getSource,
  getSourcesWithPriority,
  updateSource,
} from '../../state/client-management/async-actions';
import BlockButton from '../ui/buttons/block-btn';
import _ from 'lodash';
import InputField from '../ui/input';
import sourceConfigSelector from '../../state/modals/selectors/config-source';
import Toggle from '../ui/toggle';
import selectedSourceWithPrioritySelector from '../../state/client-management/selectors/sources/selected-source-priority';
import TertiaryButton from '../ui/buttons/tertiary';
import clientsSelector from '../../state/client-management/selectors/clients/clients';
import { joiResolver } from '@hookform/resolvers/joi';
import { sourceSchema } from '../../schemas/sourceSchema';

const SourceConfigModal = () => {
  const [isDataChanged, setIsDataChanged] = useState(false);

  const sourceConfigModal = useSelector(sourceConfigSelector);
  const source = useSelector(selectedSourceWithPrioritySelector);
  const clients = useSelector(clientsSelector);
  const isCreate = React.useMemo(() => !source.advertisers_priority, [source]);
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'all',
    resolver: joiResolver(sourceSchema),
    values: isCreate
      ? Object.fromEntries(
          clients.filter((client) => client.status === 'active').map((item) => [item.id, Number(item.priority)])
        )
      : Object.fromEntries(source.advertisers_priority.map((item) => [item.id, item.priority])),
  });

  const {
    handleSubmit,
    getValues,
    reset,
    // eslint-disable-next-line no-unused-vars
    formState: { isValid, errors },
  } = methods;

  const values = getValues();

  useEffect(() => {
    if (!isCreate) {
      if (_.isEqual(values, Object.fromEntries(source.advertisers_priority.map((item) => [item.id, item.priority])))) {
        setIsDataChanged(false);
      } else {
        setIsDataChanged(true);
      }
    }
  }, [values, source.advertisers_priority, isCreate]);

  const proccessCreate = (data) => {
    let dataToCreate = {
      name: source.name,
      status: 'active',
      advertisers_priority: Object.entries(data).map(([id, priority]) => ({ client_id: id, priority })),
    };

    dispatch(createSource(dataToCreate))
      .unwrap()
      .then(() => {
        dispatch(toggleSourceConfigModal());
      })
      .then(() => {
        dispatch(getSourcesWithPriority());
      })
      .then(() => {
        reset();
      });
  };

  const proccessUpdate = (data) => {
    dispatch(
      updateSource({
        id: source.id,
        advertisers_priority: Object.entries(data).map(([id, priority]) => ({ id: Number(id), priority })),
      })
    )
      .unwrap()
      .then(() => {
        dispatch(toggleSourceConfigModal());
      })
      .then(() => {
        dispatch(getSourcesWithPriority());
      });
  };

  const submit = (data) => {
    if (isCreate) {
      proccessCreate(data);
    } else {
      proccessUpdate(data);
    }
  };

  const handleSetStatus = () => {
    dispatch(
      updateSource({
        id: source.id,
        status: source.status === 'active' ? 'not-active' : 'active',
      })
    )
      .unwrap()
      .then(() => {
        dispatch(getSource(source.id));
      })
      .then(() => {
        dispatch(getSourcesWithPriority());
      });
  };

  const handleDeleteSource = () => {
    dispatch(deleteSourcePriority(source.id))
      .unwrap()
      .then(() => {
        dispatch(toggleSourceConfigModal());
      })
      .then(() => {
        dispatch(getSourcesWithPriority());
      });
  };

  let toggleValues = {
    active: false,
    'not-active': true,
  };

  return (
    <ModalsWrapper
      title={isCreate ? 'Create priority' : 'Update priority'}
      modal={sourceConfigModal}
      close={() => dispatch(toggleSourceConfigModal())}
    >
      <FormProvider {...methods}>
        <section id='names' className='mb-6'>
          {!isCreate && (
            <div className='flex gap-4 mb-2'>
              <TertiaryButton handleClick={handleDeleteSource}>Delete</TertiaryButton>
              <Toggle handleChange={handleSetStatus} currentValue={source.status} values={toggleValues} />
            </div>
          )}
          <div className='flex gap-6 flex-col'>
            {isCreate &&
              clients
                .filter((client) => client.status === 'active')
                .map(({ name, id, priority }, idx) => (
                  <div key={idx}>
                    <InputField
                      inputId={id}
                      name='priority'
                      isRequired={false}
                      label={(idx + 1) + ". "  + name}
                      placeholder={priority}
                      type={'text'}
                      styles={'!flex-row justify-between'}
                    />
                  </div>
                ))}
            {source.advertisers_priority?.map((p, idx) => (
              <div key={idx}>
                <InputField
                  inputId={String(p.id)}
                  name='priority'
                  isRequired={false}
                  label={(idx + 1) + ". " +  p.client_name}
                  placeholder={p.priority}
                  type={'text'}
                  styles={'!flex-row justify-between'}
                />
              </div>
            ))}
          </div>
        </section>
        <div className='flex justify-center gap-6'>
          <BlockButton handleClick={() => dispatch(toggleSourceConfigModal())}>Cancel</BlockButton>
          <BlockButton disabled={isCreate ? false : !isDataChanged} handleClick={handleSubmit(submit)}>
            {isCreate ? 'Create' : 'Update'}
          </BlockButton>
        </div>
      </FormProvider>
    </ModalsWrapper>
  );
};

export default SourceConfigModal;
