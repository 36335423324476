import React from 'react';
import ApplyButton from '../../global/filters/ApplyButton';
import DatePicker from '../../global/filters/DatePicker';
import ResetButton from '../../global/filters/ResetButton';
import { useDispatch, useSelector } from 'react-redux';
import filtersSelector from '../../../state/analytics/selectors/filters/filters';
import {
  resetFilters,
  selectAdvertiser,
  selectAllAdvertisers,
  selectAllCountries,
  selectAllLandingsID,
  selectAllSources,
  selectCountry,
  selectLandingID,
  selectSource,
  setDateRange,
} from '../../../state/analytics/actions';
import UserEmailInput from './UserEmailInput';
import CommonFilter from '../../global/filters/CommonFilter';
import { downloadReport, setReportData } from '../../../state/analytics/async-actions';
import pageInfoSelector from '../../../state/analytics/selectors/table/pagiInfo';
import DownloadButton from '../../ui/buttons/download';

const FiltersRow = ({ type }) => {
  const {
    advertisers,
    sources,
    countries,
    landings,
    selectedAdvertisers,
    selectedSource,
    selectedDate,
    selectedCountry,
    selectedLandingID,
    selectedUserEmail,
    sort,
  } = useSelector(filtersSelector);
  const { currentPage, pageSize } = useSelector(pageInfoSelector);
  const isFilter = true;
  const dispatch = useDispatch()

  const applyFunc = () => {
    dispatch(
      setReportData({
        isFilter,
        type,
        selectedDate,
        selectedAdvertisers,
        selectedSource,
        selectedCountry,
        selectedLandingID,
        selectedUserEmail,
        sort,
        pageSize,
        currentPage,
      })
    );
  };

  const resetFunc = () => {
    dispatch(resetFilters())
  }
  const handleDateRange = (newRange) => {
    dispatch(setDateRange(newRange));
  };
  const handleDownload = ()=> {
    var isDownload = true;
    dispatch(
      downloadReport({
        isDownload,
        isFilter,
        type,
        selectedDate,
        selectedAdvertisers,
        selectedSource,
        selectedCountry,
        selectedLandingID,
        selectedUserEmail,
        sort,
        pageSize,
        currentPage,
      })
    );
  }

  return (
    <div className='flex justify-between gap-3 items-center pb-5 min-w-max'>
      <div className='flex items-center gap-3'>
        <DatePicker handleSetDate={handleDateRange} selectedDate={selectedDate} />
        <CommonFilter
          name='Advertisers'
          items={advertisers}
          selectedItems={selectedAdvertisers}
          selectFunc={selectAdvertiser}
          selectAllFunc={selectAllAdvertisers}
        />
        <CommonFilter
          name='Sources'
          items={sources}
          selectedItems={selectedSource}
          selectFunc={selectSource}
          selectAllFunc={selectAllSources}
        />
        <CommonFilter
          name='Counties'
          items={countries}
          selectedItems={selectedCountry}
          selectFunc={selectCountry}
          selectAllFunc={selectAllCountries}
        />
        <CommonFilter
          name='Landings'
          items={landings}
          selectedItems={selectedLandingID}
          selectFunc={selectLandingID}
          selectAllFunc={selectAllLandingsID}
        />
        <UserEmailInput />
      </div>
      <div className='flex items-center gap-5'>
        <DownloadButton handleClick={handleDownload}>Download Exel</DownloadButton>
        <ApplyButton applyFunc={applyFunc} isFirstPage={currentPage === 1} isPaginate={true} />
        <ResetButton resetFunc={resetFunc} />
      </div>
    </div>
  );
};

export default FiltersRow;
