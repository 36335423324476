import { createSlice } from '@reduxjs/toolkit';
import { setDomainsReportData } from './async-actions';
import { TODAY_DATE } from '../../constants/date';
import { getFilters } from '../analytics/async-actions';
import { formatDate } from '../../helpers/time';

const initialState = {
  report: {},
  filter: {
    selectedDate: {
      startDate: TODAY_DATE,
      endDate: TODAY_DATE,
    },
    advertisers: [],
    sources: [],
    countries: [],
    selectedAdvertisers: [],
    selectedSource: [],
    selectedCountry: [],
  },
  loading: false,
  error: '',
};

export const domainsSlice = createSlice({
  name: 'domains-report',
  initialState,
  reducers: {
    setDateRange: (state, { payload }) => {
      state.filter.selectedDate.startDate = payload.startDate
        ? formatDate(payload.startDate)
        : TODAY_DATE;
      state.filter.selectedDate.endDate = payload.endDate ? formatDate(payload.endDate) : TODAY_DATE;
    },
    selectAdvertiser: (state, { payload }) => {
      if (state.filter.selectedAdvertisers.includes(payload)) {
        state.filter.selectedAdvertisers = state.filter.selectedAdvertisers.filter((id) => id !== payload);
      } else {
        state.filter.selectedAdvertisers.push(payload);
      }
    },
    selectAllAdvertisers: (state, { payload }) => {
      state.filter.selectedAdvertisers = payload ? payload.map(({id}) => id) : [];
    },
    selectSource: (state, { payload }) => {
      if (state.filter.selectedSource.includes(payload)) {
        state.filter.selectedSource = state.filter.selectedSource.filter((source) => source !== payload);
      } else {
        state.filter.selectedSource.push(payload);
      }
    },
    selectAllSources: (state, { payload }) => {
      state.filter.selectedSource = payload ? payload : [];
    },
    selectCountry: (state, { payload }) => {
      if (state.filter.selectedCountry.includes(payload)) {
        state.filter.selectedCountry = state.filter.selectedCountry.filter((country) => country !== payload);
      } else {
        state.filter.selectedCountry.push(payload);
      }
    },
    selectAllCountries: (state, { payload }) => {
      state.filter.selectedCountry = payload ? payload : [];
    },
    resetFilters: (state) => {
      state.filter.selectedAdvertisers = [];
      state.filter.selectedSource = [];
      state.filter.selectedCountry = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setDomainsReportData.fulfilled, (state, { payload }) => {
        state.report = payload;
        state.loading = false;
      })
      .addCase(setDomainsReportData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setDomainsReportData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getFilters.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.filter.advertisers = payload.advertisers;
        state.filter.sources = payload.source;
        state.filter.countries = payload.country;
      })
      .addCase(getFilters.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFilters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const domainsReportReducer = domainsSlice.reducer;
export default domainsReportReducer;
