import classNames from 'classnames';
import React, { useState } from 'react';

const Body = ({ data }) => {
  const [showSubRow, setShowSubRow] = useState([]);

  const onClickShowRow = (row) => {
    if (showSubRow.includes(row)) {
      setShowSubRow(showSubRow.filter((el) => el !== row));
    } else {
      setShowSubRow([...showSubRow, row]);
    }
  };
  return (
    <tbody>
      {data.map(({ Total, SubStats }, index) => (
        <>
          <tr onClick={() => onClickShowRow(index)} className='cursor-pointer'>
            {Object.values(Total).map((v, idx) => (
              <td
                key={idx}
                className={classNames(
                  'border-b-[1.5px] border-r-[1.5px] first:bg-[#FCFCFC] dark:first:bg-[#555454] border-[#E8E8E8] dark:border-[#3E3E3E] py-[5px] first:pl-[38px] first:pr-[22px] px-2 first:text-left whitespace-nowrap first:font-onest-medium  first:left-0',
                )}
              >
                {`${idx === 6 || idx === 9 || idx === 10  || idx === 13 ? '$' : ''} ${v} ${idx === 3 || idx === 5 || idx === 12 || idx === 14 || idx === 15 || idx === 16 ? '%' : ''}`}
              </td>
            ))}
          </tr>
          {SubStats?.map((val, idx) => (
            <tr key={idx} className={showSubRow?.includes(index) ? '' : 'hidden'}>
              {Object.values(val).map((v, i) => (
                <td
                  key={i}
                  className={classNames(
                    'border-b-[1.5px] border-r-[1.5px] bg-slate-400 border-[#E8E8E8] dark:border-[#3E3E3E] py-[5px] first:pl-[38px] first:pr-[22px] px-2 first:text-end whitespace-nowrap first:font-onest-medium  first:left-0',
                    {
                      italic: i === 0,
                    }
                  )}
                >
                   {`${i === 6 || i === 9 || i === 10 || i === 13 ? '$' : ''} ${v} ${i === 3 || i === 5 || i === 12 || i === 14 || i === 15 || i === 16 ? '%' : ''}`}
                </td>
              ))}
            </tr>
          ))}
        </>
      ))}
    </tbody>
  );
};

export default Body;
