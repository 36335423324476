import React from 'react';
import ReactPaginate from 'react-paginate';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

const PaginationButtons = ({ totalPages, currentPage, setPageFunc }) => {
  const showNextButton = currentPage !== totalPages;
  const showPrevButton = currentPage !== 1;
  const dispatch = useDispatch();

  const handlePageClick = ({ selected }) => {
    dispatch(setPageFunc(selected + 1));
  };

  return (
    <ReactPaginate
      breakLabel={<span className='mr-4'>...</span>}
      forcePage={currentPage - 1}
      nextLabel={
        <span
          className={classNames('w-10 h-10 flex items-center justify-center bg-lightGray rounded-md', {
            invisible: !showNextButton,
          })}
        >
          <BsChevronRight />
        </span>
      }
      onPageChange={handlePageClick}
      pageRangeDisplayed={4}
      pageCount={totalPages}
      previousLabel={
        <span
          className={classNames('w-10 h-10 flex items-center justify-center bg-lightGray rounded-md mr-4', {
            invisible: !showPrevButton,
          })}
        >
          <BsChevronLeft />
        </span>
      }
      containerClassName='flex items-center justify-center'
      pageClassName='block border- border-solid border-lightGray hover:bg-lightGray w-10 h-10 flex items-center justify-center rounded-md mr-4'
      activeClassName='bg-grey-2 text-red'
    />
  );
};

export default PaginationButtons;
