import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import pushErrorSelector from '../../../state/push/selectors/error';
import pushLoadingSelector from '../../../state/push/selectors/loading';
import appsSelector from '../../../state/push/selectors/apps/apps';
import { deleteApp, getApps } from '../../../state/push/async-actions';
import Page401 from '../../error-pages/Page401';
import Page403 from '../../error-pages/Page403';
import Loading from '../../global/Loading';
import classNames from 'classnames';
import TableWrapper from '../../ui/table/TableWrapper';
import TableAppsHeader from '../../ui/table/AppsHeader';
import { APPS_TABLE_HEADERS } from '../../../constants/push-headers';
import AppsTableBody from './TableBody';
import DeleteModal from '../../modals/DeleteModal';
import { toggleDeleteAppConfModal } from '../../../state/modals/actions';
import deleteAppConfModalSelector from '../../../state/modals/selectors/delete-app';

const Apps = () => {
  const error = useSelector(pushErrorSelector);
  const isLoading = useSelector(pushLoadingSelector);
  const apps = useSelector(appsSelector);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getApps())
  }, [dispatch])
  
  const handleDelete = () => {
    dispatch(deleteApp())
      .unwrap()
      .then(() => {
        dispatch(toggleDeleteAppConfModal());
      });

  };
  const deleteConfirmation = useSelector(deleteAppConfModalSelector);

  if (error?.status === 401) {
    return <Page401 service='push' error={error} />;
  }
  if (error?.status === 403) {
    return <Page403 />;
  }
  if (error) {
    return <h2 className='text-black-1 dark:text-white text-3xl pt-10 text-center'>{error.message || error}</h2>;
  }
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
    <div
      className={classNames('overflow-auto h-[calc(100%_-_96px)]', {
        'pt-52': !apps.length,
      })}
    >
      {apps &&  (
        <TableWrapper>
          <TableAppsHeader headers={APPS_TABLE_HEADERS} topStyle={0} />
          <AppsTableBody />
        </TableWrapper>
      )}
      <DeleteModal
        title={'Delete Push Application'}
        description={"Are you sure you want to delete this App? If you delete it, you can't get it back."}
        onDelete={handleDelete}
        onClose={() => {
          dispatch(toggleDeleteAppConfModal());
        }}
        deleteConfirmation={deleteConfirmation}
      />
    </div>
  </>
  )
}

export default Apps