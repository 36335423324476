import React, { useState } from 'react';
import BlockButton from '../../../../ui/buttons/block-btn';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedBlock } from '../../../../../state/client-management/actions';
import selectedBlockSelector from '../../../../../state/client-management/selectors/clients/selectet-block';
import { deleteClientBlock, getClient, updateClient } from '../../../../../state/client-management/async-actions';
import { toggleAddBlockModal } from '../../../../../state/modals/actions';
import AddBlockModal from '../../../../modals/AddBlockModal';
import CreateBlockModal from '../../../../modals/CreateBlockModal';
import clientSelector from '../../../../../state/client-management/selectors/clients/client';
import Toggle from '../../../../ui/toggle';

const BlocksSection = ({ blocks }) => {
  const [selectedBlockIDX, setSelectedBlockIDX] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const selectedBlock = useSelector(selectedBlockSelector);
  const {list_type, id} = useSelector(clientSelector);
  const dispatch = useDispatch();

  const handleBlockClick = (id, blockID) => {
    setSelectedBlockIDX(selectedBlockIDX === id ? null : id);
    setButtonDisabled(selectedBlock === blockID ? true : false);
    dispatch(setSelectedBlock(selectedBlock === blockID ? null : blockID));
  };

  const handleDelete = () => {
    dispatch(deleteClientBlock());
    setButtonDisabled(true);
     setSelectedBlockIDX(null)
  };

  const handleClickAdd = () => {
    dispatch(toggleAddBlockModal())
  }

  const handleSetListType = () => {
    dispatch(updateClient({
      list_type: list_type === 'black' ? 'white' : 'black',
    }))
    .unwrap()
      .then(() => {
        dispatch(getClient(id));
      })
    }

    let toggleValues = {
      black: true,
      white: false,
    };

  return (
    <section id='projects' className='flex flex-col gap-4'>
      <div><h3 className='text-2xl'>Advertiser source list:</h3></div>
      <div className='flex gap-4'>
        <div className='flex flex-col gap-3 w-[300px] border rounded-md border-grey-2 p-3 h-[250px] overflow-auto'>
          {blocks &&
            !!blocks.length &&
            blocks.map((block, id) => (
              <div
                key={id}
                onClick={() => handleBlockClick(id, block.id)}
                className={classNames(
                  'block p-2 w-full text-sm rounded-md border hover:border-black-3 focus:border-black-3 dark:hover:border-grey-4 dark:focus:border-grey-4 read-only:bg-grey-4 dark:read-only:border-grey-0 dark:read-only:bg-black-3 dark:read-only:text-grey-3 focus:ring-0 dark:focus:ring-0',
                  {
                    '!border-grey-4 !bg-grey-1 !text-gray-950': selectedBlockIDX === id,
                  }
                )}
              >
                <span>{block.value}</span>
              </div>
            ))}
        </div>
        <div className='flex flex-col gap-4 justify-center mr-2'>
            <Toggle handleChange={handleSetListType} values={toggleValues}  currentValue={list_type}/>
          <BlockButton disabled={isButtonDisabled} handleClick={handleDelete}>
            Delete
          </BlockButton>
          <BlockButton
          handleClick = {handleClickAdd}
          >Add</BlockButton>
          {/* <BlockButton
          handleClick = {handleClickCreate}
          >Create</BlockButton> */}
        </div>
      </div>
      <AddBlockModal />
      <CreateBlockModal />
    </section>
  );
};

export default BlocksSection;
