import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRejectsReport } from '../../api/rejects-report/rejectsReport';

export const setRejectsReportData = createAsyncThunk(
  'rejects-report/set-report',
  (
    selectedDate,
    selectedAdvertisers,
    selectedSource,
    selectedCountry,
    cursor,
    thunkAPI
  ) =>
    fetchRejectsReport(
      selectedDate,
      selectedAdvertisers,
      selectedSource,
      selectedCountry,
      cursor,
      thunkAPI
    )
);

