import Joi from 'joi';

export const limitSchema = Joi.object({
  limit_name: Joi.string().min(1).max(50).trim().messages({
    'string.base': `It should be a type of 'text'`,
    'string.min': `This field should have a min length of {#limit}`,
    'string.max': `This field should have a max length of {#limit}`,
  }),
  limit_type: Joi.string().valid('GEO', 's1', 'general').trim().required().messages({
    'string.base': `It should be a type of 'text'`,
    'any.only': 'The "Limit type" field should be either "GEO" or "s1" or "general',
    'string.empty': `This field can not be empty`,
    'string.required': `This field is required`,
  }),
  value: Joi.number().integer().required().messages({
    'number.base': 'The "Value" field should be a number',
    'number.integer': 'The "Payout" field should be an integer',
  }),
});
