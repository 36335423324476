import React from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import ROUTES from '../../../constants/routes';
import Header from './Header';
import logo from '../../../assets/logo-fb.png';
// import { firebaseSignOut } from '../../../firebase/auth';
// import PrimaryButton from '../../ui/buttons/primary';

const GuestHeader = () => {
  // const { pathname } = useLocation();
  // const signUpRoute = ROUTES.SIGN_UP.route;
  // const keysPath = pathname === ROUTES.AUTH_KEYS.route;
  // const signInRoute = ROUTES.SIGN_IN.route;
  // const signInPath = pathname === signInRoute;
  // const navigate = useNavigate();

  // const buttonText = signInPath ? 'Sign Up' : 'Sign In';
  // const navigateLink = signInPath ? signUpRoute : signInRoute;
  // const clickHandler = () => (keysPath ? firebaseSignOut() : navigate(navigateLink));

  return (
    <Header>
      <a href='/'>
        <img src={logo} className='mr-3 h-14 w-14' alt='logo' />
      </a>
      {/* {keysPath && <PrimaryButton handleClick={clickHandler}>Sign Out</PrimaryButton>} */}
    </Header>
  );
};

export default GuestHeader;
