import React from 'react';
import { useFormContext } from 'react-hook-form';

const Checkbox = ({ id,  isDisabled, onClick, title, label, styles }) => {
  const {
    register,
    setValue,
    watch,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useFormContext();

  watch(id);

  return (
    <div className={`flex items-center justify-center gap-2 ${styles}`}>
       {label && (
        <label htmlFor={id} className='text-sm text-left h-fit text-black-3 dark:text-white'>
          {label}
        </label>
      )}
    <input
    {...register(id)}
    title={title}
    id={id}
    type='checkbox'
    onChange={(e) => {
      setValue(id, e.target.checked)
    }}
    disabled={isDisabled ? isDisabled : false}
    onClick={(e) => !onClick && e.stopPropagation()}
    className='w-4 h-4 text-red checked:bg-red dark:checked:bg-red bg-white dark:bg-black-1 border-grey-2 dark:border-black-3 hover:border-red dark:hover:border-red checked:border-none rounded-sm focus:ring-grey-1 focus:ring-1 cursor-pointer disabled:cursor-not-allowed disabled:bg-grey-3 dark:disabled:bg-grey-1'
    />
    </div>
  );
};

export default Checkbox;
