import React from 'react';
import { Controller } from 'react-hook-form';
import LabeledSelectBox from '../../../../ui/select-box/labeled';
import SelectBox from '../../../../ui/select-box';
import { COUNTRY } from '../../../../../constants/limits';

const LimitTypeSel = ({ control }) => {
  return (
    <section id='limitsType' className='flex flex-col gap-4'>
      <div>
      <Controller
          name='limit_name'
          control={control}
          render={({ field: { value, name, onChange }, fieldState: { error } }) => (
            <LabeledSelectBox label={'Limit name'} isRequired={true} error={error}>
              <SelectBox
                name={name}
                placeholder={'Select a limit name'}
                selectedValue={value}
                options={COUNTRY}
                label={'name'}
                onSelectChange={onChange}
                secondary={true}
              />
            </LabeledSelectBox>
          )}
        />
      </div>
    </section>
  );
};

export default LimitTypeSel;
