import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import themeSelector from '../../state/menus/selectors/theme';
import { Link } from 'react-router-dom';
import { selectMenuItem } from '../../state/menus/actions';

const HomeIcon = ({ title, description, item, children }) => {
  const theme = useSelector(themeSelector);
  const dispatch = useDispatch();

  return (
    <Link to={item.route} onClick={() => dispatch(selectMenuItem(item.name))}>
      <div
        className={classNames(
          'group mb-6 w-[308px] h-[230px] cursor-pointer rounded-sm flex items-center justify-center hover:shadow-none hover:border-red hover:border-2',
          {
            'bg-gradient-to-l from-white to-[#EEEFF4] hover:bg-white shadow-[rgba(13,_38,_76,_0.19)_5px_9px_20px]':
              theme === 'light',
            'bg-gradient-to-r from-black-1 to-[#1B1B1B] hover:bg-black-1 shadow-[rgba(0,_0,_0,_0.19)_5px_9px_20px]':
              theme === 'dark',
          }
        )}
      >
        <div
          className={classNames('transition-all ease-linear duration-300', {
            'fill-grey-3 stroke-grey-3 group-hover:fill-red group-hover:stroke-red': theme === 'light',
            'fill-black-3 stroke-black-3 group-hover:fill-white group-hover:stroke-white': theme === 'dark',
          })}
        >
          {children}
        </div>
        <div className='absolute mb-7 flex gap-1 text-blue-2 dark:text-grey-4 left-0 bottom-[-100px] w-[750px] invisible group-hover:visible border-l-4 border-blue-light dark:border-grey-3'>
          <div className='h-1 bg-blue-light dark:bg-grey-3'></div>
          <div className='p-4 bg-blue-1 bg-opacity-50 dark:bg-black-2'>
            <h1 className='mb-1 font-onest-regular font-bold'>{title}</h1>
            <p className='text-sm font-onest-regular'>{description}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default HomeIcon;
