import React from 'react';
import { Controller } from 'react-hook-form';
import LabeledSelectBox from '../../../ui/select-box/labeled';
import MultiSelect from '../../../ui/multi-select';

const CategorySel = ({ control }) => {
  var categories = [
    {
      id: 0,
      name: 'general',
    },
    { id: 1, 
      name: 'gay' },
  ];

  return (
    <section id='category' className='flex flex-col gap-4'>
      <div className='max-w-[300px]'>
        <Controller
          name='category'
          control={control}
          render={({ field: { value, name, onChange }, fieldState: { error } }) => (
            <LabeledSelectBox label={'Message Categories:'} isRequired={true} error={error}>
              <MultiSelect
                id={name}
                placeholder={'Select category'}
                itemLabel={'name'}
                options={categories}
                onChange={onChange}
                values={value || []}
                isRequired={false}
                isDisabled={!categories.length}
                isReadOnly={false}
              />
            </LabeledSelectBox>
          )}
        />
      </div>
    </section>
  );
};

export default CategorySel;
