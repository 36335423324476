import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FiltersButton from '../../assets/icons/FilterButton.svg';
import FiltersButtonBlack from '../../assets/icons/FiltersButtonBlack.svg';
import { THEMES } from '../../constants/themes';
import themeSelector from '../../state/menus/selectors/theme';
import classNames from 'classnames';

const ReportBlocksWrapper = ({title, handleShow, isShow, children}) => {
  const theme = useSelector(themeSelector);

  return (
    <section className={classNames('border rounded-md p-4 bg-blue-0 dark:bg-grey-0', { 'h-[53px]': !isShow })}>
      <button onClick={() => handleShow()} className='flex items-center gap-2 mb-3'>
        <img src={theme === THEMES.light ? FiltersButtonBlack : FiltersButton} alt='Open summary icon' />
        {title}
      </button>
      {children}
    </section>
  );
};

export default ReportBlocksWrapper;
