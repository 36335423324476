import { createAsyncThunk } from '@reduxjs/toolkit';
import { addBlocksToClient, createLimit, createSourceBlock,  createSourcePriority,  fetchClientByID, fetchClients, fetchSource, fetchSources, fetchSourcesWithPriority, modifyClient, modifySource, removeBlock, removeClientBlock, removeLimit, removeSourcePriority, updateLimitByID } from '../../api/clients/clients';

//read
export const getClients = createAsyncThunk('clients/get-clients', (_, thunkAPI) => fetchClients(thunkAPI));

export const getSourcesWithPriority = createAsyncThunk('clients/get-sources-priority', (_, thunkAPI) => fetchSourcesWithPriority(thunkAPI));

export const updateSource = createAsyncThunk('clients/update-source', (data, thunkAPI) => modifySource(data, thunkAPI));

export const createSource = createAsyncThunk('clients/create-source', (data, thunkAPI) => createSourcePriority(data, thunkAPI));

export const getSources = createAsyncThunk('clients/get-sources', (_, thunkAPI) => fetchSources(thunkAPI));

export const getSource = createAsyncThunk('clients/get-source', (id, thunkAPI) => fetchSource(id, thunkAPI));

export const deleteSourcePriority = createAsyncThunk('clients/delete-source', (id, thunkAPI) => removeSourcePriority(id, thunkAPI));

export const getClient = createAsyncThunk('clients/get-client', (id, thunkAPI) => fetchClientByID(id, thunkAPI));

export const updateClient = createAsyncThunk('clients/update-client', (data, thunkAPI) => modifyClient(data, thunkAPI));

export const deleteBlock = createAsyncThunk('clients/delete-block', (_, thunkAPI) => removeBlock(thunkAPI));

export const deleteLimit = createAsyncThunk('clients/delete-limit', (_, thunkAPI) => removeLimit(thunkAPI));

export const deleteClientBlock = createAsyncThunk('clients/delete-client-block', (_, thunkAPI) => removeClientBlock(thunkAPI));

export const addBlocks = createAsyncThunk('clients/add-blocks', (data, thunkAPI) => addBlocksToClient(data, thunkAPI));

export const createBlock = createAsyncThunk('clients/create-block', (data, thunkAPI) => createSourceBlock(data, thunkAPI));

export const addLimit = createAsyncThunk('clients/create-limit', (data, thunkAPI) => createLimit(data, thunkAPI));

export const updateLimit = createAsyncThunk('clients/update-limit', (data, thunkAPI) => updateLimitByID(data, thunkAPI));

