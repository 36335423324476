import React, { useEffect, useState } from 'react';
import ModalsWrapper from '../wrappers/ModalsWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCreateBlockModal } from '../../state/modals/actions';
import { FormProvider, useForm } from 'react-hook-form';
import { createBlock, getSources } from '../../state/client-management/async-actions';
import BlockButton from '../ui/buttons/block-btn';
import _ from 'lodash';
import InputField from '../ui/input';
import createBlockModalSelector from '../../state/modals/selectors/create-block';
import { joiResolver } from '@hookform/resolvers/joi';
import { blockSchema } from '../../schemas/blockSchema';

const CreateBlockModal = () => {
  const [isDataChanged, setIsDataChanged] = useState(false);

  const createBlockModal = useSelector(createBlockModalSelector);
  const dispatch = useDispatch();

  const submit = (data) => {
    dispatch(createBlock(data))
      .unwrap()
      .then(() => {
        dispatch(toggleCreateBlockModal());
      })
      .then(() => {
        dispatch(getSources());
      });
  };

  const methods = useForm({ mode: 'all', resolver: joiResolver(blockSchema), values: '' });

  const {
    handleSubmit,
    getValues,
    // eslint-disable-next-line no-unused-vars
    formState: { isValid, errors },
  } = methods;

  const values = getValues();

  useEffect(() => {
    if (values.block_name !== '' && values.block_name !== undefined) {
      setIsDataChanged(false);
    } else {
      setIsDataChanged(true);
    }
  }, [values]);

  return (
    <ModalsWrapper
      title={'Create source block'}
      modal={createBlockModal}
      close={() => dispatch(toggleCreateBlockModal())}
    >
      <FormProvider {...methods}>
        <section id='names' className='mb-6'>
          <div className='flex gap-6'>
            <InputField
              inputId={'block_name'}
              isRequired={true}
              label={'Block name'}
              placeholder={'Enter a block name'}
              type={'text'}
            />
          </div>
        </section>
        <div className='flex justify-center gap-6'>
          <BlockButton handleClick={() => dispatch(toggleCreateBlockModal())}>Cancel</BlockButton>
          <BlockButton disabled={isDataChanged} handleClick={handleSubmit(submit)}>
            Add
          </BlockButton>
        </div>
      </FormProvider>
    </ModalsWrapper>
  );
};

export default CreateBlockModal;
