import React from 'react';
import Error403Dark from '../../assets/errors/Error403Dark.svg';
import Error403White from '../../assets/errors/Error403White.svg';
import { useSelector } from 'react-redux';
import themeSelector from '../../state/menus/selectors/theme';
import { THEMES } from '../../constants/themes';
import { Link } from 'react-router-dom';
import sidebarSelector from '../../state/menus/selectors/sidebar';
import classNames from 'classnames';

const Page403 = () => {
  const theme = useSelector(themeSelector);
  const sidebar = useSelector(sidebarSelector);
  return (
    <div
      className={classNames('flex flex-col lg:flex-row justify-center w-full items-center 2xl:mx-auto gap-7', {
        'lg:gap-[5%] 2xl:gap-[8.5%]': sidebar,
        'lg:gap-[8.5%]': !sidebar,
      })}
    >
      <img
        className={classNames('md:w-[300px] md:h-[300px] h-[250px] w-[250px] transition-all duration-500', {
          'lg:w-[300px] lg:h-[300px] xl:w-[400px] xl:h-[400px] 2xl:w-[460px] 2xl:h-[460px]': sidebar,
          'lg:w-[350px] lg:h-[350px] xl:w-[460px] xl:h-[460px] 2xl:w-[460px] 2xl:h-[460px]': !sidebar,
        })}
        alt='Error Robot'
        src={theme === THEMES.light ? Error403White : Error403Dark}
      />
      <div className='dark:text-white xl:max-w-md max-w-sm transition-all duration-500'>
        <h1 className='text-[34px] leading-snug tracking-widest font-semibold mb-7'>Oops! Key with restrictions.</h1>
        <p className='text-base mb-7'>
          Your role doesn't have enough permission to perform this action. This usually will be only if you are
          restricted from receiving info about Compensations for employees.
        </p>
        <div className='flex flex-col gap-2 xl:flex-row text-sm text-center'>
          <Link
            to='#'
            className='flex-auto px-5 py-2.5 tracking-wide text-red hover:text-red-burgundy transition-colors duration-200 rounded-md focus:outline-none active:font-bold'
          >
            Visit to our help center
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Page403;
