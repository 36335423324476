import { getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth} from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

import { firebaseConfig } from '../config/firebase';

let firebaseApp;

if (getApps().length) {
  firebaseApp = getApp();
} else {
  firebaseApp = initializeApp(firebaseConfig);
}

export const auth = getAuth(firebaseApp);

export const functions = getFunctions(firebaseApp);
export default firebaseApp;
