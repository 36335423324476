import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDomainsReport } from '../../api/domain-report/domainsReport';

export const setDomainsReportData = createAsyncThunk(
  'domains-report/set-report',
  (
    selectedDate,
    selectedAdvertisers,
    selectedSource,
    selectedCountry,
    thunkAPI
  ) =>
    fetchDomainsReport(
      selectedDate,
      selectedAdvertisers,
      selectedSource,
      selectedCountry,
      thunkAPI
    )
);

