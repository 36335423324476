import { joiResolver } from '@hookform/resolvers/joi';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import updateAppConfModalSelector from '../../../../../state/modals/selectors/update-app';
import { appSchema } from '../../../../../schemas/appSchema';
import ActionButtonGroup from '../../../../ui/button-group/ActionButtonGroup';
import { toggleUpdateAppConfModal } from '../../../../../state/modals/actions';
import UpdateModal from '../../../../modals/UpdateModal';
import InputField from '../../../../ui/input';
import { addApp, getApp, getApps, updateApp } from '../../../../../state/push/async-actions';
import StatusSel from '../../../../crm/clients/client/form-sections/status-sel';
import CategorySel from './category-sel';
import SegmentSel from './segment-sel';

const AppForm = ({ app }) => {
  var fields = [
    {
      inputId: 'name',
      isRequired: false,
      label: 'Client name',
      placeholder: app.name,
      type: 'text',
      isReadOnly: true,
    },
    {
      inputId: 'id',
      isRequired: false,
      label: 'ID',
      placeholder: app.id,
      type: 'text',
      isReadOnly: true,
    },
    {
      inputId: 'api_key',
      isRequired: true,
      label: 'Api Key',
      placeholder: app.api_key,
      type: 'text',
      isReadOnly: false,
    },
    {
      inputId: 'frequency',
      isRequired: true,
      label: 'Frequency(minute)',
      placeholder: app.payout,
      type: 'text',
      isReadOnly: false,
    },
    {
      inputId: 'link',
      isRequired: true,
      label: 'Link',
      placeholder: app.link,
      type: 'text',
      isReadOnly: false,
    },
  ];

  // const clientWithoutId = _.omit(client, 'id');
  const updateConfirmation = useSelector(updateAppConfModalSelector);

  const dispatch = useDispatch();

  const [isDataChanged, setIsDataChanged] = useState(false);
  const methods = useForm({ mode: 'all', resolver: joiResolver(appSchema), values: withArrayObject(app) });
  const {
    handleSubmit,
    getValues,
    reset,
    // eslint-disable-next-line no-unused-vars
    formState: { isValid, errors },
  } = methods;
  const values = getValues();

  const omitEmptyFields = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (obj[key] !== '') {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  useEffect(() => {
    if (_.isEqual(omitEmptyFields(values), omitEmptyFields(withArrayObject(app)))) {
      setIsDataChanged(false);
    } else {
      setIsDataChanged(true);
    }
  }, [app, values]);

  const submit = (data) => {
    if (!app.need_to_create) {
      // const changedValues = Object.entries(omitEmptyFields(data))
      //   .filter(([key, value]) => !_.isEqual(value, app[key]))
      //   .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      // if (Object.keys(changedValues).length === 0) {
      //   // No changes, do not submit
      //   console.log('No changes to submit');
      //   return;
      // }

      // console.log('Submitting changes:', changedValues);
      dispatch(updateApp(values))
        .unwrap()
        .then(() => {
          dispatch(getApp(app.id));
        })
        .then(() => {
          // dispatch(setEditableClient(false));
          dispatch(getApps());
        });
    } else {
      dispatch(addApp(values))
        .unwrap()
        .then(() => {
          dispatch(getApp(app.id));
        })
        .then(() => {
          // dispatch(setEditableClient(false));
          dispatch(getApps());
        });
    }
  };
  const handleOpenConfModal = () => {
    dispatch(toggleUpdateAppConfModal());
  };
  return (
    <div className='flex flex-col'>
      <FormProvider {...methods}>
        <form className='flex flex-col gap-8 w-full relative' onSubmit={handleSubmit(submit)}>
          <h3 className='text-2xl'>Application info:</h3>
          <section id='client_info' className='flex flex-wrap gap-4 mr-[30px]'>
            {fields.map((field, id) => (
              <div key={id} className='w-[300px]'>
                <InputField
                  inputId={field.inputId}
                  isRequired={field.isRequired}
                  label={field.label}
                  placeholder={field.placeholder}
                  type={field.type}
                  isReadOnly={field.isReadOnly}
                />
              </div>
            ))}
            <StatusSel />
            <CategorySel />
            <SegmentSel />
          </section>
        </form>
      </FormProvider>
      {/* <div className='flex gap-[200px] max-xl:flex-col max-xl:gap-[50px] my-10'></div> */}
      <div className='flex gap-6 my-6'>
        <span>
          Created time: <b>{moment.utc(app.created_at).format('DD.MM.YYYY')}</b>
        </span>
        <span>
          Updated time: <b>{moment.utc(app.updated_at).format('DD.MM.YYYY')}</b>
        </span>
      </div>
      <ActionButtonGroup
        link={{ handleClick: reset, isDisabled: !isDataChanged }}
        button={{
          isDisabled: !isDataChanged,
          label: app.need_to_create ? 'Create' : 'Update',
          handleClick: app.need_to_create ? () => submit(values) : handleOpenConfModal,
        }}
        styles={'justify-end sticky bottom-0 z-50 dark:bg-black-1 bg-grey-6 max-md:justify-start mr-5'}
      />
      <UpdateModal
        title={'Update Application'}
        description={"Are you sure you want to update this application? If you update it, you can't get it back."}
        onUpdate={() => dispatch(toggleUpdateAppConfModal()) && submit(values)}
        onClose={handleOpenConfModal}
        deleteConfirmation={updateConfirmation}
      />
    </div>
  );
};

export default AppForm;

function withArrayObject(app) {
  const updatedApp = { ...app };
  updatedApp.include_segment = updatedApp.include_segment?.map((id, value) => ({ id: value, name: id })) || [];
  updatedApp.message_category = updatedApp.message_category?.map((id, value) => ({ id: value, name: id })) || [];
  return updatedApp;
}
