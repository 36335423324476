import React from 'react';
import PageWrapper from '../components/wrappers/PageWrapper';
import Analytics from '../components/analytics/Analytics';

const AnalyticPage = () => {
  return (
    <PageWrapper>
      <Analytics />
    </PageWrapper>
  );
};

export default AnalyticPage;
