import React from 'react';
import ThemeWrapper from './components/wrappers/ThemeWrapper';
import AppRouter from './routes/AppRouter';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <ThemeWrapper>
      <AppRouter />
      <Toaster position='top-right' />
    </ThemeWrapper>
  );
}
export default App;
