import { createSlice } from '@reduxjs/toolkit';
import {
  addBlocks,
  addLimit,
  createBlock,
  createSource,
  deleteBlock,
  deleteClientBlock,
  deleteLimit,
  deleteSourcePriority,
  getClient,
  getClients,
  getSource,
  getSources,
  getSourcesWithPriority,
  updateClient,
  updateLimit,
  updateSource,
} from './async-actions';

const initialState = {
  clients: {
    clients: [],
    client: {},
    selectedBlock: null,
    selectedLimit: null,
  },
  sourcesWithPriority: {
    sourcesWithPriority: [],
    sourceWithPriority: {},
    selectedSourceWithPriority: {},
    advertisers: [],
    toDelete: null,
  },
  sources: [],
  isLoading: false,
  isEditable: false,
  error: '',
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setEditableClient: (state, { payload }) => {
      state.isEditable = payload;
    },
    setSelectedBlock: (state, { payload }) => {
      state.clients.selectedBlock = payload;
    },
    setSelectedLimit: (state, { payload }) => {
      state.clients.selectedLimit = payload;
    },
    setSelectedSourceWithPriority: (state, { payload }) => {
      state.sourcesWithPriority.selectedSourceWithPriority = payload;
    },
    setBlockToDelete: (state, { payload }) => {
      state.sourcesWithPriority.toDelete = payload;
    },
    setAdvertisers: (state, { payload }) => {
      state.sourcesWithPriority.advertisers = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClients.fulfilled, (state, { payload }) => {
        state.clients.clients = payload ? payload : [];
        state.isLoading = false;
      })
      .addCase(getClients.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getClients.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(getClient.fulfilled, (state, { payload }) => {
        state.clients.client = payload ? payload : {};
        state.isLoading = false;
      })
      .addCase(getClient.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getClient.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(updateClient.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateClient.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateClient.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(updateSource.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateSource.pending, (state) => {
        state.error = null;
      })
      .addCase(updateSource.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(createSource.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createSource.pending, (state) => {
        state.error = null;
      })
      .addCase(createSource.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(deleteBlock.fulfilled, (state) => {
        state.sourcesWithPriority.blocks = state.sourcesWithPriority.blocks.filter(
          ({ id }) => id !== state.sourcesWithPriority.toDelete
        );
        state.isLoading = false;
      })
      .addCase(deleteBlock.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteBlock.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
      .addCase(deleteClientBlock.fulfilled, (state) => {
        state.clients.client.s1_blocks = state.clients.client.s1_blocks.filter(
          ({ id }) => id !== state.clients.selectedBlock
        );
        state.clients.selectedBlock = null;
        state.isLoading = false;
      })
      .addCase(deleteClientBlock.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteClientBlock.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(getSourcesWithPriority.fulfilled, (state, { payload }) => {
        state.sourcesWithPriority.sourcesWithPriority = payload ? payload : [];
        state.isLoading = false;
      })
      .addCase(getSourcesWithPriority.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSourcesWithPriority.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(getSources.fulfilled, (state, { payload }) => {
        // state.sources = payload ? Array.from(new Set(payload.map(item => item.includes("_") ? item.split("_")[0] : item))) : [];

        state.sources =  payload ? payload.forEach((item, index) => {
          if (item.includes('_') || item.includes('-')) {
            let prefix = item.split(/[-_]/)[0];
            if (!payload.includes(prefix)) {
              payload.splice(index, 0, prefix);
            }
          }
        }) : [];

        state.sources = payload;
        state.isLoading = false;
      })
      .addCase(getSources.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSources.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(getSource.fulfilled, (state, { payload }) => {
        state.sourcesWithPriority.selectedSourceWithPriority = payload;
        state.isLoading = false;
      })
      .addCase(getSource.pending, (state) => {
        state.error = null;
      })
      .addCase(getSource.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(addBlocks.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addBlocks.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addBlocks.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(createBlock.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(createBlock.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createBlock.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(addLimit.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addLimit.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addLimit.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
      .addCase(deleteLimit.fulfilled, (state) => {
        state.clients.client.limits = state.clients.client.limits.filter(
          ({ id }) => id !== state.clients.selectedLimit
        );
        state.clients.selectedLimit = null;
        state.isLoading = false;
      })
      .addCase(deleteLimit.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteLimit.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
      .addCase(deleteSourcePriority.fulfilled, (state) => {
        state.sourcesWithPriority.selectedSourceWithPriority = [];
        state.isLoading = false;
      })
      .addCase(deleteSourcePriority.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteSourcePriority.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
      .addCase(updateLimit.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateLimit.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateLimit.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      });
  },
});

const clientsReducer = clientsSlice.reducer;

export default clientsReducer;
