import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getImages } from '../../state/push/async-actions';
import BlockButton from '../ui/buttons/block-btn';
import { toggleMessageImageModal } from '../../state/modals/actions';
import messageImageModalSelector from '../../state/modals/selectors/message-image';
import imageTypeSelector from '../../state/push/selectors/messages/image-type';
import imagesSelector from '../../state/push/selectors/messages/images';
import MessageImagesModalsWrapper from '../wrappers/MessageImagesModalWrapper';
import { useFormContext } from 'react-hook-form';

const MessageImageModal = () => {
  const dispatch = useDispatch();
  const messageImage = useSelector(messageImageModalSelector);
  const imageType = useSelector(imageTypeSelector);
  const images = useSelector(imagesSelector);
  const {
    setValue,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useFormContext();

  React.useEffect(() => {
    dispatch(getImages(imageType));
  }, []);

  const onClickImage = (path) => {
    setValue(imageType, path);
    dispatch(toggleMessageImageModal(false));
  };

  return (
    <MessageImagesModalsWrapper
      title={'Choose Image'}
      modal={messageImage}
      close={() => dispatch(toggleMessageImageModal())}
    >
      <div className='flex gap-2 flex-wrap mb-6'>
        {images.map((path, id) => (
          <div key={id} className='max-w-[100px]' onClick={() => onClickImage(path)}>
            <img src={path} alt='view' className='w-full' />
          </div>
        ))}
      </div>
      <BlockButton disabled={false} handleClick={() => dispatch(toggleMessageImageModal())}>
        Cancel
      </BlockButton>
    </MessageImagesModalsWrapper>
  );
};

export default MessageImageModal;
