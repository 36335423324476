import React from 'react';
import PageWrapper from '../components/wrappers/PageWrapper';
import Rejects from '../components/rejects-report/Rejects';

const RejectsReportPage = () => {
  return (
    <PageWrapper>
      <Rejects />
    </PageWrapper>
  );
};

export default RejectsReportPage;
