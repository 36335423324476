import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ClientForm from './Form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import clientSelector from '../../../../state/client-management/selectors/clients/client';
import Loading from '../../../global/Loading';
import Breadcrumbs from '../../../ui/breadcrumbs';
import { getClient, getClients } from '../../../../state/client-management/async-actions';

const Client = () => {
  const client = useSelector(clientSelector);

  const dispatch = useDispatch();
  const params = useParams();


  useEffect(() => {
       _.isEmpty(client) &&
      dispatch(getClients())
        .unwrap()
        .then(() => {
          dispatch(getClient(params.id));
        });
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (_.isEmpty(client)) {
    return <Loading />;
  }

  return (
    <div className='w-full h-full pl-[30px]'>
      <Breadcrumbs
        linkTo={'/crm/clients'}
        
        firstLevel={'Advertisers'}
        secondLevel={`${client.name}`}
      />
      <div className='overflow-auto h-[calc(100%_-_96px)] flex'>
        <ClientForm client={client} />
      </div>
    </div>
  );
};

export default Client;
