import { rejectsSlice } from './slice';

export const {
  setDateRange,
  selectAdvertiser,
  selectAllAdvertisers,
  selectSource,
  selectAllSources,
  selectCountry,
  selectAllCountries,
  resetFilters,
  setPageLimit,
  setCurrentPage,
  setNextCursor,
  setPrevCursor,
  setCursor,
  resetPagination,
} = rejectsSlice.actions;
