import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import summarySelector from '../../../state/analytics/selectors/table/summary';

const Summary = ({ isShow }) => {
  const summary = useSelector(summarySelector);

  const summaryTitles = {
    Submit: 'totalSubmits',
    Leads: 'totalLeads',
    Revenue: 'totalRevenue',
    '% Reject': 'totalRejectsPercent',
    '% Success': 'totalSuccessPercent',
    UniqUsers: 'totalUniqUsers',
    '% Lead2Success': 'totalLeads2success',
    Rev2Success: 'totalRev2success',
    '% GRU': 'GRU',
  };

  return (
    <div className={classNames('flex justify-around gap-2', { hidden: !isShow })}>
      {Object.entries(summaryTitles).map(([key, value]) => (
        <div key={key} className='flex flex-col gap-3 items-center'>
          <div className='text-lg font-bold'>{key}</div>
          <div className='text-xl'>{`${key.includes('Rev') ? '$' : ''} ${summary[value]} ${
            key.includes('%') ? '%' : ''
          }`}</div>
        </div>
      ))}
    </div>
  );
};

export default Summary;
