import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

const TabSwitcher = ({ tabs, styles, parentSlug }) => {
  const { pathname } = useLocation();
  const [selectedSlug, setSelectedSlug] = useState(pathname === parentSlug ? tabs[0].slug : pathname);
  const lastTabsIndex = tabs.length - 1;
  useEffect(() => {
    setSelectedSlug(pathname === parentSlug ? tabs[0].slug : pathname);
  }, [parentSlug, pathname, tabs]);

  return (
    <div className={`relative w-full max-md:px-[10px] font-onest-regular text-black-3 dark:text-white max-md:pr-0 ${styles}`}>
      <div
        className={classNames('pl-[30px] top-0 left-0 pt-6 pb-8 max-md:pb-1 dark:bg-black-1 bg-grey-6 flex justify-between', {
          hidden: tabs.every(({ slug }) => slug !== pathname),
        })}
      >
      <div>
          {tabs &&
            !!tabs.length &&
            tabs.map(({ slug, title }, index) => (
              <Link key={slug} to={slug}>
                <button
                  key={slug}
                  onClick={() => setSelectedSlug(slug)}
                  className={classNames('text-sm px-4 py-2 border-y-2 focus:outline-none border-r-2 max-md:mr-3 max-md:rounded-none max-md:mb-2 max-md:border-l-2', {
                    'bg-red border-transparent text-white': selectedSlug === slug,
                    'bg-white dark:bg-black-1 border-grey-3 dark:border-black-3 text-black-3 dark:text-grey-3':
                      selectedSlug !== slug,
                    'border-l-2 rounded-l-xl': index === 0,
                    'rounded-r-xl': index === lastTabsIndex,
                  })}
                >
                  {title}
                </button>
              </Link>
            ))}
      </div>
      </div>
      <Outlet />
    </div>
  );
};

export default TabSwitcher;
