import React from 'react';

const LabeledSelectBox = ({ label, isRequired, children, error }) => {
  return (
    <div className='flex flex-1 flex-col gap-2 w-full relative'>
      {label && (
        <label className='text-sm text-left h-fit text-black-3 dark:text-white'>
          {label}
          {isRequired && <span className='text-red-burgundy'>*</span>}
        </label>
      )}
      {children}
      {error && (
        <div className='flex gap-2'>
          {/* <svg xmlns='http://www.w3.org/2000/svg' width='13' height='14' viewBox='0 0 13 14' fill='none'>
            <path
              d='M5.85 5.05H7.15V3.75H5.85V5.05ZM6.5 12.2C3.6335 12.2 1.3 9.8665 1.3 7C1.3 4.1335 3.6335 1.8 6.5 1.8C9.3665 1.8 11.7 4.1335 11.7 7C11.7 9.8665 9.3665 12.2 6.5 12.2ZM6.5 0.5C5.64641 0.5 4.80117 0.668127 4.01256 0.994783C3.22394 1.32144 2.50739 1.80023 1.90381 2.40381C0.684819 3.62279 0 5.27609 0 7C0 8.72391 0.684819 10.3772 1.90381 11.5962C2.50739 12.1998 3.22394 12.6786 4.01256 13.0052C4.80117 13.3319 5.64641 13.5 6.5 13.5C8.22391 13.5 9.87721 12.8152 11.0962 11.5962C12.3152 10.3772 13 8.72391 13 7C13 6.14641 12.8319 5.30117 12.5052 4.51256C12.1786 3.72394 11.6998 3.00739 11.0962 2.40381C10.4926 1.80023 9.77606 1.32144 8.98744 0.994783C8.19883 0.668127 7.35359 0.5 6.5 0.5ZM5.85 10.25H7.15V6.35H5.85V10.25Z'
              fill='#E72F3C'
            />
          </svg> */}
          <span className='text-red text-xs'>{error.message}</span>
        </div>
      )}
    </div>
  );
};

export default LabeledSelectBox;
