import { joiResolver } from '@hookform/resolvers/joi';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { setEditableClient } from '../../../../state/client-management/actions';
import InputField from '../../../ui/input';
import ActionButtonGroup from '../../../ui/button-group/ActionButtonGroup';
import moment from 'moment';
import { toggleCreatePixelModal, toggleUpdatePixelConfModal } from '../../../../state/modals/actions';
import UpdateModal from '../../../modals/UpdateModal';
import { addPixel, getPixel, getPixels, updatePixel } from '../../../../state/fb/async-actions';
import updatePixelConfModalSelector from '../../../../state/modals/selectors/update-pixel';
import { pixelSchema } from '../../../../schemas/pixelSchema';

const PixelForm = ({ pixel, action }) => {
  var fields = [
    {
      inputId: 'pixel_id',
      isRequired: false,
      label: 'Pixel ID',
      placeholder: pixel.pixel_id,
      type: 'text',
      isReadOnly: action === 'create' ? false : true,
    },
    {
      inputId: 'event_source_url',
      isRequired: false,
      label: 'Event source url',
      placeholder: pixel.event_source_url,
      type: 'text',
      isReadOnly: false,
    },
    {
      inputId: 'access_token',
      isRequired: false,
      label: 'Access token',
      placeholder: pixel.access_token,
      type: 'text',
      isReadOnly: false,
    },
  ];
  const updateConfirmation = useSelector(updatePixelConfModalSelector);

  const dispatch = useDispatch();

  const [isDataChanged, setIsDataChanged] = useState(false);
  const methods = useForm({ mode: 'all', resolver: joiResolver(pixelSchema), values: pixel });
  const {
    handleSubmit,
    getValues,
    reset,
    // eslint-disable-next-line no-unused-vars
    formState: { isValid, errors },
  } = methods;
  const values = getValues();

  useEffect(() => {
    if (_.isEqual(values, pixel)) {
      setIsDataChanged(false);
    } else {
      setIsDataChanged(true);
    }
  }, [pixel, values]);

  const submit = (data) => {
    if (action === 'create') {
      dispatch(addPixel(data))
        .unwrap()
        .then(() => {
          dispatch(toggleCreatePixelModal());
        })
        .then(() => {
          dispatch(getPixels());
        });
    } else {
      const changedValues = Object.entries(data)
        .filter(([key, value]) => !_.isEqual(value, pixel[key]))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      if (Object.keys(changedValues).length === 0) {
        // No changes, do not submit
        console.log('No changes to submit');
        return;
      }

      console.log('Submitting changes:', changedValues);
      dispatch(updatePixel(values))
        .unwrap()
        .then(() => {
          dispatch(getPixel(pixel.pixel_id));
        })
        .then(() => {
          dispatch(setEditableClient(false));
          // dispatch(getClients());
        });
    }
  };

  const handleOpenUpdateConfModal = () => {
    dispatch(toggleUpdatePixelConfModal());
  };

  const handleCreate = () => {
    submit(values);
  };

  return (
    <div className='flex flex-col'>
      <FormProvider {...methods}>
        <form className='flex flex-col gap-8 w-full relative' onSubmit={handleSubmit(submit)}>
          <h3 className='text-2xl'>Pixel info:</h3>
          <section id='client_info' className='flex flex-wrap gap-4 mr-[30px] mb-10'>
            {fields.map((field, id) => (
              <div key={id} className='w-[300px]'>
                <InputField
                  inputId={field.inputId}
                  isRequired={field.isRequired}
                  label={field.label}
                  placeholder={field.placeholder}
                  type={field.type}
                  isReadOnly={field.isReadOnly}
                />
              </div>
            ))}
          </section>
        </form>
      </FormProvider>
      {action === 'update' && (
        <div className='flex gap-6'>
          <span>
            Created time: <b>{moment.utc(pixel.created_at).format('DD.MM.YYYY')}</b>
          </span>
          <span>
            Updated time: <b>{moment.utc(pixel.updated_at).format('DD.MM.YYYY')}</b>
          </span>
        </div>
      )}
      <ActionButtonGroup
        link={{
          handleClick: action === 'update' ? reset : () => dispatch(toggleCreatePixelModal()),
          isDisabled: action === 'update' ? !isDataChanged : false,
        }}
        button={{
          isDisabled: !isDataChanged,
          label: action === 'update' ? 'Update' : 'Create',
          handleClick: action === 'create' ? handleCreate : handleOpenUpdateConfModal,
        }}
        styles={'justify-end sticky bottom-0 z-50  max-md:justify-start mr-5'}
      />

      <UpdateModal
        title={'Update pixel'}
        description={"Are you sure you want to update this pixel? If you update it, you can't get it back."}
        onUpdate={() => dispatch(toggleUpdatePixelConfModal()) && submit(values)}
        onClose={handleOpenUpdateConfModal}
        deleteConfirmation={updateConfirmation}
      />
    </div>
  );
};

export default PixelForm;
