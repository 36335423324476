import { modalsSlice } from './slice';

export const {
  toggleAddBlockModal,
  toggleSourceConfigModal,
  toggleAddLangModal,
  toggleCreateBlockModal,
  toggleShowAdvertisersModal,
  toggleDeleteBlockConfModal,
  toggleUpdateClientConfModal,
  toggleUpdateAppConfModal,
  toggleUpdateLimitModal,
  toggleAddLimitModal,
  toggleDeleteAppConfModal,
  resetModals,
  toggleDeleteMsgConfModal,
  toggleUpdateMessageConfModal,
  toggleMessageImageModal,
  toggleCreateMessageModal,
  toggleUpdatePixelConfModal,
  toggleDeletePixelConfModal,
  toggleCreatePixelModal,
} = modalsSlice.actions;
