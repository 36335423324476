import React from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../../../state/analytics/actions';

const ApplyButton = ({ applyFunc, isFirstPage, isPaginate }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (isPaginate) {
      dispatch(setCurrentPage(1));

      if (isFirstPage) {
        applyFunc();
      }
    } else {
      applyFunc();
    }
  };
  return (
    <button
      onClick={() => handleClick()}
      className='bg-main-red-2 border-main-red-2 text-main-white flex items-center gap-[6px] border py-2 px-4 rounded-md transition-all duration-500'
    >
      Apply
    </button>
  );
};

export default ApplyButton;
