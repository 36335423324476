import { useDispatch, useSelector } from "react-redux";
import pixelsErrorSelector from "../../../state/fb/selectors/pixels/error";
import pixelsLoadingSelector from "../../../state/fb/selectors/pixels/loading";
import pixelsSelector from "../../../state/fb/selectors/pixels/pixels";
import React from "react";
import Page401 from "../../error-pages/Page401";
import Page403 from "../../error-pages/Page403";
import Loading from "../../global/Loading";
import classNames from "classnames";
import TableWrapper from "../../ui/table/TableWrapper";
import { PIXELS_TABLE_HEADERS } from "../../../constants/pixels";
import { deletePixel, getPixels } from "../../../state/fb/async-actions";
import PixelsTableBody from "./TableBody";
import TablePixelsHeader from "../../ui/table/PixelsHeader";
import DeleteModal from "../../modals/DeleteModal";
import { toggleCreatePixelModal, toggleDeletePixelConfModal } from "../../../state/modals/actions";
import deletePixelConfModalSelector from "../../../state/modals/selectors/delete-pixel";
import CreateButton from "../../global/filters/CreateButton";
import CreatePixelModal from "../../modals/CreatePixelModal";

const Pixels = () => {
  const error = useSelector(pixelsErrorSelector);
  const loading = useSelector(pixelsLoadingSelector);
  const deleteConfirmation = useSelector(deletePixelConfModalSelector);
  const pixels = useSelector(pixelsSelector);
  
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deletePixel())
      .unwrap()
      .then(() => {
        dispatch(toggleDeletePixelConfModal());
      });
  };

  React.useEffect(() => {
    dispatch(getPixels())
  }, [dispatch])
  

  if (error?.status === 401) {
    return <Page401 service='fb' error={error} />;
  }
  if (error?.status === 403) {
    return <Page403 />;
  }
  if (error) {
    return <h2 className='text-black-1 dark:text-white text-3xl pt-10 text-center'>{error.message || error}</h2>;
  }
  if (loading) {
    return <Loading />;
  }

  const createFunc = () => {
    dispatch(toggleCreatePixelModal())
  }

  return (
    <>
      <div
        className={classNames('overflow-auto h-[calc(100%_-_96px)]', {
          'pt-52': !pixels?.length,
        })}
      >
        <div className="mb-3 mx-5 text-end"><CreateButton createFunc={createFunc}/></div>
        {pixels &&  (
          <TableWrapper>
            <TablePixelsHeader headers={PIXELS_TABLE_HEADERS} topStyle={0} />
            <PixelsTableBody />
          </TableWrapper>
        )}
          <DeleteModal
        title={'Delete pixel'}
        description={"Are you sure you want to delete this pixel? If you delete it, you can't get it back."}
        onDelete={handleDelete}
        onClose={() => {
          dispatch(toggleDeletePixelConfModal());
        }}
        deleteConfirmation={deleteConfirmation}
      />
      <CreatePixelModal />
      </div>
    </>
  );
};

export default Pixels;