import React from 'react';
import { Controller } from 'react-hook-form';
import LabeledSelectBox from '../../../../ui/select-box/labeled';
import SelectBox from '../../../../ui/select-box';

const StatusSel = ({ control }) => {
  var statuses = [
    {
      value:"active",
      name: "Active"
    },
    {
      value:"not-active",
      name: "Not active"
    },
   ]


  return (
      <div className='w-[300px]'>
      <Controller
          name='status'
          control={control}
          render={({ field: { value, name, onChange }, fieldState: { error } }) => (
            <LabeledSelectBox label={'Status'} isRequired={true} error={error}>
              <SelectBox
                name={name}
                placeholder={'Select a status'}
                selectedValue={value}
                options={statuses}
                label={'name'}
                onSelectChange={onChange}
                secondary={true}
              />
            </LabeledSelectBox>
          )}
        />
      </div>
  );
};

export default StatusSel;
