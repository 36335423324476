import React, { useEffect } from 'react';
import TabSwitcher from '../TabSwitcher';
import ROUTES from '../../constants/routes';
import { useDispatch } from 'react-redux';
import { getFilters } from '../../state/analytics/async-actions';

const parentRoute = ROUTES.ANALYTICS.submenu[0].route;
const TABS = [
  { slug: `${parentRoute}/daily`, title: 'Daily' },
  { slug: `${parentRoute}/advertisers`, title: 'Advertiser' },
  { slug: `${parentRoute}/source`, title: 'Source' },
  { slug: `${parentRoute}/country`, title: 'Country' },
  { slug: `${parentRoute}/landing`, title: 'Landing' },
];

const Analytics = () => {
   const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFilters());
  }, [dispatch]);

  return (
    <section className='relative flex flex-col w-full overflow-x-auto'>
      <TabSwitcher tabs={TABS} styles={'h-full'} parentSlug={parentRoute} />
    </section>
  );
};

export default Analytics;
