import { useDispatch, useSelector } from 'react-redux';
import UserMenu from '../../menus/UserMenu';
import themeSelector from '../../../../state/menus/selectors/theme';
import { changeTheme } from '../../../../state/menus/actions';
import { SunIcon } from '../../../../assets/icons/header/header-icons';

const ControlPanel = () => {
  const theme = useSelector(themeSelector);
  const dispatch = useDispatch();

  return (
    <div className='flex gap-3 ml-4'>
      <div className='flex justify-center items-center w-10'>
        <button
          className='flex items-center justify-center rounded-full dark:bg-white bg-black-3 w-6 h-6'
          onClick={() => dispatch(changeTheme())}
        >
          <SunIcon stroke={theme === 'dark' ? 'black' : 'white'} />
        </button>
      </div>
      <UserMenu />
    </div>
  );
};

export default ControlPanel;
