import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FiltersButton from '../../../assets/icons/FilterButton.svg';
import FiltersButtonBlack from '../../../assets/icons/FiltersButtonBlack.svg';
import { THEMES } from '../../../constants/themes';

import themeSelector from '../../../state/menus/selectors/theme';
import SearchBar from '../../ui/search-bar';

const CommonFilter = ({ name, items, selectedItems, selectFunc, selectAllFunc }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [active, setActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const theme = useSelector(themeSelector);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (active && ref.current && !ref.current.contains(e.target)) {
        setActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [active, setActive]);

  const handleChange = (e) => {
    dispatch(selectFunc(e.target.id));
  };

  const handleSelectAll = (e) => {
    if (filteredItems.length) {
      dispatch(selectAllFunc(e.target.checked ? filteredItems : []));
    } else {
      dispatch(selectAllFunc(e.target.checked ? items : []));
      setFilteredItems(items);
    }
  };

  const handleSearchValueChange = (value) => {
    if (value === '') {
      setFilteredItems([]);
      dispatch(selectAllFunc([]));
      setSearchValue(value.toLowerCase());
      return;
    }
    setSearchValue(value.toLowerCase());
    setFilteredItems(
      items.filter((i) =>
        name === 'Advertisers' ? i.name.toLowerCase().includes(value) : i.toLowerCase().includes(value)
      )
    );
  };

  const isChecked = () => {
    if (selectedItems.length > 0 && selectedItems.length !== filteredItems.length) {
      return false;
    }
    return !selectedItems.length ? selectedItems.length === items.length : true;
  };
  
  return (
    <div className='relative' ref={ref}>
      {selectedItems.length > 0 && (
        <p className='absolute top-[-25px] right-[-10px] rounded-full bg-main-red w-7 h-7 flex items-center justify-center text-xs'>
          {selectedItems.length}
        </p>
      )}
      <button
        onClick={() => setActive(!active)}
        className={classNames('flex items-center gap-[6px] border py-2 px-3 rounded-md', {
          'bg-main-red-2 border-main-red-2 text-main-white': active,
          'bg-transparent border-main-grey-2 dark:border-main-black-2 dark:text-main-grey-2': !active,
        })}
      >
        <img
          src={theme === THEMES.light ? (active ? FiltersButton : FiltersButtonBlack) : FiltersButton}
          alt='Filters Button'
        />
        {name}
      </button>
      {active && (
        <form className='absolute mt-1 left-0 z-20 px-7 py-7 flex flex-col min-w-[400px] max-h-[500px] overflow-y-auto bg-main-white dark:bg-[#2D2D2D] dark:text-main-white rounded-md shadow-[0_0_6px_rgba(0,0,0,0.07),0_0.08px_4px_rgba(38,28,21,0.12)]'>
          <div className='flex gap-3 pb-3 justify-between'>
            <div className='flex gap-3 items-center'>
              <input
                type='checkbox'
                onChange={(e) => handleSelectAll(e)}
                checked={isChecked()}
                id={name}
                className='w-4 h-4 dark:bg-main-black checked:bg-main-red dark:checked:bg-main-red hover:checked:bg-main-red focus:checked:bg-main-red border border-main-black-2 rounded cursor-pointer focus:ring-offset-0 focus:ring-0'
              />
              <label htmlFor={name} className='cursor-pointer'>
                All {name}
              </label>
            </div>
            <SearchBar
              label={name}
              options={items}
              placeholder={`Search`}
              isDisabled={!items.length || items.length === 1}
              onChange={handleSearchValueChange}
              selectedVal={selectedItems}
              value={searchValue}
            />
          </div>
          <div className='w-full border-t border-main-grey-3 dark:border-main-black-2 pt-2'>
            <h5 className='text-main-grey-1 text-lg mb-2'>{name}</h5>
            <ul className='columns-3 gap-5'>
              {items
                .filter((el) =>
                  name === 'Advertisers'
                    ? el.name.toLowerCase().includes(searchValue)
                    : el.toLowerCase().includes(searchValue)
                )
                .map((item) => (
                  <li key={name === 'Advertisers' ? item.id : item} className='flex gap-3 items-center pb-3'>
                    <input
                      onChange={(e) => handleChange(e)}
                      type='checkbox'
                      checked={!!selectedItems.find((el) => el === (name === 'Advertisers' ? item.id : item))}
                      id={name === 'Advertisers' ? item.id : item}
                      className='w-4 h-4 self-start relative -bottom-1 dark:bg-main-black checked:bg-main-red dark:checked:bg-main-red hover:checked:bg-main-red focus:checked:bg-main-red border border-main-black-2 rounded cursor-pointer focus:ring-offset-0 focus:ring-0'
                    />
                    <label htmlFor={name === 'Advertisers' ? item.id : item} className='cursor-pointer'>
                      {name === 'Advertisers' ? item.name : item}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
          <div className='flex items-center justify-end pt-2'>
            <div className='flex items-center gap-5'>
              <button
                onClick={() => setActive(false)}
                className='border rounded-md px-4 py-2 border-main-grey-2 dark:border-main-black-2'
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default CommonFilter;
