import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TdWrapper from '../../ui/table/TdWrapper';
import pixelsSelector from '../../../state/fb/selectors/pixels/pixels';
import { getPixels } from '../../../state/fb/async-actions';
import DeleteButton from '../../ui/buttons/actions/Delete';
import { toggleDeletePixelConfModal } from '../../../state/modals/actions';
import { setPixelToDelete } from '../../../state/fb/actions';

const PixelsTableBody = () => {
  const pixels = useSelector(pixelsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <tbody>
      {pixels &&
        !!pixels.length &&
        pixels.map(
          ({
            id,
            pixel_id,
            event_source_url,
          }) => (
            <tr
              id={id}
              key={id}
              className='h-[72px] border-b border-grey-3 dark:border-black-3 hover:bg-grey-3 dark:hover:bg-black-3 transition duration-300 ease-in-out'
            >
              <TdWrapper>
                <span
                  onClick={() => {
                    dispatch(getPixels(pixel_id))
                      .unwrap()
                      .then(() => navigate(`/fb/pixels/${pixel_id}`));
                  }}
                  className='inline-flex w-full h-full text-blue-dark dark:text-blue-light cursor-pointer whitespace-break-spaces'
                >
                  {pixel_id}
                </span>
              </TdWrapper>
              <TdWrapper>{event_source_url}</TdWrapper>
              <TdWrapper>
              <div className='flex gap-3'>
                <DeleteButton handleDelete={() => dispatch(toggleDeletePixelConfModal()) && dispatch(setPixelToDelete(id))} />
              </div>
            </TdWrapper>
            </tr>
          )
        )}
    </tbody>
  );
};

export default PixelsTableBody;
