import Joi from 'joi';

export const pixelSchema = Joi.object({
  pixel_id: Joi.string().min(2).max(100).trim().messages({
    'string.empty': 'The "Pixel id" field is required',
    'string.base': 'The "Pixel id" field should contain only letters and digits',
    'string.min': 'The "Pixel id" field should have at least {#limit} characters',
    'string.max': 'The "Pixel id" field should have at most {#limit} characters',
  }),
  event_source_url: Joi.string().min(2).max(200).trim().messages({
    'number.base': 'The "Event source url" field should be a number',
    'number.integer': 'The "Event source url" field should be an integer',
    'number.min': 'The "Event source url" field should be at least {#limit}',
    'number.max': 'The "Event source url" field should be at most {#limit}',
  }),
  access_token: Joi.string().min(2).max(999).trim().messages({
    'number.base': 'Access token" field should be a number',
    'number.integer': 'Access token" field should be an integer',
    'number.min': 'Access token" field should be at least {#limit}',
    'number.max': 'Access token" field should be at most {#limit}',
  }),
});
