import React from 'react';
import { Switch } from '@headlessui/react';

const Toggle = ({ handleChange, values, currentValue }) => {
  return (
    <Switch.Group>
      <div className='flex items-center justify-center'>
        <Switch.Label className='mr-4'>
          <p>{currentValue && currentValue.charAt(0).toUpperCase() + currentValue.slice(1)}</p>
        </Switch.Label>
        <Switch
          checked={values[currentValue]}
          onChange={handleChange}
          className={`${
            values[currentValue] ? 'bg-black-3' : 'bg-gray-200'
          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors`}
        >
          <span
            className={`${
              values[currentValue] ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-red transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
};

export default Toggle;
