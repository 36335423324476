import React from 'react';
import Error401Dark from '../../assets/errors/Error401Dark.svg';
import Error401White from '../../assets/errors/Error401White.svg';
import { useDispatch, useSelector } from 'react-redux';
import themeSelector from '../../state/menus/selectors/theme';
import { THEMES } from '../../constants/themes';
import { Link } from 'react-router-dom';
import sidebarSelector from '../../state/menus/selectors/sidebar';
import classNames from 'classnames';

const Page401 = ({ service, error }) => {
  const dispatch = useDispatch();
  const theme = useSelector(themeSelector);
  const sidebar = useSelector(sidebarSelector);
  const errorMsg = error.message.replace(/^\S+\s/, '');
  return (
    <div
      className={classNames('flex flex-col justify-center w-full items-center 2xl:mx-auto gap-7', {
        'lg:gap-[5%] 2xl:gap-[1.5%]': sidebar,
        'lg:gap-[1.5%]': !sidebar,
      })}
    >
      <img
        className={classNames('md:w-[300px] md:h-[300px] h-[250px] w-[250px] transition-all duration-500', {
          'lg:w-[300px] lg:h-[300px] xl:w-[400px] xl:h-[400px] 2xl:w-[460px] 2xl:h-[460px]': sidebar,
          'lg:w-[350px] lg:h-[350px] xl:w-[460px] xl:h-[460px] 2xl:w-[460px] 2xl:h-[460px]': !sidebar,
        })}
        alt='Error Robot'
        src={theme === THEMES.light ? Error401White : Error401Dark}
      />
      <div className='dark:text-main-white xl:max-w-md max-w-sm transition-all duration-500'>
        {service === 'dashboard' ? (
          <>
            <h1 className='text-[34px] lg:leading-snug leading-tight tracking-widest font-semibold lg:mb-7 mb-5'>
              Oops! Your key is incorrect.
            </h1>
            <p className='text-base lg:mb-7 mb-5'>Please double-check was copied correctly and try again.</p>
            <div className='flex flex-col gap-2 xl:flex-row text-sm text-center'>
            </div>
          </>
        ) : (
          <h1 className='text-[34px] lg:leading-snug leading-tight tracking-widest font-semibold lg:mb-7 mb-5'>
            {errorMsg}
          </h1>
        )}
      </div>
      <Link
        to='#'
        className='flex-auto px-5 py-2.5 tracking-wide text-red hover:text-red-burgundy transition-colors duration-200 rounded-md focus:outline-none active:font-bold'
      >
        Visit to our help center
      </Link>
    </div>
  );
};

export default Page401;
