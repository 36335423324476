import React from 'react';
import PageWrapper from '../components/wrappers/PageWrapper';
import CRM from '../components/crm/CRM';

const CRMPage = () => {
  return (
    <PageWrapper>
      <CRM />
    </PageWrapper>
  );
};

export default CRMPage;
