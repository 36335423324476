import { useDispatch } from 'react-redux';
import TdWrapper from '../../ui/table/TdWrapper';
import { toggleShowAdvertisersModal, toggleSourceConfigModal } from '../../../state/modals/actions';
import { setAdvertisers, setSelectedSourceWithPriority } from '../../../state/client-management/actions';
import { useState } from 'react';
import ConfigButton from '../../ui/buttons/actions/Config';
import { getClients } from '../../../state/client-management/async-actions';

const BlocksTableBody = ({ sources }) => {
  const dispatch = useDispatch();
  const [showSubRow, setShowSubRow] = useState([]);

  const onClickShowRow = (row) => {
    if (showSubRow.includes(row)) {
      setShowSubRow(showSubRow.filter((el) => el !== row));
    } else {
      setShowSubRow([...showSubRow, row]);
    }
  };

  return (
    <tbody>
      {sources &&
        !!sources.length &&
        sources.map((source, id) => (
          <>
            <tr
              id={id + 1}
              key={id}
              className={`h-[72px] border-b border-grey-3 dark:border-black-3 hover:bg-grey-3 dark:hover:bg-black-3 transition duration-300 ease-in-out ${
                source.sub_source && 'bg-gray-500'
              }`}
            >
              <TdWrapper>{id + 1}</TdWrapper>
              <TdWrapper handleClick={() => onClickShowRow(id)} styles={source.sub_source ? 'cursor-pointer' : ''}>
                {source.name}
              </TdWrapper>
              <TdWrapper></TdWrapper>
              <TdWrapper styles={`max-md:hidden ${source.status === 'not-active' && '!text-gray-700'}`}>
                {source.advertisers_priority
                  ?.map((ad) => ad.client_name)
                  .slice('')
                  .join(', ')}
              </TdWrapper>
              <TdWrapper styles={'text-center'}>
                <ConfigButton
                  handleConfig={() => dispatch(toggleSourceConfigModal()) && dispatch(setSelectedSourceWithPriority(source)) && dispatch(getClients())}
                />
              </TdWrapper>
            </tr>
            {source.sub_source?.map((subSource, idx) => (
              <tr
                key={idx}
                className={
                  showSubRow?.includes(id)
                    ? 'border-b border-grey-3 dark:border-black-3 hover:bg-grey-3 dark:hover:bg-black-3 transition duration-300 ease-in-out'
                    : 'hidden'
                }
              >
                <TdWrapper></TdWrapper>
                <TdWrapper styles={'pl-10'}>{subSource.value}</TdWrapper>
                <TdWrapper
                  handleClick={() =>
                    dispatch(toggleShowAdvertisersModal()) && dispatch(setAdvertisers(subSource.clients))
                  }
                  styles={'cursor-pointer'}
                >
                  {subSource.clients?.slice().join(', ')}
                </TdWrapper>
                <TdWrapper styles={'max-md:hidden'}></TdWrapper>
                <TdWrapper styles={'text-center'}>
                </TdWrapper>
              </tr>
            ))}
          </>
        ))}
    </tbody>
  );
};

export default BlocksTableBody;
