import { createSlice } from '@reduxjs/toolkit';
import {
  addApp,
  addLangs,
  addMessage,
  deleteApp,
  deleteLang,
  deleteMessage,
  getApp,
  getApps,
  getImages,
  getMessage,
  getMessages,
  updateApp,
  updateMessage,
} from './async-actions';
import { getKeyByValue } from '../../constants/languages';

const initialState = {
  apps: {
    apps: [],
    app: {},
    toDelete: null,
  },
  messages: {
    messages: [],
    message: {},
    images: [],
    imageType: '',
    selectedLang: '',
    toDelete: null,
    pageInfo: {
      totalElements: null,
      totalPages: null,
      pageSize: localStorage.getItem('messagesPageLimit') || '',
      currentPage: 1,
    },
    filters: {
      selectedCategories: [],
      selectedID: '',
    },
  },
  isLoading: false,
  isEditable: false,
  error: '',
};

export const pushSlice = createSlice({
  name: 'push',
  initialState,
  reducers: {
    setImageType: (state, { payload }) => {
      state.messages.imageType = payload;
    },
    setAppToDelete: (state, { payload }) => {
      state.apps.toDelete = payload;
    },
    setMsgToDelete: (state, { payload }) => {
      state.messages.toDelete = payload;
    },
    resetFilters: (state) => {
      state.messages.filters.selectedCategories = [];
      // state.messages.pageInfo.currentPage = state.messages.pageInfo.currentPage === 1 ? state.messages.pageInfo.currentPage : 1;
    },
    selectCategory: (state, { payload }) => {
      if (state.messages.filters.selectedCategories.includes(payload)) {
        state.messages.filters.selectedCategories = state.messages.filters.selectedCategories.filter(
          (country) => country !== payload
        );
      } else {
        state.messages.filters.selectedCategories.push(payload);
      }
    },
    selectMessageID: (state, { payload }) => {
      state.messages.filters.selectedID = payload;
    },
    selectAllCategories: (state, { payload }) => {
      state.messages.filters.selectedCategories = payload ? payload : [];
    },
    setCurrentPage: (state, { payload }) => {
      state.messages.pageInfo.currentPage = payload;
    },
    setSelectedLang: (state, { payload }) => {
      state.messages.selectedLang = payload;
    },
    setPageLimit: (state, { payload }) => {
      state.messages.pageInfo.pageSize = payload;
      localStorage.setItem('messagesPageLimit', payload);
      state.messages.pageInfo.currentPage = 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApps.fulfilled, (state, { payload }) => {
        state.apps.apps = payload ? payload : [];
        state.isLoading = false;
      })
      .addCase(getApps.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getApps.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(getImages.fulfilled, (state, { payload }) => {
        state.messages.images = payload ? payload : []
        state.isLoading = false;
      })
      .addCase(getImages.pending, (state) => {
        state.error = null;
      })
      .addCase(getImages.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(getApp.fulfilled, (state, { payload }) => {
        state.apps.app = payload ? payload : {};
        state.isLoading = false;
      })
      .addCase(getApp.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getApp.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(deleteApp.fulfilled, (state) => {
        state.apps.apps = state.apps.apps.filter(({ id }) => id !== state.apps.toDelete);
        state.isLoading = false;
      })
      .addCase(deleteApp.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteApp.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
      .addCase(updateApp.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateApp.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateApp.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(addApp.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addApp.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addApp.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(addLangs.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addLangs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addLangs.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(getMessages.fulfilled, (state, { payload }) => {
        state.messages.messages = payload.messages ? payload.messages.sort((a,b) => b.stats - a.stats) : [];
        state.messages.pageInfo.totalPages = payload.last_page;
        state.messages.pageInfo.totalElements = payload.total_counts;
        state.isLoading = false;
      })
      .addCase(getMessages.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getMessages.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(deleteMessage.fulfilled, (state) => {
        state.messages.messages = state.messages.messages.filter(({ id }) => id !== state.messages.toDelete);
        state.isLoading = false;
      })
      .addCase(deleteMessage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteMessage.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
      .addCase(deleteLang.fulfilled, (state) => {
        state.messages.message.langs = state.messages.message.langs.filter(
          (item) => item !== state.messages.selectedLang
        );
        state.isLoading = false;
      })
      .addCase(deleteLang.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteLang.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
      .addCase(getMessage.fulfilled, (state, { payload }) => {
        state.messages.message = payload;
        state.messages.message.langs = payload.heading.map((obj) => getKeyByValue(obj.lang));
        state.messages.message.heading = payload.heading.find((obj) => obj.lang === 'en').text;
        state.messages.message.content = payload.content.find((obj) => obj.lang === 'en').text;
        state.isLoading = false;
      })
      .addCase(getMessage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getMessage.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
      .addCase(updateMessage.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateMessage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateMessage.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(addMessage.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addMessage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addMessage.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
});

const pushReducer = pushSlice.reducer;

export default pushReducer;
