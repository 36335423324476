import classNames from 'classnames';
import React from 'react';
//////variant icon and general

const SecondaryButton = ({ variant, type, children, disabled, handleClick }) => {
  return (
    <button
      type={type}
      onClick={handleClick}
      disabled={disabled}
      className={classNames(
        'py-2 px-2 group rounded-md border border-grey-2 dark:border-black-3 text-black-3 dark:text-grey-2 focus:outline-none font-onest-medium text-sm text-center disabled:bg-grey-3 dark:disabled:bg-grey-1 disabled:text-grey-1 dark:disabled:text-black-3 disabled:border-none',
        {
          'hover:border-red dark:hover:border-red dark:hover:text-grey-4 active:border-red-burgundy active:text-black-1 dark:active:text-white dark:active:border-red-burgundy':
            variant === 'general',
          'w-10 h-10 flex justify-center items-center hover:border-none hover:bg-red active:bg-red-burgundy':
            variant === 'icon',
        }
      )}
    >
      {children}
    </button>
  );
};

export default SecondaryButton;
