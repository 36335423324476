import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';
import DropdownList from '../dropdown-list';
import classNames from 'classnames';
import { LinedExpandArrowIcon, RoundedCloseIcon } from '../../../assets/icons';
import { generateShortForm } from '../../../helpers/index';
import { useFormContext } from 'react-hook-form';

const MultiSelectSearch = ({
  label,
  id,
  isRequired,
  isReadOnly,
  isDisabled,
  placeholder,
  options,
  itemLabel,
  onChange,
  values,
  isShortForm,
  isSelectAll
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { watch } = useFormContext() || {};
  watch && watch(id);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  const onClickWrapper = () => {
    !isDisabled && !isReadOnly && setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (value) => {
    console.log(value);
    console.log(options[value.id]);
    if (values.find(({ id }) => id === value.id)) {
      onChange(values.filter((item) => item.id !== value.id));
    } else if (value.length === values.length){
      onChange([])
    } else if (value.length > 1) {
      onChange([...value]);
    } else {
      onChange([...values, value]);
    }
  };

  const handleChipRemove = (value) => {
    onChange(values.filter((item) => item.id !== value.id));
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  return (
    <div className='flex flex-1 flex-col gap-2'>
      {label && (
        <label htmlFor={id} className='text-sm h-fit text-black-3 dark:text-white'>
          {label}
          {isRequired && <span className='text-red-burgundy'>*</span>}
        </label>
      )}
      <div
        ref={dropdownRef}
        className={classNames(
          'max-h-40 overflow-y-auto w-full font-onest-regular text-sm flex items-center justify-between pl-1 pr-2 py-2 rounded-md border border-grey-2 dark:border-black-3 hover:border-black-3 dark:hover:border-grey-4 disabled:cursor-not-allowed cursor-pointer',
          {
            'bg-grey-3 dark:bg-black-3 dark:border-transparent': isOpen,
            'dark:border-black-3': !values.length,
            'dark:border-white': values.length && !isReadOnly && !isDisabled,
            'bg-grey-4 dark:border-grey-0 dark:bg-black-3 dark:text-grey-3 cursor-auto': isReadOnly || isDisabled,
            'bg-grey-3 dark:bg-grey-1 text-grey-1 dark:text-black-3 border-none cursor-default':
              isDisabled && !isReadOnly,
          }
        )}
        onClick={onClickWrapper}
      >
        <div className='w-[268px] flex flex-wrap gap-1'>
          {!values.length && (
            <input
              type='text'
              value={searchValue}
              onChange={(e) => handleSearch(e)}
              placeholder={placeholder}
              className='w-[268px] h-5 text-sm whitespace-nowrap text-grey-2 dark:text-grey-1 focus:outline-none focus:border-none bg-inherit outline-none border-none focus:ring-0 focus:ring-opacity-0'
              disabled={isDisabled || isReadOnly}
            />
          )}
          {!!values.length &&
            values.map((item) => (
              <div
                key={item.id}
                onClick={(e) => e.stopPropagation()}
                className={classNames('px-2 py-0.5 rounded-md flex items-center gap-2', {
                  'bg-grey-3 dark:bg-black-3': !isOpen,
                  'bg-grey-4 dark:bg-black-2': isOpen,
                })}
              >
                <span>{isShortForm ? generateShortForm(item.name) : item.name}</span>
                {!isDisabled && !isReadOnly && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChipRemove(item);
                    }}
                    className='ml-2'
                  >
                    <XMarkIcon className='h-4 w-4 text-grey-1' />
                  </button>
                )}
              </div>
            ))}
        </div>
        {!!values.length && !isDisabled && !isReadOnly && (
          <button
            type='button'
            onClick={() => {
              onChange([]);
            }}
            className='flex justify-start items-start h-fit'
          >
            <RoundedCloseIcon className='fill-grey-1' />
          </button>
        )}
        {!isDisabled && !isReadOnly && (
          <div className='flex justify-center items-center'>
            <LinedExpandArrowIcon
              className={classNames('fill-grey-1', { 'dark:fill-white rotate-180': isOpen, invisible: isDisabled })}
            />
          </div>
        )}

        {!isDisabled && (
          <DropdownList
            isOpen={isOpen}
            label={itemLabel}
            options={options.filter((item) => item.name.toLowerCase().startsWith(searchValue))}
            selectOption={handleCheckboxChange}
            selectedVal={values}
            checkboxes={true}
            isSelectAll={isSelectAll}
          />
        )}
      </div>
    </div>
  );
};

export default MultiSelectSearch;
