import React from 'react';
import { useDispatch } from 'react-redux';
import { selectUserEmail } from '../../../state/analytics/actions';

const UserEmailInput = () => {
  const dispatch = useDispatch();
  const onChangeInput = (e) => {
    dispatch(selectUserEmail(e.target.value.trim()));
  };
  return (
    <div>
      <input
        className='w-full p-3 h-10 text-sm bg-white dark:bg-black-1 focus:bg-transparent rounded-md font-onest-regular border border-grey-2 placeholder-grey-2 dark:placeholder-grey-1 hover:border-black-3 focus:border-black-3 dark:hover:border-grey-4 dark:focus:border-grey-4 read-only:bg-grey-4 dark:read-only:border-grey-0 dark:read-only:bg-black-3 dark:read-only:text-grey-3 disabled:bg-grey-3 disabled:text-grey-2 disabled:border-none dark:disabled:border-none dark:disabled:bg-grey-1 dark:disabled:text-black-3 focus:outline-0 focus:ring-0'
        type='text'
        placeholder='User email'
        onChange={onChangeInput}
      />
    </div>
  );
};

export default UserEmailInput;
