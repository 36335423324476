import { joiResolver } from '@hookform/resolvers/joi';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getClient, getClients, updateClient } from '../../../../state/client-management/async-actions';
import { clientSchema } from '../../../../schemas/clientSchema';
import { setEditableClient } from '../../../../state/client-management/actions';
import InputField from '../../../ui/input';
import BlocksSection from './form-sections/blocks';
import LimitsSection from './form-sections/limits';
import ActionButtonGroup from '../../../ui/button-group/ActionButtonGroup';
import moment from 'moment';
import updateClientConfModalSelector from '../../../../state/modals/selectors/update-client';
import { toggleUpdateClientConfModal } from '../../../../state/modals/actions';
import UpdateModal from '../../../modals/UpdateModal';
import StatusSel from './form-sections/status-sel';
import Checkbox from '../../../ui/checkbox';
import FormCheckbox from '../../../ui/form-checkbox';

const ClientForm = ({ client }) => {
  var fields = [
    {
      inputId: 'name',
      isRequired: false,
      label: 'Client name',
      placeholder: client.name,
      type: 'text',
      isReadOnly: true,
    },
    {
      inputId: 'priority',
      isRequired: false,
      label: 'Priority',
      placeholder: client.priority,
      type: 'text',
      isReadOnly: false,
    },
    {
      inputId: 'payment_model',
      isRequired: false,
      label: 'Payment model',
      placeholder: client.payment_model,
      type: 'text',
      isReadOnly: false,
    },
    {
      inputId: 'payout',
      isRequired: false,
      label: 'Payout',
      placeholder: client.payout,
      type: 'text',
      isReadOnly: false,
    },
    {
      inputId: 'api_key',
      isRequired: false,
      label: 'Api key',
      placeholder: client.api_key,
      type: 'text',
      isReadOnly: false,
    },
    {
      inputId: 'request_address',
      isRequired: false,
      label: 'Request address',
      placeholder: client.request_address,
      type: 'text',
      isReadOnly: false,
    },
    {
      inputId: 'http_method',
      isRequired: false,
      label: 'Http method',
      placeholder: client.http_method,
      type: 'text',
      isReadOnly: true,
    },
    {
      inputId: 'partner_id',
      isRequired: false,
      label: 'Partner_id',
      placeholder: client.partner_id,
      type: 'text',
      isReadOnly: true,
    },
  ];
  const clientWithoutId = _.omit(client, 'id');
  const deleteConfirmation = useSelector(updateClientConfModalSelector);

  const dispatch = useDispatch();

  const [isDataChanged, setIsDataChanged] = useState(false);
  const methods = useForm({ mode: 'all', resolver: joiResolver(clientSchema), values: clientWithoutId });
  const {
    handleSubmit,
    getValues,
    reset,
    // eslint-disable-next-line no-unused-vars
    formState: { isValid, errors },
  } = methods;
  const values = getValues();

  const omitEmptyFields = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (obj[key] !== '') {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  useEffect(() => {
    if (_.isEqual(omitEmptyFields(values), omitEmptyFields(clientWithoutId))) {
      setIsDataChanged(false);
    } else {
      setIsDataChanged(true);
    }
  }, [clientWithoutId, values]);

  const submit = (data) => {
    const changedValues = Object.entries(omitEmptyFields(data))
      .filter(([key, value]) => !_.isEqual(value, clientWithoutId[key]))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    if (Object.keys(changedValues).length === 0) {
      // No changes, do not submit
      console.log('No changes to submit');
      return;
    }

    console.log('Submitting changes:', changedValues);
    dispatch(updateClient(changedValues))
      .unwrap()
      .then(() => {
        dispatch(getClient(client.id));
      })
      .then(() => {
        dispatch(setEditableClient(false));
        // dispatch(getClients());
      });
  };

  const handleOpenConfModal = () => {
    dispatch(toggleUpdateClientConfModal());
  };
  return (
    <div className='flex flex-col'>
      <FormProvider {...methods}>
        <form className='flex flex-col gap-8 w-full relative' onSubmit={handleSubmit(submit)}>
          <h3 className='text-2xl'>Advertisers info:</h3>
          <section id='client_info' className='flex flex-wrap gap-4 mr-[30px]'>
            {fields.map((field, id) => (
              <div key={id} className='w-[300px]'>
                <InputField
                  inputId={field.inputId}
                  isRequired={field.isRequired}
                  label={field.label}
                  placeholder={field.placeholder}
                  type={field.type}
                  isReadOnly={field.isReadOnly}
                />
              </div>
            ))}
            <StatusSel />
            {client.send_subsource !== null && (
              <FormCheckbox styles={'h-[40px] self-end'} id={'send_subsource'} label={'Send sub-source'} />
            )}
          </section>
        </form>
      </FormProvider>
      <div className='flex gap-[200px] max-xl:flex-col max-xl:gap-[50px] my-10'>
        <BlocksSection blocks={client.s1_blocks} />
        <LimitsSection limits={client.limits} />
      </div>
      <div className='flex gap-6'>
        <span>
          Created time: <b>{moment.utc(client.created_at).format('DD.MM.YYYY')}</b>
        </span>
        <span>
          Updated time: <b>{moment.utc(client.updated_at).format('DD.MM.YYYY')}</b>
        </span>
      </div>
      <ActionButtonGroup
        link={{ handleClick: reset, isDisabled: !isDataChanged }}
        button={{ isDisabled: !isDataChanged, label: 'Update', handleClick: handleOpenConfModal }}
        styles={'justify-end sticky bottom-0 z-50 dark:bg-black-1 bg-grey-6 max-md:justify-start mr-5'}
      />
      <UpdateModal
        title={'Update advertiser'}
        description={"Are you sure you want to update this advertiser? If you update it, you can't get it back."}
        onUpdate={() => dispatch(toggleUpdateClientConfModal()) && submit(values)}
        onClose={handleOpenConfModal}
        deleteConfirmation={deleteConfirmation}
      />
    </div>
  );
};

export default ClientForm;
