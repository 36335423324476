import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import LabeledSelectBox from '../../../ui/select-box/labeled';
import MultiSelectSearch from '../../../ui/multi-search';
import msgSelector from '../../../../state/push/selectors/messages/message';

const LangSel = ({ control, action }) => {
  const { langs } = useSelector(msgSelector);
  let selLangs = [];
  const langsToShow = [
    'English',
    'Czech',
    'Danish',
    'Dutch',
    'Finnish',
    'French',
    'German',
    'Hungarian',
    'Italian',
    'Latvian',
    'Lithuanian',
    'Norwegian',
    'Polish',
    'Portuguese',
    'Romanian',
    'Slovak',
    'Spanish',
    'Swedish',
  ];
  if (action === 'update') {
    selLangs = langsToShow.filter((item) => !langs.includes(item)).map((id, value) => ({ id: value, name: id }));
  } else {
    selLangs = langsToShow.map((id, value) => ({ id: value, name: id }));
  }

  return (
    <section id='langs' className='flex flex-col gap-4'>
      <div>
        <Controller
          name='langs'
          control={control}
          render={({ field: { value, name, onChange }, fieldState: { error } }) => (
            <LabeledSelectBox label={'Languages:'} isRequired={false} error={error}>
              <MultiSelectSearch
                id={name}
                placeholder={'Select Languages'}
                itemLabel={'name'}
                options={selLangs}
                onChange={onChange}
                values={value || []}
                isRequired={false}
                isDisabled={!selLangs.length}
                isReadOnly={false}
                isSelectAll={true}
              />
            </LabeledSelectBox>
          )}
        />
      </div>
    </section>
  );
};

export default LangSel;
