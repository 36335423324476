import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { auth } from '../index';

export const firebaseSignIn = async (data, thunkAPI) => {
  try {
    const { email, password } = data;
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    const { uid = '' } = user;
    let userPayload = {
      uid: uid,
      email: email,
    };
    return userPayload
  } catch (error) {
    const { code, message } = error;
    return thunkAPI.rejectWithValue(code, message);
  }
};
export const authListener = (callback) => onAuthStateChanged(auth, callback);

export const getCurrentUser = () => auth.currentUser;

export const firebaseSetAuthUser = async () => {
  const {email, uid} = auth.currentUser;
  let userPayload = {
    uid: uid,
    email: email,
  };

  return userPayload
};

export const firebaseSignOut = () => signOut(auth);
